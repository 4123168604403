/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
const qs = require('qs');
import axios from 'axios';
import _ from 'lodash';
import ReactJson from 'react-json-view';
import { Input, Dropdown, TextArea, Form } from 'semantic-ui-react';
import { mdiClose, mdiRefresh, mdiPlus, mdiDelete } from '@mdi/js';
import { AccountContext } from '../../providers/AccountProvider';
import IconButton from '../Block/components/FilterData/IconButton';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import GBButton from '../../components/GBButton/GBButton';
import TextButton from '../../components/TextButton/TextButton';
import FieldButton from './FieldButton';
import { getUserSystemAttributes } from '../../api/users';
import { getTableAPI } from '../../api/tables';
import { refreshToken } from '../../api/workflows';
import constructPath from './constructJSONPath';

const ActionAPICALL = ({ localData, updateData, editMode, sourceNodes }) => {
  const { userInfo } = useContext(AccountContext);
  const [insertFields, setInsertFields] = useState(localData.insertFields ?? []);
  const [availableFields, setAvailableFields] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [apiName, setApiName] = useState(localData.apiName ?? '');
  const [showInputFields, setShowInputFields] = useState(true);
  const [showResponseTab, setShowResponseTab] = useState(false);
  const [showStaticFields, setShowStaticFields] = useState(false);
  const [method, setMethod] = useState(localData.method ?? 'POST');
  const [url, setUrl] = useState(localData.url ?? '');
  const [apiKey, setApiKey] = useState(localData.apiKey ?? '');
  const [relationalFields, setRelationalFields] = useState({});
  const [mappedRelationalFields, setMappedRelationalFields] = useState(
    localData.mappedRelationalFields ?? {},
  );
  const [APIResponse, setAPIResponse] = useState(localData.apiData ?? null);
  const [tableData, setTableData] = useState(
    localData.tableData ?? {
      name: `api_${localData.id}`,
      id: `api_${localData.id}`,
      tableinfo: { columns: [{ data: 'apiResponse', uitype: 2, header: 'API Response as text' }] },
    },
  );
  const [Authentication, setAuthentication] = useState(localData.Authentication ?? 'none');
  const [provider, setProvider] = useState(localData.provider ?? null);
  const [tokenArray, setTokenArray] = useState(localData.tokenArray ?? []);
  const [staticFields, setStaticFields] = useState(localData.staticFields ?? []);
  const [fieldName, setFieldname] = useState('');
  const [fieldValue, setFieldValue] = useState('');
  const [processAllRecords, setProcessAllRecords] = useState(localData.processAllRecords ?? true);

  const { REACT_APP_API_URL } = process.env;

  useEffect(() => {
    getFields();
  }, []);

  const httpOptions = [
    { key: 'GET', value: 'GET', text: 'GET' },
    { key: 'POST', value: 'POST', text: 'POST' },
    { key: 'PUT', value: 'PUT', text: 'PUT' },
  ];

  const AuthenticationOptions = [
    { key: 'none', value: 'none', text: 'None' },
    { key: 'header', value: 'header', text: 'Authorization header' },
    { key: 'reddit', value: 'reddit', text: 'Reddit (OAUTH)' },
    { key: 'blogger', value: 'blogger', text: 'Blogger (OAUTH)' },
  ];

  const getOAuthLink = (service) => {
    const startLink = `${REACT_APP_API_URL}/${service}/start?userid=${userInfo.id}`;
    const leftPosition = (screen.width - 800) / 2;
    const topPosition = (screen.height - 600) / 2;
    const windowFeatures = `resizable,height=600,width=900,left=${leftPosition},top=${topPosition}`;
    const url = `javascript:window.open('${startLink}', 'pagename', '${windowFeatures}');`;
    return url;
  };

  const updateMethod = (val) => {
    setMethod(val);
    localData.method = val;
    updateData(localData);
  };

  const deleteProvider = () => {
    setProvider(null);
    delete localData.provider;
    updateData(localData);
  };

  const getUpdatedUserSystemAttributes = async (service) => {
    const data = await getUserSystemAttributes();
    userInfo.systemattributes = data.systemattributes;

    const idx = data.systemattributes.OAUTHProviders?.findIndex((itm) => itm.name === service);

    if (idx !== undefined && idx !== -1) {
      const tempProvider = data.systemattributes.OAUTHProviders[idx];
      setProvider(tempProvider);
      localData.provider = tempProvider;
      updateData(localData);
    }
  };

  const updateProcessAllRecords = (val) => {
    setProcessAllRecords(val);
    localData.processAllRecords = val;
    updateData(localData);
  };

  const updateAuthentication = (val) => {
    setAuthentication(val);
    localData.Authentication = val;
    updateData(localData);
    //if they choose option that is not noee or header, that indicates a 3rd party OAUTH provider.
    //We need to see if they are already authorized. If not they need to go through OAUTH process to get access /refresh token
    if (!['none','header'].includes(val)) {
      const idx = userInfo.systemattributes.OAUTHProviders?.findIndex((el) => el.name.toLowerCase() === val);

      if (idx !== undefined && idx !== -1) {
        const tempProvider= userInfo.systemattributes.OAUTHProviders[idx];
        setProvider(tempProvider);
        localData.Authentication=val;
        localData.provider = tempProvider;
        updateData(localData);
      }
    }
  };

  const addStaticField = () => {
    if (fieldName !== '' && fieldValue !== '') {
      const copyFields = structuredClone(staticFields);
      const staticField = { field: fieldName, value: fieldValue };
      copyFields.push(staticField);
      setStaticFields(copyFields);
      setFieldname('');
      setFieldValue('');

      localData.staticFields = copyFields;
      updateData(localData);
    }
  };

  const removeStaticField = (field) => {
    let copyFields = structuredClone(staticFields);
    copyFields = copyFields.filter((itm) => itm.field !== field);
    setStaticFields(copyFields);
    localData.staticFields = copyFields;
    updateData(localData);
  };

  const updateUrl = (val) => {
    setUrl(val);

    const tokens = extractUniqueTokens(val);
    setTokenArray(tokens);
    localData.tokenArray = tokens;

    localData.url = val;
    if (localData.method === undefined) {
      localData.method = method;
    }

    if (localData.apiKey === undefined) {
      localData.apiKey = apiKey;
    }

    if (localData.processAllRecords === undefined) {
      localData.processAllRecords = processAllRecords;
    }

    updateData(localData);
  };

  const updateApiName = (val) => {
    setApiName(val);
    tableData.name = val;
    localData.tableData = tableData;
    localData.Authentication = Authentication;
    localData.apiName = val;
    updateData(localData);
  };

  const updateFieldName = (item, newFieldValue) => {
    const idx = insertFields.findIndex((el) => el.value == item);
    const copy = structuredClone(insertFields);
    if (idx !== -1) {
      copy[idx].fieldName = newFieldValue;
      setInsertFields(copy);
      localData.insertFields = copy;
      updateData(localData);
    }
  };

  const updateTestValue = (item, testValue) => {
    const idx = insertFields.findIndex((el) => el.value == item);
    const copy = structuredClone(insertFields);
    if (idx !== -1) {
      copy[idx].testValue = testValue;
      setInsertFields(copy);
      localData.insertFields = copy;
      updateData(localData);
    }
  };

  const updateRelationFieldName = (tableid, item, newFieldValue) => {
    const idx = mappedRelationalFields[tableid].findIndex((el) => el.value === item);
    const copy = structuredClone(mappedRelationalFields);
    if (idx !== -1) {
      copy[tableid][idx].fieldName = newFieldValue;
      setMappedRelationalFields(copy);
      localData.mappedRelationalFields = copy;
      updateData(localData);
    }
  };

  const updateRelationTestValue = (tableid, item, testValue) => {
    const idx = mappedRelationalFields[tableid].findIndex((el) => el.value === item);
    const copy = structuredClone(mappedRelationalFields);
    if (idx !== -1) {
      copy[tableid][idx].testValue = testValue;
      setMappedRelationalFields(copy);
      localData.mappedRelationalFields = copy;
      updateData(localData);
    }
  };

  const updateApiKey = (val) => {
    setApiKey(val);
    localData.apiKey = val;
    updateData(localData);
  };

  const testPOSTCall = async () => {
    /*
      https://jsonplaceholder.typicode.com/
      could test service for GET, POST, etc
    */

    let finalUrl = url;
    tokenArray.map((tkn) => {
      finalUrl = finalUrl.replaceAll(tkn.token, tkn.value);
    });

    let data = {};
    insertFields.map((fld) => {
      data[fld.fieldName] = fld.testValue;
    });

    if (staticFields.length > 0) {
      staticFields.map((field) => {
        data[field.field] = field.value;
      });
    }

    let result = null;

    if (Authentication === 'none') {
      result = await axios.post(finalUrl, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } else if (Authentication === 'header') {
      result = await axios.post(finalUrl, data, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      });
    } else if (!['none', 'header'].includes(Authentication) && provider !== null) {
        const tokens = await refreshToken(Authentication, provider.userid);
        const headers = {
        Authorization: `Bearer ${tokens.newAccessToken}`,
        'User-Agent': 'script:Data Analyzer:v1.0.0 (by GraceBlocks)',
      };

      if(Authentication==='blogger') {
         headers['Content-Type'] = 'application/json'
      } else {
         headers['Content-Type'] = 'application/x-www-form-urlencoded'
      }

      //Reddit and supposedly most OAUTH implementations requre x-www-form-urlencoded
      //for data forma.
      // data = qs.stringify(data);

      console.log(data)

      try {
        result = await axios.post(finalUrl, data, { headers });
      } catch (error) {
        console.log(error);
      }
    }

    setAPIResponse(result.data);
    localData.apiData = result.data;
    updateData(localData);
  };

  const extractUniqueTokens = (inputString) => {
    const regex = /\{\{([^}]+)\}\}/g;
    const tokens = [];
    let match;

    while ((match = regex.exec(inputString)) !== null) {
      const token = match[0];

      // Check if the token is already in the array
      const tokenExists = tokens.some((existingToken) => existingToken.token === token);

      if (!tokenExists) {
        tokens.push({
          token: token,
          value: '',
        });
      }
    }

    return tokens;
  };

  const testGETCall = async () => {
    let finalUrl = url;
    tokenArray.map((tkn) => {
      finalUrl = finalUrl.replaceAll(tkn.token, tkn.value);
    });

    let newUrl = new URL(finalUrl);
    const data = {};

    insertFields.map((fld) => {
      data[fld.fieldName] = fld.testValue;
    });

    staticFields.map((fld) => {
      data[fld.field] = fld.value;
    });

    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        newUrl.searchParams.append(key, value);
      });
    }

    let result = {};
    if (Authentication === 'none') {
      result = await axios.get(newUrl.href);
    } else if (Authentication === 'header') {
      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          newUrl.searchParams.append(key, value);
        });
      }

      const headers = {
        Authorization: `Bearer ${apiKey}`,
      };

      result = await axios.get(newUrl.href, { headers });
    } else if (!['none', 'header'].includes(Authentication) && provider !== null) {
      const tokens = await refreshToken(Authentication, provider.userid);
      const headers = {
        Authorization: `Bearer ${tokens.newAccessToken}`,
        'User-Agent': 'script:Reddit Data Analyzer:v1.0.0 (by /u/suspicious_brain',
      };
      result = await axios.get(newUrl.href, { headers });
    }

    setAPIResponse(result.data);
    localData.apiData = result.data;
    updateData(localData);
  };

  const updateTokenValue = (token, value) => {
    const copyArray = structuredClone(tokenArray);
    const idx = copyArray.findIndex((el) => el.token === token);
    if (idx !== -1) {
      copyArray[idx].value = value;
    }
    setTokenArray(copyArray);
    localData.tokenArray = copyArray;
    updateData(localData);
  };

  const addJsonElement = (val) => {
 
    const path = constructPath(val,APIResponse);
    console.log(path)

    const copyTableData = structuredClone(tableData);
    copyTableData.tableinfo.columns = tableData.tableinfo.columns ?? [];
    const idx = copyTableData.tableinfo.columns.findIndex((col) => col.data === val.name);
    if (idx === -1) {
      const uitype = val.type === 'integer' ? 23 : 2;
      copyTableData.tableinfo.columns.push({
        data: val.name,
        header: val.name,
        uitype: uitype,
        path: path,
      });
      setTableData(copyTableData);
      localData.tableData = copyTableData;
      updateData(localData);
    }
  };

  const addFieldtoURL = (field) => {
    const newUrl = url + '{{' + field + '}}';
    const tokens = extractUniqueTokens(newUrl);
    setTokenArray(tokens);

    setUrl(newUrl);
    localData.url = newUrl;
    updateData(localData);
  };

  const removeJsonElement = (header) => {
    const copyTableData = structuredClone(tableData);
    copyTableData.tableinfo.columns = copyTableData.tableinfo.columns.filter(
      (col) => col.header !== header,
    );
    setTableData(copyTableData);
    localData.tableData = copyTableData;
    updateData(localData);
  };

  const getRelationalFields = async (tableid) => {
    const tableData = await getTableAPI(tableid);
    const tempRel = relationalFields;

    const idx = Object.keys(tempRel).findIndex((e) => e === tableData.id);

    if (idx == -1) {
      const relfields = [];
      tableData.tableinfo.columns.map((col) => {
        if (
          !mappedRelationalFields[tableid] ||
          mappedRelationalFields[tableid]?.findIndex(
            (fld) => fld.value === `${tableid}|${col.data}`,
          ) == -1
        ) {
          relfields.push({
            key: `${tableid}|${col.data}`,
            value: `${tableid}|${col.data}`,
            text: col.header,
            fieldName: col.header,
          });
        }
      });

      tempRel[tableid] = relfields;
    }
    tempRel[tableid].sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));
    setRelationalFields(tempRel);
  };

  const addRemoveField = async (value) => {
    const idx = insertFields.findIndex((el) => el.value === value);
    const idx2 = availableFields.findIndex((el) => el.value === value);

    let tempInsert = structuredClone(insertFields);
    let tempAvailable = structuredClone(availableFields);

    if (idx === -1) {
      const fld = availableFields[idx2];
      fld.mappedValue = fld.key;
      fld.fieldName = fld.colInfo.header;
      tempInsert.push(fld);
      tempAvailable = tempAvailable.filter((itm) => itm.value !== value);
      if (fld.colInfo.uitype === 1) {
        await getRelationalFields(fld.colInfo.source.lookuptableid);
      }
    } else {
      const fld = insertFields[idx];
      fld.mappedValue = fld.key;
      tempAvailable.push(fld);
      tempInsert = tempInsert.filter((itm) => itm.value !== value);
      if (fld.colInfo.uitype === 1) {
        delete mappedRelationalFields[fld.colInfo.source.lookuptableid];
      }
    }

    setInsertFields(tempInsert);
    setAvailableFields(tempAvailable);

    localData.insertFields = tempInsert;
    localData.availableFields = tempAvailable;

    updateData(localData);
  };

  const addRemoveRelationalField = async (inputValue) => {
    const [tableid, value] = inputValue.split('|');

    const idx =
      mappedRelationalFields[tableid] &&
      mappedRelationalFields[tableid].findIndex((el) => el.value === inputValue);
    const idx2 =
      relationalFields[tableid] &&
      relationalFields[tableid].findIndex((el) => el.value === inputValue);

    let tempInsert = structuredClone(mappedRelationalFields);
    let tempAvailable = structuredClone(relationalFields);

    if (idx === -1 || idx === undefined) {
      const fld = relationalFields[tableid][idx2];
      if (tempInsert[tableid] === undefined) {
        tempInsert[tableid] = [fld];
      } else {
        tempInsert[tableid].push(fld);
      }

      tempAvailable[tableid] = tempAvailable[tableid].filter((itm) => itm.value !== inputValue);
    } else {
      const fld = mappedRelationalFields[tableid][idx];
      tempAvailable[tableid].push(fld);
      tempInsert[tableid] = tempInsert[tableid].filter((itm) => itm.value !== inputValue);
      tempAvailable[tableid].sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));
    }

    setMappedRelationalFields(tempInsert);
    setRelationalFields(tempAvailable);

    localData.mappedRelationalFields = tempInsert;
    localData.relationalFields = tempAvailable;
    updateData(localData);
  };

  const getFields = async () => {
    let aFields = [];
    const allOptions = [{ key: '0', value: '', text: 'Choose field' }];

    sourceNodes.map((node) => {
      node.data.insertMultiple == null &&
        node.data?.tableData?.tableinfo?.columns.map((col) => {
          const idx = aFields.findIndex(
            (itm) => itm.key === `${node.data.tableData.id}||${col.data}`,
          );
          if (idx === -1) {
            aFields.push({
              key: `${node.data.tableData.id}|${col.data}`,
              value: `${node.data.tableData.id}|${col.header}`,
              text: `${node.data.tableData.name}|${col.header}`,
              colInfo: col,
            });
          }
        });
    });

    sourceNodes.map((node) => {
      node.data.insertMultiple == null &&
        node.data?.tableData?.tableinfo?.columns.map((col) => {
          const idx = allOptions.findIndex(
            (itm) => itm.key === `${node.data.tableData.id}||${col.data}`,
          );
          if (idx === -1) {
            allOptions.push({
              key: `${node.data.tableData.id}||${col.data}`,
              value: `${node.data.tableData.name}::${node.data.tableData.id}||${col.header}`,
              text: `${node.data.tableData.name}||${col.header}`,
            });
          }
        });
    });

    aFields.sort((a, b) =>
      a.colInfo.header.toLowerCase() > b.colInfo.header.toLowerCase() ? 1 : -1,
    );

    if (localData.insertFields !== undefined) {
      setInsertFields(localData.insertFields);

      for (const fld of localData.insertFields) {
        if (fld.colInfo.uitype === 1) {
          await getRelationalFields(fld.colInfo.source.lookuptableid);
        }
      }

      aFields = aFields.filter(
        (col) => !localData.insertFields.map((e) => e.value).includes(col.value),
      );
      setAvailableFields(aFields);
    } else {
      setAvailableFields(aFields);
    }
    setAllFields(allOptions);
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div>
        API Name: <Input value={apiName} fluid onChange={(e, data) => updateApiName(data.value)} />
      </div>
      <div style={{ marginTop: '10px' }}>
        HTTP Method:{' '}
        <Dropdown
          selection
          fluid
          value={method}
          options={httpOptions}
          onChange={(e, data) => updateMethod(data.value)}
        />
      </div>
      <div style={{ marginTop: '10px' }}>
        Authorization:{' '}
        <Dropdown
          selection
          fluid
          value={Authentication}
          options={AuthenticationOptions}
          onChange={(e, data) => updateAuthentication(data.value)}
        />
      </div>
      {!['none', 'header'].includes(Authentication) && provider === null ? (
        <div>
          <div style={{ fontWeight: 'bold', marginTop: '10px' }}>Authorize {Authentication}</div>
          <div>In order to use {Authentication} API, you must configure OAUTH.</div>
          <div>
            <a href={getOAuthLink(Authentication)}>
              <b>Click here</b>
            </a>{' '}
            to Authorize GraceBlocks to use your {Authentication} Account.
            <div style={{ marginTop: '10px' }}>
              Click "Refresh" after completing the authorization step above.
              <GBButton
                icon={mdiRefresh}
                iconColor="white"
                text="Refresh"
                padding="5px"
                width="100px"
                color={'#0D99FF'}
                Action={() => getUpdatedUserSystemAttributes(Authentication)}
              />
            </div>
          </div>
        </div>
      ) : null}
      {provider !== null && !['none', 'header'].includes(Authentication) ? (
        <div>
          <div>Service: {provider.name}</div>
          <table>
            <tr>
              <td>
                Authorized user: {provider.firstname} {provider.lastname}
              </td>
              <td>
                <IconButton color={'#E06055'}  hoverColor={'#E0605580'} icon={mdiDelete} Action={deleteProvider} />
              </td>
            </tr>
          </table>
        </div>
      ) : null}
      {/* <div style={{marginTop:'10px'}}/>
      <div>
        DATA FORMAT: <Dropdown selection fluid value={format} options={formatOptions} onChange={(e,data)=>updateFormat(data.value)} />
      </div> */}
      <div style={{ marginTop: '10px' }} />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '5px' }}>
        <div>HTTPS URL</div>
        <div style={{ marginLeft: '10px' }}>
          {' '}
          <FieldButton
            padding="5px"
            label="Add field to url"
            options={allFields}
            addRemoveField={addFieldtoURL}
            icon={mdiPlus}
          />
        </div>
      </div>

      <Input fluid value={url} onChange={(e, data) => updateUrl(data.value)} />
      {tokenArray.length > 0 ? (
        <table style={{ margin: '10px' }}>
          <tr>
            <th>Token</th>
            <th>Test value</th>
          </tr>
          {tokenArray.map((tk) => (
            <tr>
              <td>{tk.token}</td>
              <td>
                <Input
                  value={tk.value}
                  onChange={(e, data) => updateTokenValue(tk.token, data.value)}
                />
              </td>
            </tr>
          ))}
        </table>
      ) : null}

      {Authentication === 'header' ? (
        <div style={{ marginTop: '10px' }}>
          Authorization header token (Authorization: Bearer -token- ):{' '}
          <Input fluid value={apiKey} onChange={(e, data) => updateApiKey(data.value)} />
        </div>
      ) : null}
      <div>
        <div style={{ marginTop: '15px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: '#eee',
              padding: '5px',
              borderRadius: '10px',
            }}
          >
            <TextButton
              text="Show input fields"
              textColor={showInputFields ? 'black' : 'blue'}
              Action={() => {
                setShowResponseTab(false);
                setShowInputFields(true);
                setShowStaticFields(false);
              }}
            />
            <div style={{ width: '10px' }} /> | <div style={{ width: '10px' }} />
            <TextButton
              text="Show static fields"
              textColor={showStaticFields ? 'black' : 'blue'}
              Action={() => {
                setShowResponseTab(false);
                setShowInputFields(false);
                setShowStaticFields(true);
              }}
            />
            <div style={{ width: '10px' }} /> | <div style={{ width: '10px' }} />
            <TextButton
              text="Show response fields"
              textColor={showResponseTab ? 'black' : 'blue'}
              Action={() => {
                setShowResponseTab(true);
                setShowInputFields(false);
                setShowStaticFields(false);
              }}
            />
          </div>

          {showInputFields ? (
            <>
              <div style={{ marginTop: '10px' }}>
                <b>Input fields</b>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <FieldButton
                  options={availableFields}
                  addRemoveField={addRemoveField}
                  icon={mdiPlus}
                />
              </div>
              <table>
                <tr>
                  <th>Field name</th>
                  <th>Api field name</th>
                  <th>Test value</th>
                  <th>Remove</th>
                </tr>
                {insertFields.map((field) => (
                  <>
                    <tr>
                      {field.colInfo.uitype === 1 ? (
                        <td>
                          <FieldButton
                            padding="5px"
                            label={field.text.split('|')[1]}
                            options={relationalFields[field.colInfo.source.lookuptableid]}
                            addRemoveField={addRemoveRelationalField}
                            icon={mdiPlus}
                          />
                        </td>
                      ) : (
                        <td>{field.text}</td>
                      )}
                      <td style={{ width: '100px' }}>
                        <Input
                          fluid
                          value={field.fieldName}
                          onChange={(e, data) => updateFieldName(field.value, data.value)}
                        />
                      </td>
                      <td style={{ width: '100px' }}>
                        <Input
                          fluid
                          value={field.testValue}
                          onChange={(e, data) => updateTestValue(field.value, data.value)}
                        />
                      </td>

                      <td style={{ textAlign: 'center' }}>
                        <IconButton
                          icon={mdiClose}
                          iconSize="20px"
                          color={'#E06055'}
                          hoverColor={'#E0605580'}
                          Action={addRemoveField}
                          ActionValue={field.value}
                        />
                      </td>
                    </tr>
                    {field.colInfo.uitype === 1 &&
                    mappedRelationalFields[field.colInfo?.source?.lookuptableid]
                      ? mappedRelationalFields[field.colInfo?.source?.lookuptableid].map((rel) => (
                          <tr style={{ backgroundColor: '#eee' }}>
                            <td style={{ paddingLeft: '40px' }}>{rel.text}</td>
                            <td style={{ width: '50px' }}>
                              <Input
                                value={rel.fieldName}
                                onChange={(e, data) =>
                                  updateRelationFieldName(
                                    field.colInfo.source.lookuptableid,
                                    rel.value,
                                    data.value,
                                  )
                                }
                              />
                            </td>
                            <td style={{ width: '100px' }}>
                              <Input
                                fluid
                                value={field.testValue}
                                onChange={(e, data) =>
                                  updateRelationTestValue(field.value, data.value)
                                }
                              />
                            </td>

                            <td style={{ textAlign: 'center' }}>
                              <IconButton
                                icon={mdiClose}
                                iconSize="20px"
                                color={'#E06055'}
                                hoverColor={'#E0605580'}
                                Action={addRemoveRelationalField}
                                ActionValue={rel.value}
                              />
                            </td>
                          </tr>
                        ))
                      : null}
                  </>
                ))}
              </table>
            </>
          ) : null}
          {showResponseTab ? (
            <>
              <div style={{ marginTop: '10px' }}>
                <div style={{ marginTop: '10px' }}>
                  <b>Response fields</b>
                </div>
                <GBButton
                  text="Get response"
                  padding="10px"
                  color={'#0D99FF'}
                  Action={method === 'GET' ? testGETCall : testPOSTCall}
                />
              </div>
              {APIResponse ? (
                <>
                  <div>Click on the value/field you want to capture from response</div>
                  <div style={{ height: '300px', width:'100%', overflow: 'auto', marginTop: '10px' }}>
                    <ReactJson theme="ocean" onSelect={addJsonElement} src={APIResponse}  style={{ width: '2000px' }} />
                  </div>
                  <div style={{ marginTop: '5px', marginBottom: '5px', fontWeight: 'bold' }}>
                    Fields to capture
                  </div>
                  {tableData?.tableinfo.columns?.map((col) => (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div style={{ marginRight: '5px' }}>{col.header}</div>
                      {col.data !=='apiResponse' ? (
                      <div>
                        <IconButton
                          icon={mdiClose}
                          iconSize="20px"
                          color={'#E06055'}
                          hoverColor={'#E0605580'}
                          Action={removeJsonElement}
                          ActionValue={col.header}
                        />
                      </div>
                      ):null}
                    </div>
                  ))}
                </>
              ) : null}
              <div style={{ marginTop: '5px' }}>
                <GBSwitch
                  isChecked={processAllRecords}
                  text="Process all records"
                  Action={() => updateProcessAllRecords(!processAllRecords)}
                />
              </div>
            </>
          ) : null}
          {showStaticFields ? (
            <div>
              <div style={{ marginTop: '10px' }}>
                <b>Static fields</b>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Input
                  value={fieldName}
                  placeholder="Field name"
                  onChange={(e, data) => setFieldname(data.value)}
                />
                <div stye={{ width: '5px' }}> - </div>
                <Input
                  value={fieldValue}
                  placeholder="Field value"
                  onChange={(e, data) => setFieldValue(data.value)}
                />
                <div style={{ marginLeft: '5px' }}>
                  <GBButton
                    iconColor="white"
                    width="110px"
                    padding="5px"
                    icon={mdiPlus}
                    text="Add field"
                    color={'#0D99FF'}
                    Action={addStaticField}
                  />
                </div>
              </div>
              <div style={{ height: '10px' }} />
              {staticFields.length > 0 ? (
                <table>
                  <tr>
                    <th>Field</th>
                    <th>Value</th>
                  </tr>
                  {staticFields.map((field) => (
                    <tr>
                      <td width="100px">{field.field}</td>
                      <td>{field.value}</td>
                      <td>
                        <IconButton
                          color={'#E06055'}
                          hoverColor={'#E0605580'}
                          icon={mdiDelete}
                          Action={() => removeStaticField(field.field)}
                        />{' '}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ActionAPICALL;
