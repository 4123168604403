// /* eslint-disable */

/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { mdiPlayBox, mdiPauseOctagon } from '@mdi/js';
import IconButton from '../Block/components/FilterData/IconButton';

const AudioPlayer = ({ mp3Url }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.src = mp3Url;
      audioRef.current.play();
    }
    // setIsPlaying is handled by the event listeners
  };

  useEffect(() => {
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);

    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.addEventListener('play', handlePlay);
      audioElement.addEventListener('pause', handlePause);
      audioElement.addEventListener('ended', handleEnded);
    }

    // Cleanup function to remove event listeners
    return () => {
      if (audioElement) {
        audioElement.removeEventListener('play', handlePlay);
        audioElement.removeEventListener('pause', handlePause);
        audioElement.removeEventListener('ended', handleEnded);
      }
    };
  }, [mp3Url]); // Dependency array ensures effect runs when mp3Url changes

  return (
    <div>
        <IconButton color="#0D99FF" size="90px" icon={isPlaying ? mdiPauseOctagon : mdiPlayBox} Action={togglePlay} />
      <audio ref={audioRef} />
    </div>
  );
};

export default AudioPlayer;

// import React, { useState, useRef } from 'react';
// import { mdiPlayBox,mdiPauseOctagon } from '@mdi/js';
// import IconButton from '../Block/components/FilterData/IconButton';

// const AudioPlayer=({ mp3Url }) => {
//   const [isPlaying, setIsPlaying] = useState(false);
//   const audioRef = useRef(null);

//   const togglePlay = () => {
//     if (isPlaying) {
//       audioRef.current.pause();
//     } else {
//       audioRef.current.src = mp3Url; // Set source only when playing
//       audioRef.current.play();
//     }
//     setIsPlaying(!isPlaying);
//   };

//   return (
//     <div>
//         <IconButton size="30px" icon={mdiPlayBox} Action={togglePlay} />
//       <audio ref={audioRef} />
//     </div>
//   );
// }

// export default AudioPlayer;