/* eslint-disable */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Input, Dropdown, Popup } from 'semantic-ui-react';
import Icon from '@mdi/react';
import Modal from 'react-modal';
import {
  mdiClose,
  mdiFunctionVariant,
  mdiPlayBox,
  mdiClipboardCheck,
  mdiPlus,
  mdiInformationOutline,
  mdiGateNor
} from '@mdi/js';
import Global from '../../clients/global';
import { getBlocksAPI } from '../../api/blocks';
import { getTableAPI, getTablesAPI, getRelationalTableData } from '../../api/tables';
import TextButton from '../../components/TextButton/TextButton';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import DuplicateControl from '../Block/components/Forms/components/DuplicateControl';
import FieldButton from './FieldButton';
import IconButton from '../Block/components/FilterData/IconButton';
import CodeEditor from 'src/components/CodeEditor/CodeEditor';
import EvaluateFunction from './EvaluateFunction';
import TextControl from 'src/components/TextControl/TextControl';
import RelationalDropdown from './RelationalDropdown';
import GBButton from '../../components/GBButton/GBButton';
import FilterData from '../Block/components/FilterData/index';
import FilterTags from '../Block/components/FilterData/FilterTags';


const ActionNewRecord = ({ localData, updateData, editMode, sourceNodes }) => {
  const [blocks, setBlocks] = useState(null);
  const [tables, setTables] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(localData.blockid ?? '');
  const [tableData, setTableData] = useState(null);
  const [enableDupLogic, setEnableDupLogic] = useState(
    localData.duplicateSettings !== undefined ? true : false,
  );
  const [insertFields, setInsertFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [refreshDate, setRefreshDate] = useState(Date.now());
  const [insertMultiple, setInsertMultiple] = useState(localData.insertMultiple ?? false);
  const [selectedMultiRelationalField, setSelectedMultiRelationalField] = useState(localData.selectedMultiRelationalField ?? '');
  const [multiRelationalFields, setMultiRelationalFields] =useState([])
  const [modalIsOpen,setModalIsOpen] =useState(false);
  const [multiInsertFilters,setMultiInsertFilters] = useState(localData.multiInsertFilters ?? [])
  const [multiInsertTable, setMultiInsertTable] =useState();


  //Used to render DuplicateControls. We are creating a dummy form that has defined fields used
  //in duplicate control to properly render them.
  const [form, setForm] = useState(localData.duplicateSettings ?? { settings: {} });

  const updateFormAttribute = (attr, value) => {
    const tempForm = { ...form };
    tempForm.settings[attr] = value;
    setForm(tempForm);
    localData.duplicateSettings = tempForm;
  };

  // useEffect(() => {
  //   console.log(localData);
  // }, []);


  const onHandleMultiRelationalField = async (value,isLoading=false) => {
    setSelectedMultiRelationalField(value);
    
    const [sourceTabname,sourceTab,lookuptableid,lookupfield] = value.split('||');
    const tbl = await getTableAPI(lookuptableid);
    
    const tabname =tbl.name;
    let header ='';
    const idx = tbl.tableinfo.columns.findIndex(fl=>fl.source?.returnfield===lookupfield)
    if(idx !==-1) {
      header =  tbl.tableinfo.columns[idx].header;
    }

    // We need to lookup tableData of relational table to retrieve all columns to be appended
    // to getOption
  
    const relationalFields =[];

    tbl.tableinfo.columns.map(col=>{
      relationalFields.push({
        key: `${lookuptableid}|${col.data}`,
        value: `${lookuptableid}|${col.data}|${col.header}`,
        text: `${sourceTabname}||${sourceTab}||${col.header}`,
        uitype: col.uitype
      });
    })

    setMultiRelationalFields(relationalFields);
    setMultiInsertTable(tbl)
  
    //10-7-24 if user selects to change field, reset the filters to empty. But if isloading,
    //that means we want to keep existing filters when component loads. 
    //1-7-25 commented out as was always hiding filters.
    // if(!isLoading) {
    //   setMultiInsertFilters([]);
    // }
  
    localData.selectedMultiRelationalField=value;
    localData.multiInsertTableId = tbl.id;
    delete localData.multiInsertFilters;
    updateData(localData)

  };

  const getMultiRelationalFields = () => {
    const options = [];
    sourceNodes.map((node) => {
      const columns = node.data.tableData?.tableinfo.columns.filter(
        (fl) => fl.uitype === 1 && fl.lookup === 'multiple',
      );

      columns?.map((col) => {
        const idx = options.findIndex((itm) => itm.key === `${col.source.lookuptableid}|${col.data}`);
        if (idx === -1) {
          options.push({
            key: `${col.source.lookuptableid}|${col.data}`,
            value: `${node.data.tableData.name}::${node.data.tableData.id}||${col.header}||${col.source.lookuptableid}||${col.data}`,
            text: `${node.data.tableData.name}::${node.data.tableData.id}||${col.header}`,
          });
        }
      });
    });

    return options;
  };

  const updateMultiInsertFilters = (filters) =>{
    setMultiInsertFilters(filters);
    localData.multiInsertFilters = filters
    setModalIsOpen(false)
  }

  const getOptions = (uitype) => {
    const options = [
      { key: '0', value: 'manual', text: 'Manual entry' },
      { key: 'function', value: 'function', text: 'Javascript function' },
    ];

    sourceNodes.map((node) => {
      let columns = node.data.tableData?.tableinfo.columns;

      //We remove select/relational as possible fields because it would be confusing to user...the chance
      //of the field have exact matches is very low. If they want to use the field, they will use js function.
      if (uitype) {
        columns = node.data.tableData?.tableinfo.columns.filter(
          (fl) => ![1, 6, 7].includes(fl.uitype) && fl.uitype === uitype,
        );
        //1-8-25 if field  is short,long, or rtf, bring back all those as options.
        //for exampole, you can insrt a ahort text field into an RTF field.
        if([2,3,13].includes(uitype)) {
          columns = node.data.tableData?.tableinfo.columns.filter(
            (fl) => [2,3,13].includes(fl.uitype)
          );
        }
      }

      !node.data.insertMultiple && columns?.map((col) => {
        const idx = options.findIndex((itm) => itm.key === `${node.data.tableData.id}|${col.data}`);
        if (idx === -1) {
          options.push({
            key: `${node.data.tableData.id}|${col.data}`,
            value: `${node.data.tableData.id}|${col.data}`,
            text: `${node.data.tableData.name}::${node.data.tableData.id}||${col.header}`,
          });
        }
      });
    });

    if(multiRelationalFields.length>0 && uitype===undefined) {
      options.push(...multiRelationalFields)
    } else if (multiRelationalFields.length>0 && uitype !==undefined) {
       //We remove select/relational as possible fields because it would be confusing to user...the chance
      //of the field have exact matches is very low. If they want to use the field, they will use js function.
      options.push(...multiRelationalFields.filter(itm=>itm.uitype===uitype && ![1, 6, 7].includes(itm.uitype)))
    }

    return options;
  };

  const getMappedValue = (field, attribute) => {
    const idx = insertFields.findIndex((col) => col.key === field);
    if (idx !== -1) {
      return insertFields[idx][attribute] ?? false;
    }
  };

  const handleMappedValueChange = (field, val, attribute) => {
    const idx = insertFields.findIndex((col) => col.value === field);
    const temp = structuredClone(insertFields);
    temp[idx][attribute] = val;
    setInsertFields(temp);

    localData.insertFields = temp;
    updateData(localData);
  };

  const updateFieldValue = (field, value) => {
    const idx = insertFields.findIndex((el) => el.key === field);
    if (idx !== -1) {
      insertFields[idx].dataValue = value;
      setInsertFields(insertFields);

      localData.insertFields = insertFields;
      updateData(localData);
      setRefreshDate(Date.now());
    }
  };

  const stateMap = { blocks, tables };

  const DisplayValue = (source, value) => {
    const sourceArray = stateMap[source];
    if (!sourceArray) {
      console.error(`Source ${source} not found.`);
      return;
    }
    const idx = sourceArray.findIndex((el) => el.value === value);
    if (idx !== -1) {
      return sourceArray[idx].text;
    } else {
      return 'Value not found';
    }
  };

  useEffect(() => {
    if (localData.actionEvent === 'newrecord') {
      fetchBlocks();
    } else {
      loadUpdateRecordInfo();
    }
  }, [localData.actionEvent]);

  const loadUpdateRecordInfo = () => {
    //this is run when action is update Triggered record, so we need to get
    //trigger record (which is id='1')

    const triggerNode = sourceNodes.filter((el) => el.id === '1')[0];
    localData.tableData = triggerNode.data.tableData;

    const editablefields = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 17, 22, 23];
    let afields = [];
    localData.tableData.tableinfo.columns
      .filter((el) => editablefields.includes(el.uitype) && el.data !== 'id')
      .map((itm) => {
        afields.push({ key: itm.data, value: itm.data, text: itm.header, colInfo: itm });
      });

    afields.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));

    if (localData.insertFields !== undefined) {
      setInsertFields(localData.insertFields);

      afields = afields.filter(
        (col) => !localData.insertFields.map((e) => e.value).includes(col.value),
      );
      setAvailableFields(afields);
    } else {
      setAvailableFields(afields);
    }
  };

  const onBlockSelection = async (blockid) => {
    let tables = await getTablesAPI(blockid, 3);
    

    //9-30-24 We are adding logic that a workflow can only allow insrting one new record into a table. (except for multi-insert action).
    //So, you can't have 2 actions insert into the same table either through newrecord or form task. 
    // Here we find already defined tables and remove them from selection.
    const getDefinedtables = sourceNodes.filter(itm=>itm?.data?.tableData?.id !==undefined).map(itm=>itm.data.tableData.id);
    tables = tables.filter(tbl=>!getDefinedtables.includes(tbl.value))
    
    tables.forEach((itm) => {
      delete itm.isSystemTable;
    });

    tables.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));
    setSelectedBlock(blockid);
    setTables(tables);
    localData.blockid = blockid;
    // localData.tableData=null;
    updateData(localData);
  };

  const onInsertMultiple = (val) =>{
    setInsertMultiple(val)

    //9-16-24 if they toggle off insertMultiple, remove the selectMultipleField
    //and also the defined relationalfield so they are removed from potential picks.
    if(!val) {
      delete localData.selectedMultiRelationalField
      setMultiRelationalFields([]);
    }

    localData.insertMultiple=val;
    updateData(localData);
  }

  const onTableSelection = async (tableid) => {
    // const { fields } = await getFieldsAPI(tableid);

    const tbl = await getTableAPI(tableid);
    setTableData(tbl);
    const editablefields = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 17, 22, 23];
    let afields = [];
    tbl.tableinfo.columns
      .filter((el) => editablefields.includes(el.uitype) && el.data !== 'id')
      .map((itm) => {
        afields.push({ key: itm.data, value: itm.data, text: itm.header, colInfo: itm });
      });

    afields.sort((a, b) => (a.text > b.text ? 1 : -1));

    if (localData.insertFields !== undefined) {
      setInsertFields(localData.insertFields);

      afields = afields.filter(
        (col) => !localData.insertFields.map((e) => e.value).includes(col.value),
      );
      setAvailableFields(afields);
    } else {
      setAvailableFields(afields);
    }

    localData.tableData = tbl;
    updateData(localData);
  };



  const fetchBlocks = async () => {
    const blocks = await getBlocksAPI(parseInt(Global.zoneid));

    blocks.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));

    const blockswithIcons = [];
    blocks.forEach((itm) => {
      blockswithIcons.push({
        key: itm.key,
        value: itm.value,
        text: itm.text,
        icon: (
          <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={itm.icon} size="20px" />
        ),
      });
    });

    setBlocks(blockswithIcons);
    setMultiInsertFilters(localData.multiInsertFilters ?? [])
  
    if (localData.blockid !== undefined) {
      await onBlockSelection(localData.blockid);

      if (localData.tableData !== undefined && localData.tableData !== null) {
        await onTableSelection(localData.tableData.id);
      }

      if(localData.selectedMultiRelationalField !==undefined) {
        onHandleMultiRelationalField(localData.selectedMultiRelationalField,true)
      }
    }

  };

  const addRemoveField = (value) => {
    const idx = insertFields.findIndex((el) => el.value === value);
    const idx2 = availableFields.findIndex((el) => el.value === value);

    let tempInsert = structuredClone(insertFields);
    let tempAvailable = structuredClone(availableFields);

    if (idx === -1) {
      const fld = availableFields[idx2];
      fld.mappedValue = 'manual';
      tempInsert.push(fld);
      tempAvailable = tempAvailable.filter((itm) => itm.value !== value);
    } else {
      const fld = insertFields[idx];
      fld.mappedValue = 'manual';
      tempAvailable.push(fld);
      tempInsert = tempInsert.filter((itm) => itm.value !== value);
    }

    setInsertFields(tempInsert);
    setAvailableFields(tempAvailable);

    localData.insertFields = tempInsert;
    localData.availableFields = tempAvailable;

    updateData(localData);
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div style={{ marginTop: '20px' }}>
        {localData.actionEvent === 'newrecord' ? (
          <>
            <div style={{ fontWeight: 'bold' }}>
              Block <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
            </div>
            {!editMode ? (
              DisplayValue('blocks', selectedBlock)
            ) : (
              <Dropdown
                selectOnBlur={false}
                search
                value={selectedBlock}
                options={blocks}
                fluid
                selection
                onChange={(e, data) => onBlockSelection(data.value)}
                clearable
              />
            )}

            <div style={{ fontWeight: 'bold', marginTop: '20px' }}>
              Tab <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
            </div>
            {!editMode ? (
              <div>{DisplayValue('tables', tableData?.id)}</div>
            ) : (
              <Dropdown
                selectOnBlur={false}
                search
                value={tableData?.id}
                options={tables}
                fluid
                selection
                onChange={(e, data) => onTableSelection(data.value)}
                clearable
              />
            )}

            <div style={{ marginTop: '20px' }} />
            <GBSwitch
              color={'#0D99FF'}
              isChecked={enableDupLogic}
              Action={() => setEnableDupLogic(!enableDupLogic)}
              text="Update vs create new record when duplicates are identified"
            />

            {enableDupLogic && tableData != null ? (
              <>
                <div style={{ marginTop: '20px' }}>
                  <DuplicateControl
                    type="duplicateFields"
                    text="DUPLICATE CRITERIA"
                    tooltip={
                      <div>
                        You can specify one or many fields, or combination of fields that when
                        matching an existing record in the tab will cause a duplicate to be
                        identified. The record will then stack onto the existing record using the
                        logic configured on the subsequent two fields.
                        <p /> To learn more, see{' '}
                        <a
                          href="https://support.graceblocks.com/hc/en-us/articles/360061090531-Managing-web-form-duplicate-logic"
                          target="_newwindow"
                        >
                          web form duplicate logic.
                        </a>{' '}
                      </div>
                    }
                    selectedFields={form.settings?.duplicateFields}
                    tableinfo={tableData.tableinfo}
                    placeholder="Click + to add criterial fields set"
                    color={'#0D99FF'}
                    form={form}
                    updateFormAttribute={updateFormAttribute}
                  />
                </div>
                <div style={{ marginBottom: '20px' }}>&nbsp;</div>
                <div>
                  <DuplicateControl
                    type="blockedFields"
                    text="BLOCK UPDATES OF THESE FIELDS WHEN DUPLICATE"
                    tooltip={
                      <div>
                        Specify any fields you do not want overwritten when a duplicate is
                        identified. The data entered on the form matching the fields specified here
                        will be ignored when the update occurs.
                        <p /> To learn more, see{' '}
                        <a
                          href="https://support.graceblocks.com/hc/en-us/articles/360061090531-Managing-web-form-duplicate-logic"
                          target="_newwindow"
                        >
                          web form duplicate logic.
                        </a>{' '}
                      </div>
                    }
                    selectedFields={form.settings.blockedFields}
                    tableinfo={tableData.tableinfo}
                    placeholder="Click to select fields"
                    color={'#0D99FF'}
                    form={form}
                    updateFormAttribute={updateFormAttribute}
                  />
                </div>
                <div style={{ marginBottom: '20px' }}>&nbsp;</div>
                <div>
                  <DuplicateControl
                    type="replaceMultipleFields"
                    text="REPLACE VS. APPEND VALUES FOR MULTI-SELECT FIELDS"
                    tooltip={
                      <div>
                        When duplicates are found, data from the incoming form appends to the
                        existing record by default. If you wish to override this default behavior
                        and instead have data replaced with what is provided on the form, use this
                        setting. Specify the fields here where you want to override the default
                        behavior.
                        <p /> To learn more, see{' '}
                        <a
                          href="https://support.graceblocks.com/hc/en-us/articles/360061090531-Managing-web-form-duplicate-logic"
                          target="_newwindow"
                        >
                          web form duplicate logic.
                        </a>{' '}
                      </div>
                    }
                    selectedFields={form.settings.replaceMultipleFields}
                    tableinfo={tableData.tableinfo}
                    placeholder="Click to select fields"
                    color={'#0D99FF'}
                    form={form}
                    updateFormAttribute={updateFormAttribute}
                  />
                </div>
              </>
            ) : null}
          </>
        ) : null}

        <div style={{ marginTop: '20px' }} />
        
        {localData.actionEvent === 'newrecord' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <div style={{ width: '270px' }}>
            <GBSwitch
              color={'#0D99FF'}
              isChecked={insertMultiple}
              Action={() => onInsertMultiple(!insertMultiple)}
              text="Insert multiple records (from relational)"
            />
          </div>
          <Popup
            content={
              <div>
                The default action is to insert a single record. Enable this option in order to use
                one of the multi-select relational fields to loop over and insert multiple records
                into target table.
                <br/><br/>
                NOTE: When using this option, This action will not be available for reference in subsequent actions.
              </div>
            }
            mouseEnterDelay={200}
            trigger={<Icon path={mdiInformationOutline} size="17px" />}
          />
        </div>
        ) :null}

        {insertMultiple ? (<>
          <Dropdown
            fluid
            selection
            options={getMultiRelationalFields()}
            value={selectedMultiRelationalField}
            onChange={(e,data)=>onHandleMultiRelationalField(data.value)}
          />
          <div style={{height:'10px'}}></div>
          <GBButton
                  textColor="white"
                  width="230px"
                  borderRadius='10px'
                  padding='10px'
                  fontWeight='normal'
                  fontSize='15px'
                  color="#0D99FF"
                  iconColor='white'
                  Action={()=>setModalIsOpen(!modalIsOpen)}
                  ActionValue={'existing'}
                  iconPosition="left"
                  icon={mdiGateNor}
                  iconHoverColor='white'
                  iconSize="20px"
                  text="Manage filter conditions"
                />
              {multiInsertFilters?.length > 0 ? (
                <FilterTags
                  sortHeader={'id'}
                  sortField={'id'}
                  sortOrder={'asc'}
                  filters={multiInsertFilters}
                  removeFilter={null}
                  removeSort={null}
                />
              ) : null}

        </>) : null}

        <div style={{ marginTop: '20px' }}>
          <FieldButton options={availableFields} addRemoveField={addRemoveField} icon={mdiPlus} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            {insertFields.length > 0
              ? insertFields.map((fld) => (
                  <div
                    key={fld.key}
                    style={{
                      margin: '10px',
                      padding: '10px',
                      border: '1px solid black',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        marginTop: '10px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ marginRight: '10px' }}>{fld.text}</div>
                      <Dropdown
                        selectOnBlur={false}
                        fluid
                        search
                        value={getMappedValue(fld.key, 'mappedValue')}
                        selection
                        options={getOptions(fld.colInfo.uitype)}
                        onChange={(e, data) =>
                          handleMappedValueChange(fld.key, data.value, 'mappedValue')
                        }
                      />
                      <div style={{ marginLeft: '10px', marginRight: '20px' }}>
                        <GBSwitch
                          color={'#0D99FF'}
                          isChecked={getMappedValue(fld.key, 'isRequired')}
                          Action={() => {
                            handleMappedValueChange(
                              fld.key,
                              !getMappedValue(fld.key, 'isRequired'),
                              'isRequired',
                            );
                          }}
                          text="Required"
                        />
                      </div>
                      <IconButton
                        color="red"
                        hoverColor="white"
                        hoverBackgroundColor="#F6685E"
                        icon={mdiClose}
                        Action={addRemoveField}
                        ActionValue={fld.value}
                      />
                    </div>
                    {/* {getMappedValue(fld.key,'mappedValue')==='manual' && ![1,6,7,8].includes(fld.colInfo.uitype) ? (
                        <Input fluid onChange={(e,data)=>updateFieldValue(fld.key,data.value)}   style={{ width:'100%', marginTop:'10px'}} defaultValue={fld.value} />
                     ): null} */}
                    {getMappedValue(fld.key, 'mappedValue') === 'manual' &&
                    ![1, 4].includes(fld.colInfo?.uitype) ? (
                      // <Input fluid onChange={(e,data)=>updateFieldValue(fld.key,data.value)}   style={{ width:'100%', marginTop:'10px'}} defaultValue={fld.value} />
                      <div style={{ marginTop: '5px' }}>
                        <TextControl
                          value={fld.dataValue}
                          key={fld.key}
                          showLabel={false}
                          index={0}
                          alignControl={true}
                          blockid={selectedBlock}
                          updateValue={updateFieldValue}
                          tableid={localData.tableData.id}
                          colInfo={fld.colInfo}
                          role={3}
                          canEdit={true}
                          editMode={true}
                        />
                      </div>
                    ) : null}
                    {getMappedValue(fld.key, 'mappedValue') === 'manual' &&
                    [1].includes(fld.colInfo?.uitype) ? (
                      // <TextControl index={0} alignControl={true} key={'dddd'} blockid={selectedBlock} updateValue={updateFieldValue} tableid={localData.tableData.id} value={null} colInfo={fld.colInfo} role={3} canEdit={true} editMode={true} />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        <RelationalDropdown
                          tableid={localData.tableData.id}
                          colInfo={fld.colInfo}
                          selectedOptions={fld.dataValue}
                          updateFieldValue={updateFieldValue}
                        />
                      </div>
                    ) : null}

                    {getMappedValue(fld.key, 'mappedValue') === 'manual' &&
                    [4].includes(fld.colInfo?.uitype) ? (
                      // <TextControl index={0} alignControl={true} key={'dddd'} blockid={selectedBlock} updateValue={updateFieldValue} tableid={localData.tableData.id} value={null} colInfo={fld.colInfo} role={3} canEdit={true} editMode={true} />
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        <Input
                          style={{ width: '100%' }}
                          fluid
                          value={fld.dataValue}
                          onChange={(e, data) => updateFieldValue(fld.key, data.value)}
                        />
                      </div>
                    ) : null}

                    {getMappedValue(fld.key, 'mappedValue') === 'function' ? (
                      <>
                        <div style={{ marginTop: '10px' }}>
                          <CodeEditor
                            fields={getOptions().map((el) => el.text)}
                            updateFormula={updateFieldValue}
                            formula={fld.dataValue?.toString()}
                            isReadOnly={false}
                            mode="javascript"
                            field={fld.key}
                          />
                        </div>
                        <div>
                          <TextButton
                            tooltip="Enter sample values into the token fields for this function and click the play icon to see what will return as the result."
                            text="Evaluate"
                            textColor="#0D99FF"
                            color="#0D99FF"
                            hoverColor="#0D99FF80"
                            icon={mdiClipboardCheck}
                            Action={() => {
                              handleMappedValueChange(
                                fld.key,
                                !getMappedValue(fld.key, 'evaluateFunction'),
                                'evaluateFunction',
                              );
                            }}
                            iconSize="30px"
                            iconPosition="left"
                          />
                        </div>
                        {getMappedValue(fld.key, 'evaluateFunction') == true ? (
                          <div>
                            <EvaluateFunction functionString={fld.dataValue} />
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      <Modal
            shouldCloseOnOverlayClick={false}
            isOpen={modalIsOpen}
            // onAfterOpen={this.afterOpenModal}
            // onRequestClose={this.closeModal}
            style={{
              overlay: {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0)',
              },
              content: {
                // position: 'fixed',
                width: '450px',
                // height: this.state.modalHeight,
                height: 'calc(100vh -300px)',
                // maxHeight:'500px',
                top: 200,
                left: window.innerWidth / 2,
                border: `.3px solid #0D9953`,
                background: '#fff',
                overflow: 'auto',
                zIndex: 6010,
                WebkitOverflowScrolling: 'touch',
                borderRadius: '10px',
                outline: 'none',
                padding: '0px',
              },
            }}
            contentLabel="Example Modal"
          >
            <FilterData
              title={'Insert field conditions'}
              icon={mdiGateNor}
              saveText="Save"
              tableinfo={multiInsertTable?.tableinfo}
              tableid={multiInsertTable?.id}
              close={() => setModalIsOpen(false)}
              FilterData={updateMultiInsertFilters}
              filters={multiInsertFilters}
              color={'#0D99FF'}
              // color={'#0D9953'}
              blockid={multiInsertTable?.blockid}
              callingComponent="workflow"
            />
          </Modal>
    </div>
  );
};

export default ActionNewRecord;
