/* eslint-disable */
import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import GBButton from '../../../../components/GBButton/GBButton';
import Spinner from '../../../../components/Spinner/Spinner';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import GBConfirm from '../../../../components/GBConfirm/GBConfirm';
import { confirmAlert } from 'react-confirm-alert';
import {
  getForms,
  addForm,
  updateFormEmailUsers,
  deleteForm,
  updateFormStatus,
  reOrderForms,
  createSocialShareImage,
  duplicateForm
} from '../../../../api/forms';
import FormItem from './FormItem';
import { AccountContext } from '../../../../providers/AccountProvider';
import Global from '../../../../clients/global'

class FormsManager extends Component {
  static contextType = AccountContext;

  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.onDragEnd = this.onDragEnd.bind(this);

  }

  state = {
    forms: [],
    formName: '',
    isLoading:false,
  };

  async componentDidMount() {
      this.setState({isLoading:true})
      const {tableid}=this.props;
      const forms = await getForms(tableid);
      this.setState({forms:forms,isLoading:false})
  }

  addNewForm = async () => {
      const {formName} = this.state;
      const {tableid,blockid} = this.props;

    await addForm(formName, tableid,blockid);
    const forms = await getForms(tableid);
    this.setState({forms:forms})
  };

  deleteFormLocal = async (formid) => {
      const {tableid} =this.props;

    if (this.input.current.value.toLowerCase() === 'delete') {
      await deleteForm(formid,tableid);
      const forms = await getForms(tableid);
      this.setState({forms:forms})
    }
  };

  duplicateFormLocal = async (formid,name) => {
    const {tableid} =this.props;
    await duplicateForm(formid,name);
    const forms = await getForms(tableid);
    this.setState({forms:forms})

};


  updateFormStatusLocal = async (formid, isdefault, ispublic) => {
    const {tableid} = this.props;
    await updateFormStatus(formid, tableid, isdefault, ispublic);
    const forms = await getForms(tableid);
    this.setState({forms:forms})
  };

  generateSocialShare = async (form) => {
    this.setState({isLoading:true})
    const result = await createSocialShareImage(form);
    if(result.status==='success') {
      toast.info(<div style={{marginRight:'5px',color:'white'}}>
      Click <a href={result.url} style={{color:'white', textDecoration:'underline'}} target="_window">here</a> to view the image.(Clear cache if prior image was generated.)
      <br/>
      <a style={{color:'white',textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/web-form-social-sharing-image-feature" target="_newwindow">Learn more about this feature.</a>
      </div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 15000,
      });

      this.setState({isLoading:false})
    }

  }

  deletFormConfirm = (formid, name) => {
    const message = (
      <div>
        Are you sure you want to delete this form: {name}? Any url's referencing this form will no
        longer work once it is deleted. The form will be abolished.
      </div>
    );

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title={'Delete form '}
            action={this.deleteFormLocal}
            actionValue={formid}
            buttonLabel="OK"
            message={message}
            divRef={this.input}
            showInput={true}
            confirmAction="delete"
            confirmWord="delete"
            height="450px"
            onClose={onClose}
          />
        );
      },
    });
  };

  sortForms = async () => {
    const {forms} = this.state;
    const formids = forms.map(itm=>itm.formid)
    await reOrderForms(formids);
}

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const forms = this.reorder(
      this.state.forms,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      forms,
    },() =>this.sortForms());
  }

  updateFormEmails = async (formid, userId, isEnabled) => {

    const {forms} = this.state;
    const { userInfo } = this.context;
    const idx = forms.findIndex((el) => el.formid === formid);

    if (idx !== -1) {
      const currentForm = forms.filter((el) => el.formid === formid)[0];
      let emailusers = currentForm.emailusers;
      if (isEnabled) {
        if (emailusers === null) emailusers = [{ user:userId }];
        else if (emailusers !== null) emailusers.push({ user:userId });
      } else {
        emailusers = emailusers.filter((el) => el.user !== userId);
      }

      currentForm.emailusers = emailusers;
      const response = await updateFormEmailUsers(formid, emailusers);
      if (response === 'success') {
        const formscopy = [...forms];
        formscopy[idx] = currentForm;
        this.setState({forms:formscopy})

        if (isEnabled) {
          const message = (
            <div>
              You have chosen to activate notices each time the form {currentForm.name} is
              completed. When someone submits a form, GraceBlocks will send you an email notice at
              your email address {userInfo.email} with the contents of the completed form.
              <p />
              <p />
              At any time, you can choose to toggle off this option and disable future emails when
              forms are submitted.
            </div>
          );

          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <GBConfirm
                  title={'Form submission email notice'}
                  action={onClose}
                  showCancelButton={false}
                  buttonLabel="OK"
                  message={message}
                  showInput={false}
                  height="400px"
                  width="500px"
                />
              );
            },
          });
        }
      }
    }
  };

  render() {
      
    const {color,tableid,editForm,editSettings,togglePreview}=this.props;
    const { userInfo} = this.context;
    const {forms,formName,isLoading}=this.state;

    const dbname = userInfo.zones.filter(itm=>itm.id===parseInt(Global.zoneid))[0].database;

    return (
      <div>
        <div
          style={{
            paddingLeft: '30px',
            fontWeight: 'bold',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '120px',
            borderBottom: `1px solid ${color}`,
          }}
        >
          Create new web form
          <div style={{ width: '10px' }} />
          <Input
            value={formName}
            onChange={(e, data) => this.setState({formName:data.value})}
            placeholder="Enter form name here"
          />
          <div style={{ width: '20px' }} />
          <GBButton
            Action={this.addNewForm}
            width="100px"
            color={color}
            text="Add form"
            borderRadius="5px"
          />
        </div>
        {!isLoading ? (
        <div style={{ marginLeft: '30px', marginTop: '10px' }}>
          {forms.length>0 ? (<>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <div style={{ width: '250px' }}>Form name</div>
            <div style={{ width: '125px' }}>Availability</div>
            <div style={{ width: '200px' }}>Added by</div>
            <div style={{ width: '125px' }}>Added on</div>
            <div style={{ width: '225px' }}>Form submission email notice</div>
            <div style={{ width: '125px' }}>Actions</div>
          </div>

          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {forms.length>0 && !isLoading && forms.map((item, index) => (
                    <Draggable key={item.formid.toString()} draggableId={item.formid.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                        <FormItem
                          togglePreview={togglePreview}
                          deleteForm={this.deletFormConfirm}
                          duplicateForm={this.duplicateFormLocal}
                          editForm={editForm}
                          editSettings={editSettings}
                          color={color}
                          dbname={dbname}
                          formData={item}
                          userId={userInfo.id}
                          updateFormEmails={this.updateFormEmails}
                          updateFormStatus={this.updateFormStatusLocal}
                          generateSocialShare={this.generateSocialShare}
                        />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          </>): <div>Enter a name and click Add form above to start creating your first web form. Learn more: <a href="https://www.graceblocks.com/support-article/building-web-forms" target="_newWindow">managing web forms.</a></div>}
        </div>
        ): <Spinner color={color} /> }
      </div>
    );
  }
}

export default FormsManager;
