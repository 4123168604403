/* eslint-disable */
import { getTableAPI } from '../../api/tables';

const ExtractRequiredFields = async (workflow) => {

    const testWorkFlow = structuredClone(workflow)
    /* 4-19-24
       All Workflows start with a trigger of some specific table and row. This data row then becomes
       available to all downstream actions in the entire workflow. The first step of the workflow
       is to retrieve the triggered record, but in order to try to keep performance high, we should
       only retrieve fields which are later used in any of the actions. This is because tables can be
       quite complex and retreiving all data elements slow. 
   
       The goal of this method is to process the entire workflow, adding all fields that are used somewhere
       else in a workflow action. This could be for trigger conditions, or fields used in mapping to add a new record
       or fields /tokens used in sending any email /or form task messages, etc. 
   
       The end result is an array of fields, which is to filter tableInfo to just those columns
       thereby  retrieving only what is necessary.
    */


       /* places to check
            node.data.filters [field]
            node.data.fieldUpdateFilters [field]
            node.data.insertfields [tableid|field]
            node.data.filterConditions [{tableid:[field]}]
            node.data.emailMessage
            node.data.selectedEmail [field]
            node.data.selectedText [field]
            node.data.timeField (tableid|field)
            node.data.multiInsertFilters [fields]
       */

      const baseTableId = testWorkFlow.workflow.nodes[0].data.tableData.id;

       const tableFields={[baseTableId]:[]}

       // 4-29-24 
       /*   A workflow can reference multiple tables via different actions. Here we create
       a simple array of all tables used in entire workflow. This is used when we need to parse
       tokens used in email campaigns, javascript functions, etc.
       */
        const allTables =[];
        testWorkFlow.workflow.nodes?.map(node=>{
            if(node.data.tableData) {
                const idx = allTables.findIndex(el=>el.tableid===node.data.tableData.id);
                if(idx ==-1){
                    allTables.push({name:node.data.tableData.name,tableid: node.data.tableData.id,tableinfo:node.data.tableData?.tableinfo})
                    tableFields[node.data.tableData.id]=[]
                }
            }
            if(node.data?.sourceNodes?.length>0) {
                node.data.sourceNodes.map(e=>{
                    if(e.data.tableData) {
                        const idx = allTables.findIndex(el=>el.tableid===e.data.tableData.id);
                        if(idx ==-1){
                            allTables.push({name:e.data.tableData.name,tableid: e.data.tableData.id,tableinfo:node.data.tableData?.tableinfo})
                        }
                    }
                })
            }
        })

        const extractTerms = (str) => {
            const regex = /{{({)?([^}]+)}}}?/g;
            const matches = [];
            let match;
            while ((match = regex.exec(str)) !== null) {
              const term = match[0];
              matches.push(term);
            }
            return matches;
          };

        const getFields = (functionStr) =>{
            
            const tokens = extractTerms(functionStr);
        
            tokens.map(token =>{
                const strArray = token.replaceAll('{','').replaceAll('}','').replaceAll('&amp;','&').split('||');
               
                const idx  = allTables.findIndex(e=>e.name===strArray[0].split('::')[0]);  //7-8-24 Changed email token format to tablename::tableid.  

                if(idx !==-1) {
                    const tblid = allTables[idx].tableid;
                    const header = strArray[1];
                    const fieldIdx = allTables[idx]?.tableinfo.columns.findIndex(col=>col.header===header);
                    if(fieldIdx !==-1) {
                        if(tableFields[tblid] !==undefined)
                        {
                            tableFields[tblid]?.push(allTables[idx]?.tableinfo.columns[fieldIdx].data)
                        } else {
                            tableFields[tblid] = [allTables[idx]?.tableinfo.columns[fieldIdx].data];
                        }
                    }
                } else {
                    //found tableid not in alltable array. this would be for relational mapping.
                    // const tbl = await getTableAPI(strArray[0].split('::')[0])
                }

            })
        }


       for(const node of testWorkFlow.workflow.nodes){
            // delete node.data.tableData
   
            node.data.filters?.map(fl=>{
                const idx = tableFields[node.data.tableData.id].findIndex(el=>el===fl.field)
                if(idx===-1) {
                    tableFields[node.data.tableData.id].push(fl.field)
                }
            })

            node.data.fieldUpdateFilters?.map(fl=>{
                const idx = tableFields[node.data.tableData.id].findIndex(el=>el===fl)
                if(idx ==-1) {
                    tableFields[node.data.tableData.id].push(fl)
                }
            })

            if(node.data.schedule?.timeField !==undefined && node.data.schedule?.timeField !=='now' ) {
               
               //timefield could be from different source tables.
                const timeFieldValue = node.data.schedule?.timeField.split('|');
                const sourceTbl = timeFieldValue[0]
                const sourceVal = timeFieldValue[1]

                tableFields[sourceTbl].push(sourceVal)
            
            }

            node.data.insertFields?.filter(e=>!['manual','function'].includes(e.mappedValue)).map(fl=>{
                const f = fl.mappedValue.split('|')
                if(tableFields[parseInt(f[0])] !==undefined)
                {
                    tableFields[parseInt(f[0])]?.push(f[1])
                } else {
                    tableFields[parseInt(f[0])] = [f[1]];
                }
            })

            //This gets all tokens used in Javascript functions when adding/updating a table in an action.
            node.data.insertFields?.filter(e=>e.mappedValue==='function').map(fl =>{
                if(tableFields[node.data.tableData.id] !==undefined)
                    {
                        tableFields[node.data.tableData.id]?.push(fl.value)
                    } else {
                        tableFields[node.data.tableData.id] = [fl.value];
                    }
                getFields(fl.dataValue);
            })

            Array.isArray(node.data.selectedEmail) && node.data.selectedEmail?.filter(e=>!['manual','function'].includes(e.field)).map(fl=>{
                const f = fl.field.split('|')
                if(tableFields[parseInt(f[0])] !==undefined)
                {
                    tableFields[parseInt(f[0])]?.push(f[1] )
                } else {
                    tableFields[parseInt(f[0])] = [f[1]];
                }
            })

            //Create task form, sets this field as single value, not array
            if(node.data.selectedEmail !==undefined && !Array.isArray(node.data.selectedEmail) && node.data.selectedEmail !=='manual') {
                const em = node.data.selectedEmail.split('|')
                if(tableFields[parseInt(em[0])] !==undefined)
                {
                    tableFields[parseInt(em[0])]?.push(em[1] )
                } else {
                    tableFields[parseInt(em[0])] = [em[1]];
                }
            }

            if(node.data.imageField !==undefined) {
                const em = node.data.imageField.split('|')
                if(tableFields[parseInt(em[0])] !==undefined)
                {
                    tableFields[parseInt(em[0])]?.push(em[1] )
                } else {
                    tableFields[parseInt(em[0])] = [em[1]];
                }
            }

            if(node.data.emailMessage !==undefined) {
                getFields(node.data.emailMessage.fromName);
                getFields(node.data.emailMessage.subject);
                getFields(node.data.emailMessage.replyTo);
                getFields(node.data.emailMessage.message);

                if(node.data.emailMessage.CC !==undefined) {
                    getFields(node.data.emailMessage.CC);
                }
                if(node.data.emailMessage.BCC !==undefined) {
                    getFields(node.data.emailMessage.BCC);
                }
                
            }

            if(node.data.taskMessage !==undefined) {
                getFields(node.data.taskMessage.fromName);
                getFields(node.data.taskMessage.subject);
                getFields(node.data.taskMessage.replyTo);
                getFields(node.data.taskMessage.message);

                if(node.data.taskMessage.CC !==undefined) {
                    getFields(node.data.taskMessage.CC);
                }
                if(node.data.taskMessage.BCC !==undefined) {
                    getFields(node.data.taskMessage.BCC);
                }

            }

            if(node.data.newUserMessage !==undefined) {
                getFields(node.data.newUserMessage.fromName);
                getFields(node.data.newUserMessage.subject);
                getFields(node.data.newUserMessage.replyTo);
                getFields(node.data.newUserMessage.message);

                if(node.data.newUserMessage.CC !==undefined) {
                    getFields(node.data.newUserMessage.CC);
                }
                if(node.data.newUserMessage.BCC !==undefined) {
                    getFields(node.data.newUserMessage.BCC);
                }
            }
         

            if(node.data.existingUserMessage !==undefined) {
                getFields(node.data.existingUserMessage.fromName);
                getFields(node.data.existingUserMessage.subject);
                getFields(node.data.existingUserMessage.replyTo);
                getFields(node.data.existingUserMessage.message);

                if(node.data.existingUserMessage.CC !==undefined) {
                    getFields(node.data.existingUserMessage.CC);
                }
                if(node.data.existingUserMessage.BCC !==undefined) {
                    getFields(node.data.existingUserMessage.BCC);
                }
            }

            if(node.data.textMessage !==undefined) {
                getFields(node.data.textMessage)
            }

            if(node.data.confettiMessage !==undefined) {
                getFields(node.data.confettiMessage)
            }

            if(node.data.promptMessage !==undefined) {
                getFields(node.data.promptMessage)
            }

            if(node.data.url !==undefined) {
                getFields(node.data.url)
            }

            if(node.data.selectedPassword !==undefined && node.data.selectedPassword !=='manual') {
                const f = node.data.selectedPassword.split('|')
                if(tableFields[parseInt(f[0])] !==undefined)
                {
                    tableFields[parseInt(f[0])]?.push(f[1] )
                } else {
                    tableFields[parseInt(f[0])] = [f[1]];
                }
            }

            //1-20-25 added duplicatefields of new table where insert is happening.
            node.data.duplicateSettings?.settings?.duplicateFields.map(dupCase=>{
                dupCase.map(f=>{
                    if(tableFields[parseInt(node.data.tableData.id)] !==undefined) {
                        tableFields[parseInt(node.data.tableData.id)]?.push(f)
                    } else {
                        tableFields[parseInt(node.data.tableData.id)] = [f]
                    }
                })
               
            })

   
            if(node.data.selectedFirstName !==undefined && node.data.selectedFirstName !=='manual') {
                const f = node.data.selectedFirstName.split('|')
                if(tableFields[parseInt(f[0])] !==undefined)
                {
                    tableFields[parseInt(f[0])]?.push(f[1] )
                } else {
                    tableFields[parseInt(f[0])] = [f[1]];
                }
            }

            if(node.data.selectedLastName !==undefined && node.data.selectedLastName !=='manual') {
                const f = node.data.selectedLastName.split('|')
                if(tableFields[parseInt(f[0])] !==undefined)
                {
                    tableFields[parseInt(f[0])]?.push(f[1] )
                } else {
                    tableFields[parseInt(f[0])] = [f[1]];
                }
            }
           
            if(node.data.selectedMobile !==undefined && node.data.selectedMobile !=='manual') {
                const f = node.data.selectedMobile.split('|')
                if(tableFields[parseInt(f[0])] !==undefined)
                {
                    tableFields[parseInt(f[0])]?.push(f[1] )
                } else {
                    tableFields[parseInt(f[0])] = [f[1]];
                }
            }


            node.data.selectedText?.filter(e=>!['manual','function'].includes(e.field)).map(fl=>{
                const f = fl.field.split('|')
                if(tableFields[parseInt(f[0])] !==undefined)
                {
                    tableFields[parseInt(f[0])]?.push(f[1] )
                } else {
                    tableFields[parseInt(f[0])] = [f[1]];
                }
            })
        

            if(node.data.filterConditions !==undefined) {
                Object.keys(node.data.filterConditions).map(key=>{
                    
                    if(tableFields[parseInt(key)] !==undefined)
                    {
                        node.data.filterConditions[key].map(itm=>{
                            tableFields[parseInt(key)]?.push(itm.field);
                        })
                    } 
                    else {
                        tableFields[parseInt(key)] = node.data?.filterConditions[key]?.map(col=>col.field);
                    }
                })
            }

        //9-16-24
        if(node.data.selectedMultiRelationalField !==undefined) 
        {
            const [sourceTab,sourceField,relTable,relField] = node.data.selectedMultiRelationalField.split('||');
            const tblid = sourceTab.split('::')[1]
            if(tableFields[parseInt(tblid)] !==undefined)
            {
                tableFields[parseInt(tblid)]?.push(relField)
            } else {
                tableFields[parseInt(tblid)] = [relField];
            }
        }

        //10-16-24
        node.data.multiInsertFilters?.map(fl=>{
            //First check if multiInsertTable is defined in list
            if(tableFields[node.data.multiInsertTableId] ===undefined) {
                tableFields[node.data.multiInsertTableId] = [];
            } 

            const idx = tableFields[node.data.multiInsertTableId].findIndex(el=>el===fl.field)
            if(idx ==-1) {
                tableFields[node.data.multiInsertTableId].push(fl.field)
            }
        })

       }

       //1-20-24 make the list of fields in each table key unique, remove duplicates.
       const finalResult = {};
       for (const key in tableFields) {
         finalResult[key] = [...new Set(tableFields[key])]; // Use Set to get unique values
       }
       return finalResult;   
   }
   
export default ExtractRequiredFields