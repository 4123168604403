/* eslint-disable */
import React, { useEffect } from 'react';

const RelationalTag = ({ color, value }) => {
  // print(value)
  return (
    <>
      {value && value.length > 0 ? ( // Check if value is not null and has elements
        value.map((el) =>
          el.data ? ( // Check if el.data is not null or undefined
            <div
              style={{
                display: 'inline-flex',
                margin: '3px',
                whiteSpace: 'noWrap',
                paddingLeft: '5px',
                paddingRight: '5px',
                // alignSelf: 'center',
                borderRadius: '7px',
                backgroundColor: `${color}0D`, //`${color}B3`
                // color: 'white',
              }}
              key={`${el.id}-${el.data}`}
            >
              {el.data}
            </div>
          ) : null, // Render nothing if el.data is null or undefined
        )
      ) : (
        <div></div> // Render empty div if value is null, undefined, or empty
      )}
    </>
  );
};

export default RelationalTag;

// /* eslint-disable */
// import React, { useEffect } from 'react';

// const RelationalTag =({color,value}) => {

//   // print(value)
//     return (
    
//      value && value.map(el=> el.data && (
//         <div
//         style={{
//           display:'inline-flex',
//           margin: '3px',
//           whiteSpace:'noWrap',
//           paddingLeft: '5px',
//           paddingRight: '5px',
//           // alignSelf: 'center',
//           borderRadius: '7px',
//           backgroundColor: `${color}0D`, //`${color}B3`
//           // color: 'white',
//         }}
//         key={`${el.id}-${el.data}`}
//       >
//         {el.data}
//       </div>
//      ))

//     )

// }


// export default RelationalTag