/* eslint-disable */
import React, { useEffect } from 'react';
import Divider from '../DataList/Divider';
import TextControl from '../../../../components/TextControl/TextControl';

const ControlRender = (props) => {
  // useEffect(() => {
  //     console.log(props.control)
  // },[])


  const { control, collapse, color,blockid, role, updateSectionName,submitSectionName,deleteSection,tableid,userInfo,loadRelatedRecord,canEdit,id,editMode,index,unmaskRole } = props;
  if (control.name !== undefined) {
    return (
      <Divider
        key={control.name}
        setSectionExpanded={collapse}
        sectionName={control.name}
        updateSectionName={updateSectionName}
        submitSectionName={submitSectionName}
        deleteSection={deleteSection}
        sectionNumber={control.sectionNumber}
        fieldCount={control.count}
        isSectionExpanded={!control.collapse}
        color={color}
        role={role}
        editMode={editMode}
        isEdit={control.isEdit}
      />
    );
  } else
   {
    return (
      <TextControl
        key={control.data}
        blockid={blockid}
        updateValue={props.updateValue}
        submitChanges={props.submitChanges}
        loadRelatedRecord={loadRelatedRecord}
        color={color}
        tableid={tableid}
        value={control.value}
        colInfo={control}
        role={role}
        canEdit={canEdit}
        rowId={id}
        editMode={editMode}
        index={index}
        unmaskRole={unmaskRole}
      />
    );
  }

};

export default ControlRender;
