/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useContext } from 'react';
import { css, jsx } from '@emotion/core'
import { Dropdown, Input } from 'semantic-ui-react';
import { debounce } from "debounce";
import { getOptionsAPI } from '../../../../api/options';
import { getRelationalTableData } from '../../../../api/tables';
import FilterOperators from './FilterOperators';
import NestedDropdowns from '../DataList/nestedDropdowns';


const OptionsFilter = (props) => {
  const [optionData, setOptionData] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [operator, setOperator] = useState(props.filter.operator ?? 'searchvalues');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filterObject, setFilterObject] = useState({});
  const [isNested, setIsNested] = useState(false);
  const [colInfoClone, setCoInfoClone] = useState(); // needed to pass to nesteddropdown and ensure the lookup='multiple' in this filter mode.
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm]= useState(['contains','notcontains'].includes(props.filter.operator) ? props.filter.value : '')


  const {
    displayfield,
    filter,
    header,
    field,
    uitype,
    UpdateFilter,
    color,
    tableid,
    colInfo,
    role,
    callingComponent,
  } = props;

  useEffect(() => {
    const tempInfo = structuredClone(colInfo);
    tempInfo.lookup = 'multiple';
    setCoInfoClone(tempInfo);
    let limit = 50;
    let format = 'dropdown';
    if (colInfo.source.nestedFields !== undefined && colInfo.source.nestedFields.length > 0) {
      limit = 10000;
      format = 'raw';
    }

    let listdata = [];
    const fetchData = async () => {
      let tempData = '';
      if ((uitype === 6 || uitype === 7) && colInfo.jointype === undefined) {
        listdata = await getOptionsAPI(field, tableid, 'dropdown');
      } else if (uitype === 1 && colInfo.originalSource === undefined) {
        listdata = await getRelationalTableData(
          tableid,
          colInfo,
          role,
          '',
          limit,
          !['contains','notcontains','isnull','isnotnull'].includes(filter.operator) && filter.value !== undefined && filter.value?.length > 0  ? filter.value : null,
          format,
          false,
          true
        );
      } else if (uitype === 1 && colInfo.originalSource !== undefined) {
        listdata = await getRelationalTableData(
          tableid,
          colInfo.originalSource,
          role,
          '',
          limit,
          null,
          format,
          false,
          true
        );
      } else if ((uitype === 6 || uitype === 7) && colInfo.jointype === 'relational-lookup') {
        listdata = await getOptionsAPI(
          colInfo.source.lookupfield.replace('_options', ''),
          colInfo.source.lookuptableid,
          'dropdown',
        );
      } else if (
        (uitype === 6 || uitype === 7) &&
        (colInfo.jointype === 'relational-lookup-lookup' ||
          colInfo.jointype === 'relational-lookup-special' ||
          colInfo.jointype === 'relational-special-special')
      ) {
        const tblid = colInfo.source.grouptable.split('_')[1];
        listdata = await getOptionsAPI(
          colInfo.source.finallookupfield.replace('_options', ''),
          tblid,
          'dropdown',
        );
      } else if ((uitype === 6 || uitype === 7) && colInfo.jointype === 'relational-special') {
        listdata = await getOptionsAPI(
          colInfo.source.lookupfield,
          colInfo.source.lookuptableid,
          'dropdown',
        );
      }

      //8-3-22 added this idea of initial object to simplify how updating the filter happens
      //between dropdown selectoin and when changing the operator.
      const initialFilter = {
        field,
        header,
        value: [],
        uitype,
        textvalues: [],
        displayfield,
        operator: filter.operator ?? 'searchvalues',
      };

      if (!['contains','notcontains','isnull','isnotnull'].includes(filter.operator) && filter.value !== undefined ) {
        
        //1-26-24 I recently changed the logic for select options and rel id' to use int's intead
        //of stringa and made that change in all the places. However, older bookmarks are still saved
        //with the string values. This line of code converts them to int's and any new bookmarks created
        //will use the int values going forward. At some point I can remove this line .
        filter.value = filter.value.map(el=>parseInt(el));

        setSelectedOptions(filter.value);
        setOperator(filter.operator);
        initialFilter.value = filter.value;
        initialFilter.operator = filter.operator;
     
        initialFilter.nestedLevels=filter.nestedLevels;

        let textvalues = [];

        filter.value.forEach((itm) => {
          const rowindex = listdata.findIndex((el) => format==='raw' ? el.id : el.value === itm);
          if (rowindex !== -1) {
            textvalues.push(format==='raw' ? listdata[rowindex].CustomID : listdata[rowindex].text);
          }
        });

        initialFilter.textvalues = textvalues;
      } 

      setOptionData(listdata);
      setFilterObject(initialFilter);
      setIsNested(
        colInfo.source.nestedFields !== undefined && colInfo.source.nestedFields.length > 0,
      );
    };
    fetchData();
  }, []);


  const updateTextFilter =(val) =>{
    
    setSearchTerm(val);
    filterObject.value=val;
    UpdateFilter(filterObject);
  }


  const updateFilter = (items, tmpoperator) => {
    setSelectedOptions(items.value);
    const textvalues = [];
    const tempOptions = structuredClone(optionData);

    // Check to see if passed in value is in existing list. It might not be if they are filtering big list of options.
    // in order for it to display, we need to add it to list if it doesnt' exist.

    if (uitype === 1 && tmpoperator !== 'isnull' && tmpoperator !== 'isnotnull' && !isNested) {
      items.value.map((el) => {
        const idx = optionData.findIndex((itm) => itm.value === el);
        if (idx === -1) {
          const index = items.options.findIndex((o) => parseInt(o.value) === parseInt(el));
          tempOptions.push(items.options[index]);
          setOptionData(tempOptions);
        }
      });
    }
    if (!isNested) {
      items.value &&
        items.value.forEach((itm) => {
          const rowindex = tempOptions?.findIndex((el) => el.value === itm);
          if (rowindex && rowindex !== -1) {
            textvalues.push(tempOptions[rowindex].text);
          }
        });
    } else {
      items.text.map((el) => {
        textvalues.push(el);
      });
      
      // When updating fileter, if nestedLevels node exists, we need to append the text values of that to overal textvalues.
      if(filterObject.nestedLevels !==undefined) {
        Object.keys(filterObject.nestedLevels).map((itm) =>{
            filterObject.nestedLevels[itm].text.map((t) => textvalues.push(t))
          });
      }
    }

    filterObject.value = items.value;
    filterObject.textvalues = textvalues;

    UpdateFilter(filterObject);
    setFilteredOptions([]);
  };


  let filterOption = [
    { key: 'searchvalues', text: 'Includes (or)', value: 'searchvalues' },
    { key: 'doesnotinclude', text: 'Does not include (or)', value: 'doesnotinclude' },
  ];

  if (colInfo.uitype===6 || ((colInfo.uitype===1 || colInfo.source?.lookupuitype===1) && colInfo.lookup==='multiple')) {
    filterOption.splice(1, 0, { key: 'searchvalues_and', text: 'Includes (and)', value: 'searchvalues_and' });
    filterOption.splice(3, 0, { key: 'doesnotinclude_and', text: 'Does not include (and)', value: 'doesnotinclude_and' });
  }

  if(colInfo.uitype===1 || colInfo.source?.lookupuitype===1) {
    filterOption.push({ key: 'contains', text: 'Contains', value: 'contains' });
    filterOption.push( { key: 'notcontains', text: 'Does not contain', value: 'notcontains' });
  }

  filterOption.push({ key: 'isnull', text: 'Empty (null)', value: 'isnull' });
  filterOption.push( { key: 'isnotnull', text: 'Not empty (not null)', value: 'isnotnull' });
  


  const changeOperator = (newoperator) => {
    setOperator(newoperator);
    filterObject.operator = newoperator;
    console.log(filterObject)
    UpdateFilter(filterObject);
  };

  const removeFilter = (newfield) => {
    // updateFilter([]);
    props.remove(newfield);
  };

  const nestedFieldUpdate = (field, values) => {
    const nestedItems = {
      value: values.map((el) => el.optionid),
      text: values.map((el) => el.data),
    };
    updateFilter(nestedItems);
  };

  const updateSelectedLevel = (field, level,textvalues) => {
    //9-28-22 When allowing users to filter web form fields, we want to allow them to choose to filter
    //at any defined level of the nested fields. When editing filter from webform filter page
    // we will allow them to choose level/values to include in the match.
    // This will be stored as array of values
    // Example: nestedLevels: {field_1: [2,4], field_3: [4]}
    //0-29-22 added the textvalues param for when configuring web form filtering options. Here we need to pass
    //the full string of textvalues from both adding levels & adding individual records. 

    //If null value passed in, this indicates they clicked to remove the nestedLevels field, so we remove the node.
   
    if(level !==null) {
        filterObject.nestedLevels = level;
        filterObject.textvalues=textvalues
    } else {
        delete filterObject.nestedLevels
        filterObject.textvalues=textvalues
    }

    setFilterObject(filterObject)
    UpdateFilter(filterObject);
    
  };

  const handleDropdownSearch = async (value) => {
    let listdata = [];
    if (uitype === 1 && colInfo.originalSource === undefined) {
      listdata = await getRelationalTableData(
        tableid,
        colInfo,
        role,
        value,
        50,
        null,
        'dropdown',
        false,
        true
      );
    } else if (uitype === 1 && colInfo.originalSource !== undefined) {
      listdata = await getRelationalTableData(
        tableid,
        colInfo.originalSource,
        role,
        value,
        50,
        null,
        'dropdown',
        false,
        true
      );
    }
    setFilteredOptions(listdata);
    setIsSearching(false);
  };

  const debouncedHandleDropdownSearch = debounce(handleDropdownSearch, 500); 

  const displayvalue = operator === 'isnull' ? 'Empty (is null)' : 'Not empty (is not null)';

  // Just setting this to default grey color, otherwise it will use block color.
  // keeping logic just in case we want to swap back to using colors.

  return (
    <div css={{ 
      '&& .ui.selection.dropdown': {
        backgroundColor:`${color}1A !important`
       },
      marginBottom: '23px', width: '100%' }}>
           <FilterOperators
           header={header}
           operator={operator}
           filterOption={filterOption}
           RemoveAction={removeFilter}
           RemoveActionValue={field}
           ChangeOperator={changeOperator}
           color={color}
         />
      {operator === 'isnull' || operator === 'isnotnull' || operator==='contains' || operator==='notcontains' ? (
        <Input fluid value={searchTerm} onChange={(e,data)=>updateTextFilter(data.value)} />
      ) : colInfo.source.nestedFields === undefined ||
        (colInfo.source.nestedFields && colInfo.source.nestedFields.length === 0) ? (
        <Dropdown
          selectOnBlur={false}
          multiple
          search
          fluid
          selection
          value={selectedOptions}
          onSearchChange={(e, data) => {
            setIsSearching(true); 
            debouncedHandleDropdownSearch(data.searchQuery);
        }} 
          options={isSearching ? [] : filteredOptions.length > 0 ? filteredOptions : optionData}
          onChange={(e, data) => updateFilter(data)}
        />
      ) : optionData.length > 0 ? (
        <NestedDropdowns
          data={optionData}
          colInfo={colInfoClone}
          nestedFields={colInfo.source.nestedFields}
          role={3}
          updateValue={nestedFieldUpdate}
          color={color}
          callingComponent={callingComponent}
          preSelected={selectedOptions}
          conditions={filter}
          updateSelectedLevel={callingComponent === 'form' ? updateSelectedLevel : undefined}
        />
      ) : null}
    </div>
  );
};

export default OptionsFilter;
