/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, Component } from 'react';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import { Popup } from 'semantic-ui-react';

import Icon from '@mdi/react';
import { mdiPlus,mdiCheckBold,mdiFlare,mdiAutoMode } from '@mdi/js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ControlRender from './ControlRender';
import { setDeep } from '../../../../utils/SetDeep';
import { get } from 'lodash';
import GBButton from '../../../../components/GBButton/GBButton';
import TextButton from '../../../../components/TextButton/TextButton';

const portal = document.createElement('div');
portal.classList.add('reactdnd');
document.body.appendChild(portal);

class PortalAwareItem extends Component {
  render() {
    const {
      provided,
      snapshot,
      item,
      collapse,
      color,
      role,
      updateSectionName,
      submitChanges,
      updateValue,
      submitSectionName,
      deleteSection,
      addSection,
      tableid,
      blockid,
      userInfo,
      loadRelatedRecord,
      canEdit,
      id,
      editMode,
      index,
    } = this.props;

    const usePortal = snapshot.isDragging;

    const child = (
      <div
        style={{ display: 'flex', backgroundColor: 'red' }}
        ref={provided.innerRef}
        key={item.data}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <ControlRender
          key={item.data}
          control={item}
          blockid={blockid}
          collapse={collapse}
          role={role}
          color={color}
          updateValue={updateValue}
          submitChanges={submitChanges}
          tableid={tableid}
          loadRelatedRecord={loadRelatedRecord}
          updateSectionName={updateSectionName}
          deleteSection={deleteSection}
          submitSectionName={submitSectionName}
          userInfo={userInfo}
          canEdit={canEdit}
          id={id}
          editMode={editMode}
          index={index}
        />
      </div>
    );

    if (!usePortal) {
      return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, portal);
  }
}

class ControlList extends Component {
  state = {
    items: [],
    editedList: [],
    originalItems: [],
    isLoading: false,
    editMode: false,
    moreData:false,
    fetchingData:false,
    sectionsWithData:[]
  };

  componentDidMount() {
    this.LoadControlList();
  }


  LoadControlList = () => {
    const { editMode } = this.state;
    this.setState({ isLoading: true,sectionsWithData:[]});
    const { dataItem, color, userInfo } = this.props;

    // const Newtableinfo = JSON.parse(JSON.stringify(dataItem.tableinfo)); 1-24-24
    const Newtableinfo = structuredClone(dataItem.tableinfo);

    let fields = Newtableinfo.columns
      .filter(
        (itm) =>
          itm.data !== 'id' &&
          itm.removeColumn === undefined && // added 12-30-2021 to remove "locked"/hidden columns from user who does not have access
          ((itm.uitype === 1 && itm.lookup === 'single') || itm.uitype !== 1) &&
          itm.uitype !== 12 &&
          itm.uitype !== 4 &&
          (!itm.source || (itm.source && itm.source.lookupuitype !== 4)),
      )
      .sort((a, b) => (a.detailviewsort > b.detailviewsort ? 1 : -1));


    let isMoreData=false;
    let maxLookup= Newtableinfo.maxLookup !==undefined ? parseInt(Newtableinfo.maxLookup) : 30;
    
    fields.forEach((f) => {
      f.display = true;
      f.value = dataItem.selectedRow[f.data];
      f.color = { color };
      if(f.uitype===18 && dataItem.selectedRow[f.data]?.length===maxLookup) {
        isMoreData=true;
      }
    });

    const sections = [...dataItem.tableinfo.sections];

    sections.forEach((itm, index) => {
      itm.sectionNumber = index + 1;
      fields.splice(itm.insertAt, 0, itm);
    });

    sections.forEach((sec,index) => {
      const secisExpanded = get(userInfo, `details."${dataItem.tableid}"."${sec.sectionNumber}"`);
     
      if (secisExpanded !== undefined) {
        sec.collapse = !secisExpanded;
        for (let x = sec.insertAt + 1; x <= sec.insertAt + sec.count; x += 1) {
          if (fields[x] !== undefined && fields[x].data !==undefined) {
            fields[x].display = secisExpanded;
          }
        }
      } 
      else if(index !==0){
        sec.collapse=true;
        for (let x = sec.insertAt + 1; x <= sec.insertAt + sec.count; x += 1) {
          if (fields[x] !== undefined && fields[x].data !==undefined) {
            fields[x].display = false;
          }
        }
      }

    });

    this.setState({ items: fields, isLoading: false, originalItems: fields,moreData: isMoreData,fetchedMoreData:false,fetchingData:false });
    this.getFieldCount(fields);
  };

  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):

    if (this.props.dataItem !== prevProps.dataItem) {
      this.LoadControlList();
    }
    if (this.props.index !== prevProps.index) {
      this.LoadControlList();
    }
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // this.props.updateTable(this.props.dataItem.tableid, result);
    this.setState({editedList:result})
    return result;
  };

  onDragEnd = (result) => {
    // dropped outside the list

    const { items } = this.state;
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    console.log(result);
    //12-19-24 added logic so you cannot move control above top divier to top 0 index
    //and canot' move the top divier from index =0 to anywwhere else. Also adding logic
    //that you cannot delete the top divider, which shoudl ensure logic stays valid in all cases.
    if(result.destination.index===0 || result.source.index===0) {
      return;
    }

    const newitems = this.reorder(items, result.source.index, result.destination.index);
    this.setState({ items: newitems });
    this.getFieldCount(newitems);
  };

  collapseSection = async (isExpanded, sectionName) => {
    const { dataItem, userInfo,refreshRecord } = this.props;
    const {sectionsWithData} = this.state;
    const idx = this.state.items.findIndex((itm) => itm.name === sectionName);

    if (idx !== -1) {
      const section = this.state.items[idx];

      setDeep(
        userInfo,
        ['details', `"${dataItem.tableid}"`, `"${section.sectionNumber}"`],
        isExpanded,
        true,
      );

      section.collapse = !section.collapse;

      let newitems = this.state.items;
      for (let x = section.insertAt + 1; x <= section.insertAt + section.count; x += 1) {
        newitems[x].display = !section.collapse;
      }

      this.setState({ items: newitems });
     
      //12-3-24 keep track of which sections I've fetched data for, so that if they 
      //expand/collapse, expand, we don't rerun the query.
      const secIdx = sectionsWithData.findIndex(itm=>itm===sectionName);
      if(secIdx===-1 && isExpanded) {
         await refreshRecord(dataItem);
         sectionsWithData.push(sectionName);
         this.state.sectionsWithData = sectionsWithData;
      }
    }
  };

  getFieldCount = (items) => {
    const sections = items.filter((itm) => itm.count !== undefined);
    for (let x = 0; x < sections.length; x += 1) {
      const currentindex = items.findIndex((itm) => itm.name == sections[x].name);
      if (sections[x + 1] !== undefined) {
        const nextIndex = items.findIndex((itm) => itm.name === sections[x + 1].name);
        const diff = nextIndex - currentindex - 1;
        items[currentindex].count = diff;
        items[currentindex].insertAt = currentindex;
        // console.log(`${sections[x].name} - ${diff} - insertAt: ${currentindex}`);
      } else {
        const diff = items.length - currentindex - 1;
        items[currentindex].count = diff;
        items[currentindex].insertAt = currentindex;
        // console.log(`${sections[x].name} - ${diff} - insertAt: ${currentindex}`);
      }
    }
  };

  toggleEditMode = () => {
    const { setIsEditMode } = this.props;
    const { editMode, originalItems } = this.state;
    setIsEditMode(!editMode); // 10-27-22 this updated detailview edit mode to grey /ungrey out next/prev buttons.
    this.setState({ editMode: !editMode, items: originalItems }, () => {
      this.LoadControlList();
    });
  };

  fetchMoreData = async ()=> {
    const {submitChanges} = this.props;
    this.setState({fetchingData:true})
    await submitChanges(true,true)
    this.setState({fetchingData:false})
 
  }

  saveChanges = async () => {
    const { submitChanges } = this.props;
    const {editedList} = this.state;
    const result = await submitChanges();

    if(result==='success') {
      // 12-30-22 only update table sections/list order if there were changes.
      if(editedList.length>0){
        await this.props.updateTable(this.props.dataItem.tableid, editedList); //new 11-29
      }
      //11-7-24 removed the "isClose" param, as the submitChanges method now calls this in detailview
      //automatically as we don't support "save" and keep open.
        this.setState({ editMode: false },()=>this.LoadControlList());
    }
    
  };

  render() {
    const { items, isLoading, editMode,moreData,fetchedMoreData,fetchingData } = this.state;
    const {
      color,
      blockid,
      dataItem,
      updateValue,
      submitChanges,
      role,
      updateSectionName,
      submitSectionName,
      deleteSection,
      addSection,
      userInfo,
      loadRelatedRecord,
      isRoot,
      isShare
    } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          position:'relative',
          flexDirection: 'column',
          margin: '10px',
          marginBottom: '10px',
          width: '100%',
          paddingBottom: editMode ? '160px' : null, //1-26-24 add pssing on bomttom so select lists don't get cut off.
          // border:'1px solid red',
          // height:'100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginRight: '20px',
            marginLeft: '20px',
          }}
        >

          {/* 4-8-23 We don't show this anymore since CTE Query brings back all lookup values
          In future, we may use this again for different data load scenarios, so keeping commented out in code.
          */}
          {/* {moreData && dataItem.selectedRow.fetchedData===undefined && isRoot && !isShare ? (<>
              {!fetchingData ? (
                <div css={{svg: {color:color}, height:'30px', visibility:moreData && !fetchedMoreData ? 'visible' : 'hidden',color:{color}, cursor:'pointer','&:hover': {svg: {color:`${color}80`}} }}  >
                <Popup   mouseEnterDelay={500} content='More info may exist! Click me to fetch everything.' hoverable trigger={
                <Icon onClick={this.fetchMoreData}  path={mdiAutoMode} size="30px" spin={5} />
                  } />
                </div>
              ) : (
                <Icon path={mdiCheckBold} size="30px" color={'green'} />
              )}
          </>):<div></div>} */}
  
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginRight: '20px',
              marginLeft: '20px',
            }}
          >
            {!editMode && dataItem.selectedRow.canEdit ? (
              <GBButton width="75px" text="Edit" color={color} Action={this.toggleEditMode} />
            ) : null}
            {editMode && dataItem.selectedRow.canEdit ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '300px',
                  marginRight:'50px',
                  justifyContent: 'flex-end',
                  marginBottom:'10px'
                }}
              >
                <TextButton
                  text="Cancel"
                  textColor="#757575"
                  hoverColor="#75757580"
                  Action={this.toggleEditMode}
                />
                <div style={{width:'20px'}}/>
        
                <GBButton
                  text="Save and view"
                  color={color}
                  Action={this.saveChanges}
                />
              </div>
            ) : null}
          </div>
        </div>
        {/* 6-10-2020 if they are builder, put items in draggable/editable interface, otherwise display list as is.  */}
        {this.props.role === 3 && editMode ? (
          <div style={{position:'relative'}}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {items.length > 0 &&
                      !isLoading &&
                      items.map((item, index) =>
                        item.display ? (
                          <Draggable
                            draggableId={item.name !== undefined ? item.name : item.data}
                            index={index}
                            key={item.name !== undefined ? item.name : item.data}
                          >
                            {(draggableProvided, draggableSnapshot) => (
                              <PortalAwareItem
                                blockid={blockid}
                                color={color}
                                collapse={this.collapseSection}
                                item={item}
                                role={role}
                                tableid={dataItem.tableid}
                                updateValue={updateValue}
                                updateSectionName={updateSectionName}
                                loadRelatedRecord={loadRelatedRecord}
                                submitSectionName={submitSectionName}
                                deleteSection={deleteSection}
                                addSection={addSection}
                                submitChanges={submitChanges}
                                provided={draggableProvided}
                                snapshot={draggableSnapshot}
                                userInfo={userInfo}
                                canEdit={dataItem.selectedRow.canEdit}
                                id={dataItem.selectedRow.id}
                                editMode={editMode}
                                index={index}
                                unmaskRole={dataItem.tableinfo.security?.unmask}
                              />
                            )}
                          </Draggable>
                        ) : null,
                      )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div
              onClick={() => addSection()}
              css={{
                marginTop: '20px',
                borderRadius: '20px',
                position:'relative',
                display: 'flex',
                marginLeft: '30px',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '10px',
                '&:hover': {
                  backgroundColor: '#F1EFEF',
                  color: color,
                  transition: 'all .3s ease',
                  svg: {
                    color: 'white',
                    transition: 'all .3s ease',
                    backgroundColor: color,
                    borderRadius: '50px',
                  },
                },
              }}
            >
              <Icon path={mdiPlus} size="20px" />
              <div style={{ marginLeft: '5px' }}>Add section</div>
            </div>
          </div>
        ) : (
          <div>
            {!isLoading &&
              items.map((item, index) =>
                item.display ? (
                  <ControlRender
                    key={index.toString()}
                    blockid={blockid}
                    control={item}
                    collapse={this.collapseSection}
                    color={color}
                    role={role}
                    tableid={dataItem.tableid}
                    loadRelatedRecord={loadRelatedRecord}
                    updateValue={updateValue}
                    submitChanges={submitChanges}
                    userInfo={userInfo}
                    canEdit={dataItem.selectedRow.canEdit}
                    id={dataItem.selectedRow.id}
                    editMode={editMode}
                    index={index}
                    unmaskRole={dataItem.tableinfo.security?.unmask ??0}
                  />
                ) : null,
              )}
          </div>
        )}
      </div>
    );
  }
}

export default ControlList;
