/* eslint-disable */
import React, {useEffect} from 'react';
import Icon from '@mdi/react';
import { mdiInformationOutline,mdiPlusBox,mdiLoading } from '@mdi/js';
import {Popup}  from 'semantic-ui-react';
import TextButton from '../../../../components/TextButton/TextButton';

const BottomBar = ({currentPage, recordCount,filterCount,viewingCount,showSecurityMessage,mathNumbers,insertNewTableRow,color,canAddRow,isLoading,isSimpleView}) => {

  return ( 
    <div
      style={{
        display: 'flex',
        // width:'100%',
        flexDirection:'row',
        paddingLeft: '0px',
        alignItems:'center',
        fontWeight:'bold',
        backgroundColor: '#F1F1F1',
        justifyContent: 'space-between',
        paddingRight:'160px',
      }}
    > 
          
    <div style={{display:'flex',flexDirection:'row', alignItems:'center',marginLeft:'5px',marginBottom:'2px'}}>
        {insertNewTableRow !==undefined && insertNewTableRow!==null && canAddRow ? (<>
      <TextButton text="Add row" Action={insertNewTableRow} icon={mdiPlusBox} iconPosition="left" textColor={color} hoverColor={`${color}80`} />
      </>):null}
    </div>
   <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
    {isSimpleView ? (
        <div style={{marginRight:'10px', display:'flex', flexDirection:'row', alignItems:'center',color:'#E06055'}}>⚠ Simple mode (query exceeds performance threshold). 
        <Popup content={<div>Query exceeds GraceBlocks' performance response-time threshold. Results are loaded in preview mode. Visit the details page to view full record and apply edits if authorized.</div>}
                 mouseEnterDelay={200}
                 trigger={<Icon path={mdiInformationOutline}  size="17px"  />} />
         </div>
    ) :null}
    {showSecurityMessage ? (
    <div style={{marginRight:'10px', display:'flex', flexDirection:'row', alignItems:'center'}}>🔒 Security applied. This is a view-only spreadsheet view. 
   <Popup content={<div>Your security profile allows you to see records in the list which you are not authorized to edit. This is why spreadsheet view is view-only.  When you access the details page of records you are authorized to edit (using the first column ID or 🔎 icon), you can make edits to specific records where you have authorization.</div>}
            mouseEnterDelay={200}
            trigger={<Icon path={mdiInformationOutline}  size="17px"  />} />
    </div>
    ) : null}
    {mathNumbers !==null && mathNumbers !==undefined ? <div>
      <span style={{marginRight:'10px'}}>Average: {mathNumbers.avg}</span>
      <span style={{marginRight:'10px'}}>Count: {mathNumbers.count}</span>
      <span style={{marginRight:'10px'}}>Sum: {mathNumbers.total}</span>
      <span style={{marginRight:'10px'}}>Min: {mathNumbers.minNumber}</span>
      <span>Max: {mathNumbers.maxNumber}</span>
      </div>: null}
      <div style={{marginLeft:'10px'}}>Viewing {parseInt(viewingCount).toLocaleString('en')} of {filterCount===0 ? parseInt(recordCount).toLocaleString('en') : parseInt(filterCount).toLocaleString('en')} </div>
      <div style={{width:'20px'}}>
       {isLoading ? <Icon style={{marginLeft:"10px"}} path={mdiLoading} size="20px" spin="10" color="green" /> : ''}
        </div>
      </div>
    </div>
  );
};

export default BottomBar;
