/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Dropdown } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiPlus, mdiListBox } from '@mdi/js';
import GBButton from 'src/components/GBButton/GBButton';

const FieldButton = ({ options, addRemoveField, label = 'Add field', icon, color="#0D99FF",padding="10px" }) => {
  // useEffect(()=>{
  //     console.log(options)
  // },[])

  return (
    <div>
      <Dropdown
        // text={label}
        closeOnChange={false}
        scrolling
        search
        // button
        selectOnBlur={false}
        // iconPosition="left"
        icon={null}
        trigger={
          <GBButton
            width={'maxWidth'}
            iconPosition="left"
            borderRadius="10px"
            fontSize="15px"
            padding={padding}
            iconSize='20px'
            iconColor="white"
            fontWeight='normal'
            text={label}
            Action={null}
            color={color}
            icon={icon }
          />
        }
        options={options}
        value=""
        onChange={(e, data) => addRemoveField(data.value)}
      />
    </div>
  );
};

export default FieldButton;
