/* eslint-disable */
import React, { useEffect } from 'react';
import { Popup } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import Avatar from '../../components/Avatar/Avatar';
import UserCard from './UserCard';

const SelectedUserItem = ({ item, backgroundColor = null, color, Action, fontSize = '13px', uitype }) => {
  const name = item ? `${item.firstname} ${item.lastname}` : ''; // Handle null item

  return (
    <>
      {item ? ( // Check if item is not null
        <Popup
          flowing
          hoverable
          mouseEnterDelay={2000}
          position="top center"
          trigger={
            <div
              // onClick={Action !== null ? () =>Action(item.userid) : null}
              style={{
                width: 'max-content',
                // border: `1px solid ${backgroundColor}`, //2-28-2022 removed aa border didn't always line up
                backgroundColor: backgroundColor,
                color: uitype === 18 ? '#9E9E9E' : 'black',
                justifyContent: 'flex-start',
                marginTop: '2px',
                marginRight: '5px',
                // height: '22px', // 2-28-2022 removed when we removed border and to not flatten circle.
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                paddingRight: '5px',
                borderRadius: '10px',
                whiteSpace: 'noWrap',
                fontSize: fontSize,
              }}
            >
              <Avatar item={item} />
              {name}
              {/* {Action !== null && item.userid ? <Icon path={mdiClose} size="15px" /> : null} */}
            </div>
          }
        >
          <UserCard user={item} />
        </Popup>
      ) : (
        <div></div> // Render empty div if item is null
      )}
    </>
  );
};

export default SelectedUserItem;

// /* eslint-disable */
// import React,{useEffect} from 'react';
// import { Popup } from 'semantic-ui-react';
// import Icon from '@mdi/react';
// import {mdiClose } from '@mdi/js';
// import Avatar from '../../components/Avatar/Avatar';
// import UserCard from './UserCard';

// const SelectedUserItem =({item, backgroundColor=null,color,Action,fontSize="13px",uitype}) => {

//   const name =`${item.firstname} ${item.lastname}`
//     return (
//       <Popup
//       flowing
//       hoverable
//        mouseEnterDelay={2000}
//        position="top center"
//        trigger={
//         <div
//             // onClick={Action !== null ? () =>Action(item.userid) : null}
//             style={{
//             width: 'max-content',
//             // border: `1px solid ${backgroundColor}`, //2-28-2022 removed aa border didn't always line up
//             backgroundColor:backgroundColor,
//             color: uitype===18 ? '#9E9E9E' :'black',
//             justifyContent:'flex-start',
//             marginTop:'2px',
//             marginRight:'5px',
//             // height: '22px', // 2-28-2022 removed when we removed border and to not flatten circle.
//             display: 'flex',
//             alignItems: 'center',
//             overflow:'hidden',
//             paddingRight: '5px',
//             borderRadius: '10px',
//             whiteSpace: 'noWrap',
//             fontSize:fontSize
//           }}
//         >
//           <Avatar item={item} />
//           {name}
//           {/* {Action !== null && item.userid ? <Icon path={mdiClose} size="15px" /> : null} */}
//         </div>}>
//       <UserCard user={item} />
//       </Popup>
//     )
// }

// export default SelectedUserItem