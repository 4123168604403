/* eslint-disable  */
import axios from 'axios';
import firebase, { provider,msprovider } from '../clients/firebase';
import cloudrun from '../clients/httpcloudrun';
import Global from '../clients/global';



export const getUserInfoAPI = () => {
  // console.log(window.location.href);
  let cz =null;
  // if(window.location)
  if(window.location.href.includes('/block/') || window.location.href.includes('/workflow/') ) {
    const parts = window.location.href.split('/');
     cz = parts[parts.length - 1];
  }


  return cloudrun
    .get('/GetUser')
    .then(({ userid, email, firstname, lastname, mobile, mobileverifydate, plans, addedon,usecase, status, attributes, systemattributes, ZoneUsers,currentZone }) => {
      //9-18-23 updated getuser to set currentZone = claims.zoneid, as that is last zone they worked in. We will set that
      Global.zoneid=cz ?? currentZone
      return {
        id: userid,
        email,
        firstName: firstname,
        lastName: lastname,
        phoneNumber: mobile,
        plans,
        addedon,
        mobileverifydate,
        usecase,
        status,
        attributes,
        systemattributes,
        zones: ZoneUsers.map(({ role, pos, Zone: { name, zoneid, token, plan,storage,messaging_funds_purchased,messaging_funds_used,billingIssue,attributes,userlicenses,database } }) => ({
          id: zoneid,
          name,
          token,
          pos,
          role,
          plan,
          storage,
          messaging_funds_purchased,
          messaging_funds_used,
          billingIssue,
          attributes,
          userlicenses,
          database
        })),
      };
    });
};

export const LinkNewProvider = async () => {
  const provider = new firebase.auth.OAuthProvider('microsoft.com');
  try{
    const result = firebase.auth().currentUser.linkWithPopup(provider);
    var credential = result.credential;
    var user = result.user;
    // console.log(credential);
  } catch(error) {
    console.log(error)
  }
}


export const addUserAPI = async ({ email, firstName, lastName, code }) => {
  const payload={}
  // await cloudrun.post('/scheduleMobileReset', {payload}) // added in 5-10-2022, but not implementing yet. Need more thought on this.
  return await cloudrun.post('/addUser', { email, firstname: firstName, lastname: lastName, code });

};

export const updateUserAPI = ({
  email,
  firstName,
  lastName,
  phoneNumber,
  usecase,
  industry,
  plans,
}) => {
  return cloudrun.post('/updateUser', {
    email,
    firstname: firstName,
    lastname: lastName,
    mobile: phoneNumber,
    usecase,
    industry,
    plans,
  });
};

export const addUserFromFirebaseAPI = async (user) => {
  const { displayName } = user;
  // 11-9-222 updated this logic: When adding login with Microsoft, I came across displayName being null,
  // which broke the flow, here if displayname is null, we'll just set lastname=email and keep firstname blank.
  let lastName=''
  let firstName=''

  if(displayName !==null) {
  const displayNameArgs = displayName.split(' ');
    lastName = displayNameArgs.pop();
    firstName = displayNameArgs.join(' ');
  } else {
    lastName=user.email;
  }

  return await addUserAPI({ email: user.email, firstName, lastName, code: user.code });
};

export const signOutAPI = () => {
  return firebase.auth().signOut();
};

export const signInAPI = ({ email, password }) => {
  var resolver;
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(({ user, additionalUserInfo: { isNewUser } }) => {
      return { user, isNewUser };
    }).catch(function(error) {
      if (error.code == 'auth/multi-factor-auth-required') {
        // The user is a multi-factor user. Second factor challenge is required.
        resolver = error.resolver;
        return {resolver};
      } else if (error.code == 'auth/wrong-password') {
        throw error
      } 
    });
};

export const signInGoogleAPI = (code) => {
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then(({ user, additionalUserInfo: { isNewUser } }) => {
      return { user, isNewUser };
    })
    .then(({ user, isNewUser }) => {
      if (isNewUser) {
        if(code !==undefined) {
          user.code = code;
        }
        return addUserFromFirebaseAPI(user).then(() => ({
          user,
          isNewUser,
        }));
      }
      return { user, isNewUser };
    })
    .catch((error) => {
      return signOutAPI().then(() => Promise.reject(error));
    });
};

export const signInMicrosoftAPI = (code) => {
  var resolver;
  return firebase
    .auth()
    .signInWithPopup(msprovider)
    .then(({ user, additionalUserInfo: { isNewUser } }) => {
      return { user, isNewUser };
    })
    .then(({ user, isNewUser }) => {
      if (isNewUser) {
        if(code !==undefined) {
          user.code = code;
        }
        return addUserFromFirebaseAPI(user).then(() => ({
          user,
          isNewUser,
        }));
      }
      return { user, isNewUser };
    })
    .catch((error) => {
      if (error.code == 'auth/multi-factor-auth-required') {
        // The user is a multi-factor user. Second factor challenge is required.
        resolver = error.resolver;
        return {resolver};
      } else {
        console.log(error)
        return signOutAPI().then(() => Promise.reject(error));
      }
    });
};

export const signInInvitedGoogleAPI = (registeremail,trxid,zoneid) => {
    
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then(async ({ user, additionalUserInfo: { isNewUser } }) => {
      let isValidMatch=false;
      if(user.email.toLowerCase()===registeremail.toLowerCase())
      {
        console.log('Email matches')
        isValidMatch=true;
        const { displayName } = user;
        const displayNameArgs = displayName.split(' ');
        const lastName = displayNameArgs.pop();
        const firstName = displayNameArgs.join(' ');

        const payload= {firstname:firstName,lastname:lastName,zoneid,trxid,email:user.email}
        await cloudrun.post('/linkUser',{payload})
      } else if (isNewUser && user.email !== registeremail) {
        console.log("email NOT match")
        isValidMatch=false;
        return addUserFromFirebaseAPI(user).then(() => ({
          user,
          isNewUser,
        }));
      }
      return { user, isNewUser,isValidMatch };
    })
    .catch((error) => {
      return signOutAPI().then(() => Promise.reject(error));
    });
};

export const signInInvitedMicrosoftAPI = (registeremail,trxid,zoneid) => {
    
  return firebase
    .auth()
    .signInWithPopup(msprovider)
    .then(({ user, additionalUserInfo: { isNewUser } }) => {
      let isValidMatch=false;
      if(user.email.toLowerCase()===registeremail.toLowerCase())
      {
        console.log('Email matches')
        isValidMatch=true;
        const { displayName } = user;
        const displayNameArgs = displayName.split(' ');
        const lastName = displayNameArgs.pop();
        const firstName = displayNameArgs.join(' ');

        const payload= {firstname:firstName,lastname:lastName,zoneid,trxid,email:user.email}
        cloudrun.post('/linkUser',{payload})
      } else if (isNewUser && user.email !== registeremail) {
        console.log("email NOT match")
        isValidMatch=false;
        return addUserFromFirebaseAPI(user).then(() => ({
          user,
          isNewUser,
        }));
      }
      return { user, isNewUser,isValidMatch };
    })
    .catch((error) => {
      return signOutAPI().then(() => Promise.reject(error));
    });
};

export const signUpAPI = ({ email, password1, firstName, lastName, code }) => {

  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password1)
    .then(({ user }) => {
      return user
        .updateProfile({
          displayName: `${firstName} ${lastName}`,
        }) 
        .then(() => {
          return addUserAPI({ email, firstName, lastName,emailVerified:false,code });
        })
        .then(() => {
          user.sendEmailVerification()
        })
        .then(() => {
          return signInAPI({ email, password: password1 });
        })
        .catch((error) => {
          return signOutAPI().then(() => Promise.reject(error));
        });
    });
};


export const signUpInvitedUserAPI = async ({ email, password1, firstName, lastName,zoneid,trxid }) => {

  let isValidEmailMatch=true;
  let payload={};
  const { REACT_APP_API_CLOUDRUN_URL } = process.env;

    try {
          const user = await firebase.auth().createUserWithEmailAndPassword(email, password1);       
          await user.user.updateProfile({displayName: `${firstName} ${lastName}`})
        
          const fbuserid = user.user.uid; // 10-9-2020 Adding to pass to validate function. if email matches, we can auto-verify their email address using FireBase Admin SDK
          // Check if email still matches. based on result, either call linkUserAPI or AddUserAPI
          payload={token: trxid,email,zoneid,fbuserid}

          const result = await axios({
            url: `${REACT_APP_API_CLOUDRUN_URL}/validateInviteToken`,
            headers: { 'Content-Type': 'application/json' },
            method: 'post',
            data: {
                payload,
            },
        });

          isValidEmailMatch=result.data.length>0
          
          if(isValidEmailMatch)
          {
             payload= {firstname:firstName,lastname:lastName,zoneid,trxid,email}
             await cloudrun.post('/linkUser',{payload})
          } else {
            await addUserAPI({ email, firstName, lastName,emailVerified:false });
          }
            
          await signInAPI({ email, password: password1 });

         } catch(error) {
            return signOutAPI().then(() => Promise.reject(error));
          }

    return isValidEmailMatch

};

export const SendEmailPasswordReset = async (emailAddress) => {
  try{
    await firebase.auth().sendPasswordResetEmail(emailAddress);
    return "success";
  } catch(error) {
    return error.message
  }
}

export const ConfirmPasswordReset = async (code, newPassword) => {
  try{
    await firebase.auth().confirmPasswordReset(code,newPassword);
    return "success";
  } catch(error) {
    return error.message
  }
}

export const verifyEmailConfirmation = async (code) => {
    try {    
    await firebase.auth().applyActionCode(code)

    // 2-21-2021 removing keeping this in our own system. Instead will rely on firebase to confirm
    // email, as were running into issues calling this with newly registered users.

    // const payload={isVerified:true,dbfunction:'updateEmailVerification',useCentral:true}
    // await cloudrun.post('/executeQueryAPI', {payload}) // update email verified in our system .
    return "success"
    } catch(error) {
      console.log(error.message)
      return error.message
    }
}


export const checkEmailVerified = () => {
  const user = firebase.auth().currentUser;
  user.reload();
  return user.emailVerified
}

export const sendEmailVerification = async () => {
  const user = firebase.auth().currentUser;
  await user.sendEmailVerification();
}

// This method updates both the firebase email and system email
// only available as of 9-18-2020 for unverified email addresses
export const updateEmail = async (currentPassword,userid,email) => {
  
    const user = firebase.auth().currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
    await user.reauthenticateWithCredential(cred);
  
  try{
    await user.updateEmail(email); // update email in firebase.
    await user.sendEmailVerification();
    const items=[{field:'email',value:email}]
    const payload={userid,items,dbfunction:'updateUserInfo',useCentral:true}
    await cloudrun.post('/executeQueryAPI', {payload}) // update email in our system .
    // await cloudrun.post('/scheduleEmailReset', {payload}) //commented out 3-24-2021 (we are having email delivery problems, especially with corp accounts)  Schedule Task to check email in 2 hours. If still not valid, changes firebase email to guid@invalidemail.com
    return "success"
  } catch(error)
  {
    console.log(error.message)
    return error.message
  }
}

// This method is called when a user successfully "adds" a mobile number to their account. We update
// the mobile number and the mobileverifyDate.
export const updateMobileNumber = async (userid,mobileNumber) => {
  const d = new Date();
  const items=[{field:'mobile',value:mobileNumber},{field:'mobileverifydate',value:d.toISOString()}]
  const payload={userid,items,dbfunction:'updateUserInfo',useCentral:true}
  await cloudrun.post('/executeQueryAPI', {payload}) 
  return "success";
}

export const UnlinkMobileNumber = async () => {
  await firebase.auth().currentUser.unlink(firebase.auth.PhoneAuthProvider.PROVIDER_ID);
}

export const ChangePassword = async (currentPassword, newPassword) => {
  try{
    const user = firebase.auth().currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
    await user.reauthenticateWithCredential(cred);

    const res = await user.updatePassword(newPassword); 
    return "success"
  } catch(error)
  {
    if(error.message.includes('The password is invalid'))
    {
      return "The password is invalid"
    } 
      return error.message
  }

}

export const updateAccountStatus = async (userid,status) => {
  const payload={userid,status,dbfunction:'updateAccountStatus',useCentral:true}
  await cloudrun.post('/executeQueryAPI', {payload}) 
  
  return "success";

}

export const VerifyPhoneNumber = async (phoneNumber) => {

  const { REACT_APP_API_CLOUDRUN_URL } = process.env;
  
    const result = await axios({
      url: `${REACT_APP_API_CLOUDRUN_URL}/verifyPhoneNumber`,
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
      data: {
         phoneNumber,
      },
  });
    return result;
}