/* eslint-disable */
import React, { useEffect } from 'react';
import { Popup } from 'semantic-ui-react';
import Avatar from 'src/components/Avatar/Avatar';
import UserCard from 'src/components/UserDropdown/UserCard';

const UserTag = ({ item }) => {
  return (
    <>
      {item && item.length > 0 ? ( // Check if item is not null and has elements
        item.map((el) =>
          el.userid ? ( // Check if el.userid is not null or undefined
            <Popup
              key={el.userid}
              flowing
              hoverable
              mouseEnterDelay={2000}
              position="top center"
              trigger={
                <div
                  style={{
                    width: 'max-content',
                    color: 'black',
                    display: 'inline-flex',
                    flexDirection: 'row',
                    margin: '3px',
                    paddingTop: '2px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    alignItems: 'center',
                    borderRadius: '10px',
                  }}
                >
                  <Avatar item={el} />
                  {el.firstname} {el.lastname}
                </div>
              }
            >
              <UserCard user={el} />
            </Popup>
          ) : null, // Render nothing if el.userid is null or undefined
        )
      ) : (
        <div></div> // Render empty div if item is null, undefined, or empty
      )}
    </>
  );
};

export default UserTag;

// /* eslint-disable */
// import React, { useEffect } from 'react';
// import { Popup } from 'semantic-ui-react';
// import Avatar from 'src/components/Avatar/Avatar';
// import UserCard from 'src/components/UserDropdown/UserCard';

// const UserTag = ({ item }) => {

//   return (
//     item &&
//     item.map(
//       (el) =>
//         el.userid && (
//           <Popup
//             flowing
//             hoverable
//             mouseEnterDelay={2000}
//             position="top center"
//             trigger={<div
//                 key={el.userid}
//                 style={{
//                   width: 'max-content',
//                   color: "black",
//                   display: 'inline-flex',
//                   flexDirection: 'row',
//                   margin: '3px',
//                   paddingTop: '2px',
//                   paddingLeft: '5px',
//                   paddingRight: '5px',
//                   alignItems: 'center',
//                   borderRadius: '10px',
//                 }}
//               >
//                  <Avatar item={el} />
//                 {el.firstname} {el.lastname}
//               </div>}>
//               <UserCard user={el} />
//             </Popup>
//         ),
//     )
//   );
// };

// export default UserTag;
