/* eslint-disable */
import React, { Component } from 'react';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';
import { Input, Dropdown } from 'semantic-ui-react';
import { confirmAlert } from 'react-confirm-alert';
import { mdiEye, mdiEyeOff, mdiClose, mdiDragVertical, mdiPlus } from '@mdi/js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cloudrun from '../../../../clients/httpcloudrun';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';
import GBConfirm from '../../../../components/GBConfirm/GBConfirm';
import ColumnItem from './ColumnItem';
import ColumnConfig from '../ColumnConfig';
import {
  getTableAPI,
  updateUserTableColumnSettings,
  updateUserTabSettings,
  canFieldBeDeleted,
} from '../../../../api/tables';

class ColumnManager extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  state = {
    name: '',
    items: [],
    isLoading: false,
    isChanged: false,
    hasLockedColumns: false,
    sortItems: [],
    groupItems: [],
    calcItems: [],
    modalIsOpen: false,
    selectedColumn: null,
    localTableInfo: this.props.tableinfo,
    searchTerm: '',
    typeFilter: '',
  };

  options = [
    { key: '2', value: 2, text: 'Short text' },
    { key: '3', value: 3, text: 'Long text' },
    { key: '14', value: 14, text: 'Masked' },
    { key: '13', value: 13, text: 'RTF Editor' },
    { key: '7', value: 7, text: 'Single select' },
    { key: '6', value: 6, text: 'Multi select' },
    { key: '4', value: 4, text: 'Attachment' },
    { key: '1', value: 1, text: 'Relational' },
    { key: '18', value: 18, text: 'Lookup' },
    { key: '23', value: 23, text: 'Number' },
    { key: '22', value: 22, text: 'Date' },
    { key: '17', value: 17, text: 'Counter' },
    { key: '9', value: 9, text: 'Phone number' },
    { key: '10', value: 10, text: 'Email' },
    { key: '11', value: 11, text: 'URL' },
    { key: '5', value: 5, text: 'Check box' },
    { key: '8', value: 8, text: 'Collaborator' },
    { key: '16', value: 16, text: 'Formula' },
    { key: '12', value: 12, text: 'Message' },
    { key: '19', value: 19, text: 'Added on' },
    { key: '20', value: 20, text: 'Updated on' },
    { key: '24', value: 24, text: 'Added by' },
    { key: '25', value: 25, text: 'Updated by' },
  ];

  componentDidMount() {
    this.loadColumns();
  }

  getItems = () => {
    const { items, searchTerm, typeFilter } = this.state;
    let copyItems = structuredClone(items);

    if (searchTerm !== '') {
      copyItems = copyItems.filter((itm) => itm.header.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    if (typeFilter !== '') {
      copyItems = copyItems.filter((itm) => itm.uitype === typeFilter);
    }

    return copyItems;
  };

  clearTerm = () => {
    this.setState({ searchTerm: '' });
  };

  updateSearchTerm = (val) => {
    this.setState({ searchTerm: val });
  };

  updateTypeFilter = (val) => {
    this.setState({ typeFilter: val });
  };

  addField = async () => {
    const { tableid } = this.props;
    const payload = { tableid };
    await cloudrun.post('/addDirectField', { payload });
    await this.refreshTableInfo();
  };

  deleteColumn = async (field) => {
    const { tableid, userInfo } = this.props;
    const { localTableInfo, items } = this.state;
    const idx = localTableInfo.columns.findIndex((col) => col.data === field);
    let colInfo = null;
    if (idx !== -1) {
      colInfo = localTableInfo.columns[idx];
    } else {
      return false;
    }

    // They must type delete in confirm delete prompt.
    if (this.input.current.value !== 'delete') {
      return;
    }

    const nestedFields = await canFieldBeDeleted(field, tableid, userInfo.blocks);
    let isWorkFlowField = false;
    if (nestedFields.length > 0) {
      //if the field is used in workflow, show block/workflow
      let fields = '';
      if (nestedFields.findIndex((itm) => itm.type === 'workflow') !== -1) {
        isWorkFlowField = true;
        fields = nestedFields.map((el) => (
          <div>
            {el.blockName} &gt; ({el.header})
          </div>
        ));
      } else {
        fields = nestedFields.map((el) => (
          <div>
            {el.blockName} &gt; {el.name}: ({el.header})
          </div>
        ));
      }

      toast.info(
        <div style={{ marginRight: '10px' }}>
          This field is refererenced in the following{' '}
          {isWorkFlowField ? 'Block > Workflow' : 'Block > tab: (field) locations:'}
          <p />
          <p>{fields}</p>
          <p />
          You must remove these fields prior to removing this field.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: false,
        },
      );
      return false;
    }

    if (colInfo.concat !== undefined) {
      // in this call, we pass the field which is to be removed, so that logic can exlude this
      // field from recalculating new view from existing fields.
      const payload = { tableid: tableid, tableinfo: localTableInfo, field };
      await cloudrun.post('/updateCustomIdView', { payload });

      // 12-2-2021 Show toast to let them know RID has chnaged.
      toast.info(
        <div style={{ margin: '10px' }}>
          🔔Good job building! IMPORTANT NOTE: With this more recent change, you have altered the
          "record identifier" for this tab. As a result, you will want to update how you will refer
          to records in this tab.{' '}
          <a
            style={{ color: 'white', textDecoration: 'underline' }}
            href="https://www.graceblocks.com/support-article/managing-tabs"
            target="_newWindow"
          >
            Please see this help article{' '}
          </a>
          (in particular, review Modifying tabs, step 2B) to learn more.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 30000,
        },
      );
    }

    const payload = { tableid: tableid, fieldname: field };
    const result = await cloudrun.post('/removeField', { payload });

    if (result === 'success') {
      await this.refreshTableInfo();
      //We need to remove the field from Items manually.
      let copyItems = structuredClone(items).filter((itm) => itm.data !== field);
      this.setState({ items: copyItems });
    } else {
      if (result.error === 'nestedField') {
        let fields = result.fields.map((el) => (
          <ul>
            {el.name}: {el.header}
          </ul>
        ));
        const msg = (
          <div style={{ marginRight: '10px' }}>
            This field is used in a relational nested fields. It can not be deleted until you remove
            it from the nested fields in the following relational field(s):
            <p>{fields}</p>
          </div>
        );
        toast.info(
          { msg },
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: false,
          },
        );
      } else {
        toast.info(<div style={{ marginRight: '10px' }}>{result}</div>, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: false,
        });
      }
    }

    console.log(field);
  };

  confirmDeleteField = (field) => {
    const { localTableInfo } = this.state;
    const idx = localTableInfo.columns.findIndex((col) => col.data === field);
    if (idx !== -1) {
      const header = localTableInfo.columns[idx].header;

      const message = (
        <div>
          You have selected to delete the field: {header}.<p />
          Are you sure you want to delete this field? Any data associated with the field will be
          abolished.
        </div>
      );

      confirmAlert({
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <GBConfirm
              title="Delete field"
              action={this.deleteColumn}
              actionValue={field}
              onClose={onClose}
              showCancelButton={true}
              confirmAction="delete"
              confirmWord="delete"
              divRef={this.input}
              buttonLabel="OK"
              message={message}
              showInput={true}
              height="350px"
            />
          );
        },
      });
    }
  };

  loadColumns = () => {
    this.setState({ isLoading: true });
    const { tableinfo, userTabSettings } = this.props;
    const { localTableInfo } = this.state;

    let hasLockedColumnsTemp = false;
    let itemsTemp = [];
    let initialSortItems = [];
    let initialGroupItems = [];
    let initialCalcItems = [];
    let isChangedTemp = false;

    if (userTabSettings.sortMultipleFields !== undefined) {
      initialSortItems = userTabSettings.sortMultipleFields;
    }
    if (userTabSettings.groups !== undefined) {
      initialGroupItems = userTabSettings.groups;
    }
    if (userTabSettings.calcFields !== undefined) {
      initialCalcItems = userTabSettings.calcFields;
    }

    localTableInfo.columns
      .filter((itm) => itm.data !== 'id')
      .map((el, index) => {
        const disableSort =
          (el.source && el.uitype === 18 && el.source.lookup === 'multiple') ||
          (el.lookup === 'multiple' &&
            (['all', 'unique'].includes(el.specialfunction) || !el.specialfunction)) ||
          el.uitype === 12 ||
          el.uitype === 13;

        //This check removes group checkbox if type is message,attachment or rtf.
        const disableGroup =
          (el.source && [12, 4, 13].includes(el.source.lookupuitype)) ||
          el.uitype === 4 ||
          el.uitype === 12 ||
          el.uitype === 13 ||
          el.uitype == 14;

        //mark field if numeric, to be used to show checkbox for fields you can have calculations for in group view.
        const isNumericField =
          ([17, 23].includes(el.uitype) && el.data !== 'id') ||
          (el.uitype === 18 &&
            [17, 23].includes(el.source?.lookupuitype) &&
            (!['all', 'unique'].includes(el.specialfunction) || el.source.lookup === 'single')) ||
          el.fieldType === 'numeric';

        itemsTemp.push({
          header: el.header,
          data: el.data,
          hide: el.hide,
          width: el.width,
          locked: el.locked,
          disableSort,
          disableGroup,
          isNumericField,
          uitype: el.uitype,
          index: index,
        });
        if (el.locked !== undefined) hasLockedColumnsTemp = true;
      });

    //remnove any sort fields that are no longer valid.
    initialSortItems.length > 0 &&
      initialSortItems.map((itm) => {
        const idx = localTableInfo.columns.findIndex((col) => col.data === itm.field);
        if (idx === -1) {
          initialSortItems = initialSortItems.filter((itm2) => itm2.field !== itm.field);
          isChangedTemp = true;
        }
      });

    //5-26-23 Logic to check if field in groups still exist in tableinfo. If not, remove them and
    //set isChanged to true.
    initialGroupItems.length > 0 &&
      initialGroupItems.map((itm) => {
        const idx = localTableInfo.columns.findIndex((col) => col.data === itm);
        if (idx === -1) {
          initialGroupItems = initialGroupItems.filter((itm2) => itm2 !== itm);
          isChangedTemp = true;
        }
      });

    //5-30-23 remove any existing calcfields which are no longer valid in tableinfo.
    if (initialCalcItems.length > 0) {
      initialCalcItems.map((itm) => {
        const idx = localTableInfo.columns.findIndex((col) => col.data === itm);
        if (idx === -1) {
          initialCalcItems = initialCalcItems.filter((itm2) => itm2 !== itm);
          isChangedTemp = true;
        }
      });
    } else {
      //we set all fields checked by default
      initialCalcItems = localTableInfo.columns
        .filter(
          (col) =>
            ([17, 23].includes(col.uitype) && col.data !== 'id') ||
            (col.uitype === 18 &&
              [17, 23].includes(col.source?.lookupuitype) &&
              !['all', 'unique'].includes(col.specialfunction)) ||
            col.fieldType === 'numeric',
        )
        .map((el) => el.data);
    }

    this.setState({
      isChanged: isChangedTemp,
      items: itemsTemp,
      isLoading: false,
      hasLockedColumns: hasLockedColumnsTemp,
      sortItems: initialSortItems,
      groupItems: initialGroupItems,
      calcItems: initialCalcItems,
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(this.state.items, result.source.index, result.destination.index);

    this.setState(
      {
        items,
        isChanged: true,
      },
      //   () => this.reOrderBookMarks(),
    );
  }

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'white' : 'white',
    padding: 10,
    // width: 250,
  });

  //12-4-24 add ability to update all items to hide or show.
  updateAllValues = (mode) => {
    const { items } = this.state;
    const tempItems = [...items];

    tempItems.map((col, index) => {
      col.hide = mode === 'hide' ? true : index < 30 ? false : true;
    });
    this.setState({ items: tempItems, isChanged: true });
  };

  updateColumn = (item) => {
    const { items } = this.state;
    const tempItems = [...items];

    //12-31-24 added logic to not allow them to add more than 30 columns to the view.
    const visibleCount = tempItems.filter((itm) => itm.hide === false || itm.hide === undefined)
      .length;
    if (visibleCount < 30 || item.hide === false) {
      tempItems.filter((el) => el.data === item.data)[0].hide = !item.hide;
      this.setState({ items: tempItems, isChanged: true });
    } else if (visibleCount >= 30 && item.hide === true) {
      toast.info(
        <div style={{ marginRight: '10px' }}>
          You've reached the 30-column display limit which ensures optimal performance. To view
          additional columns, hide some of the currently displayed ones.
          <br />
          <br />
          🔔 Tip: Create{' '}
          <a
            style={{ color: 'white' }}
            href="https://www.graceblocks.com/support-article/using-bookmarks"
            target="newwindow"
          >
            <u>bookmarks</u>
          </a>{' '}
          with different fields to support easy access to critical information. View the{' '}
          <a
            style={{ color: 'white' }}
            href="https://www.graceblocks.com/support-article/details-page"
            target="new window"
          >
            <u>details page</u>
          </a>{' '}
          to access all fields of a specific record.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: false,
        },
      );
    }
  };

  updateSortItems = (sortItem) => {
    const { sortItems } = this.state;
    const { userInfo } = this.props;

    if (userInfo.plan === 'Free') {
      toast.info(
        <div style={{ marginRight: '10px' }}>
          You must upgrade to a paid subscription plan in order to use this feature.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: false,
        },
      );
      return false;
    }

    let sortItemsTemp = sortItems;
    const idx = sortItemsTemp.findIndex((el) => el.field === sortItem.field);
    if (idx === -1 && sortItems.length < 3) {
      sortItemsTemp.push(sortItem);
    } else if (idx !== -1) {
      sortItemsTemp = sortItemsTemp.filter((el) => el.field !== sortItem.field);
    }
    this.setState({ sortItems: sortItemsTemp, isChanged: true });
  };

  updateSortOrder = (sortItem, order) => {
    const { sortItems } = this.state;
    let sortItemsTemp = sortItems;
    const idx = sortItemsTemp.findIndex((el) => el.field === sortItem.field);
    if (idx !== -1) {
      sortItemsTemp[idx].sortOrder = order;
      this.setState({ sortItems: sortItemsTemp, isChanged: true });
    }
  };

  updateGroupItems = (field) => {
    const { groupItems } = this.state;
    const { userInfo } = this.props;

    if (userInfo.plan === 'Free') {
      toast.info(
        <div style={{ marginRight: '10px' }}>
          You must upgrade to a paid subscription plan in order to use this feature.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: false,
        },
      );
      return false;
    }

    let groupItemsTemp = groupItems;
    const idx = groupItemsTemp.findIndex((el) => el === field);
    if (idx === -1 && groupItemsTemp.length < 3) {
      groupItemsTemp.push(field);
    } else if (idx !== -1) {
      groupItemsTemp = groupItemsTemp.filter((el) => el !== field);
    }
    this.setState({ groupItems: groupItemsTemp, isChanged: true });
  };

  //5-30-23 adding to support logic to allow them to select which numeric fields to show
  //calculations for on the group by page.
  updateCalcItems = (field) => {
    const { calcItems } = this.state;
    const { userInfo } = this.props;

    if (userInfo.plan === 'Free') {
      toast.info(
        <div style={{ marginRight: '10px' }}>
          You must upgrade to a paid subscription plan in order to use this feature.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: false,
        },
      );
      return false;
    }

    let calcItemsTemp = calcItems;
    const idx = calcItemsTemp.findIndex((el) => el === field);
    if (idx === -1) {
      calcItemsTemp.push(field);
    } else if (idx !== -1) {
      calcItemsTemp = calcItemsTemp.filter((el) => el !== field);
    }
    this.setState({ calcItems: calcItemsTemp, isChanged: true });
  };

  grid = 8;

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    width: '850px',
    padding: 2,
    // border:'1px solid red',
    marginLeft: '20px',
    // marginRight:'20px',

    background: isDragging ? `${this.props.color}80` : 'white',
    ...draggableStyle,
  });

  updateColumnsAndClose = async () => {
    const { tableid, close, closeModalandRefreshTable, userTabSettings } = this.props;
    const { items, isChanged, sortItems, groupItems, calcItems } = this.state;
    if (isChanged) {
      items.unshift({ data: 'id', width: 25 });
      await updateUserTableColumnSettings(tableid, items);

      if (sortItems.length > 0) {
        userTabSettings.sortMultipleFields = sortItems;
      } else {
        delete userTabSettings.sortMultipleFields;
      }
      if (groupItems.length > 0) {
        userTabSettings.groups = groupItems;
      } else {
        delete userTabSettings.groups;
      }

      if (calcItems.length > 0) {
        userTabSettings.calcFields = calcItems;
      } else {
        delete userTabSettings.calcFields;
      }

      // 11-15-22 save group and sort options
      await updateUserTabSettings(tableid, userTabSettings);

      closeModalandRefreshTable();
    } else {
      close();
    }
  };

  editColumn = (field) => {
    const { localTableInfo } = this.state;
    const idx = localTableInfo.columns.findIndex((col) => col.data === field);
    if (idx !== -1) {
      const updateCol = localTableInfo.columns[idx];
      console.log(updateCol);
      this.setState({ selectedColumn: updateCol, modalIsOpen: true });
    }
  };

  renameColumn = (field, header) => {
    const { items, localTableInfo } = this.state;
    // const tempTableInfo = structuredClone(localTableInfo)
    const idx = items.findIndex((col) => col.data === field);
    const idx2 = localTableInfo.columns.findIndex((col) => col.data === field);
    if (idx !== -1 && idx2 !== -1) {
      items[idx].header = header;
      localTableInfo.columns[idx2].header = header;
      this.setState({ items, localTableInfo, modalIsOpen: false, selectedColumn: null });
    }
  };

  //12-30-24 Called when a column change occurs. Here we want to fetch latest tableinfo and update
  //the localTableInfo
  refreshTableInfo = async () => {
    const { tableid } = this.props;
    const { items, localTableInfo } = this.state;
    const data = await getTableAPI(tableid);

    //we need to update local items with new header/ui type.
    data.tableinfo.columns.map((itm, index) => {
      const idx = items.findIndex((e) => e.data === itm.data);
      if (idx !== -1) {
        items[idx].header = itm.header;
        items[idx].uitype = itm.uitype;
      } else if (itm.data !== 'id') {
        items.push({
          header: itm.header,
          data: itm.data,
          hide: localTableInfo.columns.length - 1 < 30 ? false : true,
          width: itm.width,
          locked: itm.locked,
          disableSort: false,
          disableGroup: false,
          isNumericField: false,
          uitype: itm.uitype,
          index: index,
        });
      }
    });

    //update localtableiknfo, items, and set isChanged to true so grid is refreshed.
    this.setState({
      items,
      localTableInfo: data.tableinfo,
      isChanged: true,
      selectedColumn: null,
      modalIsOpen: false,
    });
  };

  render() {
    const { color, close, tableinfo, tablename, blockid, role, tableid } = this.props;
    const {
      items,
      isLoading,
      hasLockedColumns,
      sortItems,
      groupItems,
      calcItems,
      modalIsOpen,
      localTableInfo,
      selectedColumn,
      searchTerm,
      typeFilter,
    } = this.state;
    // const {tableinfo} = this.s
    return (
      <div>
        <div
          style={{
            display: 'flex',
            padding: '10px',
            flexDirection: 'row',
            height: '40px',
            backgroundColor: color,
            color: 'white',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginLeft: '10px',
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Icon path={mdiEye} size="25px" />
            <div style={{ marginLeft: '5px', fontSize: '15px' }}>Columns </div>
          </div>
          <TextButton
            text="Hide panel when done"
            Action={this.updateColumnsAndClose}
            textColor="white"
            icon={mdiClose}
            hoverColor="#FFFFFF80"
          />
        </div>
        <div
          style={{
            width: modalIsOpen ? '320px' : '100%',
            overflow: 'auto',
            height:
              window.innerHeight > 850
                ? 'calc(100vh - 260px)'
                : window.innerHeight > 700 && window.innerHeight < 900
                ? 'calc(100vh - 230px)'
                : 'calc(100vh - 200px)',
          }}
        >
          <div style={{ margin: '10px', marginLeft: '30px', fontWeight: 'bold' }}>
            Total columns for the tab: {tablename} ({localTableInfo.columns.length - 1}){' '}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: '30px',
              marginRight: '20px',
              marginTop: '10px',
              width: '850px',
            }}
          >
            <div style={{ width: '60px', paddingLeft: '10px', color: '#B3B3B3', fontSize: '10px' }}>
              ORDER
            </div>
            <div style={{ width: '230px', fontWeight: 'bold' }}>
              <Input
                value={searchTerm}
                placeholder="Field name"
                icon={
                  searchTerm === ''
                    ? 'search'
                    : {
                        name: 'close',
                        style: { color: '#E06055' },
                        link: true, // Make it clickable
                        onClick: () => this.clearTerm(),
                      }
                }
                onChange={(e, data) => this.updateSearchTerm(data.value)}
              />
            </div>
            <div style={{ width: '200px', fontWeight: 'bold' }}>
              <Dropdown
                placeholder="Field type"
                value={typeFilter}
                options={this.options}
                search
                selection
                clearable
                onChange={(e, data) => this.updateTypeFilter(data.value)}
              />
            </div>
            <div style={{ width: '180px', fontWeight: 'bold' }}>Group/Summarize*</div>
            {/* <div style={{ width: '50px',fontWeight:'bold' }}>Sort</div> */}
            <div style={{ width: '70px', fontWeight: 'bold' }}>Sort</div>
            <div
              style={{
                width: '80px',
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              <GBButton
                text="Hide all"
                width="80px"
                padding="3px"
                color={color}
                Action={this.updateAllValues}
                ActionValue="hide"
              />
              <div style={{ height: '5px' }} />
              <GBButton
                text={localTableInfo.columns.length - 1 < 30 ? 'Show all' : 'Show 1st 30'}
                width="80px"
                fontSize={localTableInfo.columns.length - 1 < 30 ? '13px' : '10px'}
                padding="3px"
                color={color}
                Action={this.updateAllValues}
                ActionValue="show"
              />
            </div>
            <div style={{ width: '25px', fontWeight: 'bold' }}>Reorder</div>
          </div>

          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={this.getListStyle(snapshot.isDraggingOver)}
                >
                  {items.length > 0 &&
                    !isLoading &&
                    this.getItems().map((item, index) => (
                      <Draggable key={item.data} draggableId={item.data} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                          >
                            <ColumnItem
                              index={item.index}
                              color={color}
                              role={role}
                              item={item}
                              updateColumn={this.updateColumn}
                              sortItems={sortItems}
                              groupItems={groupItems}
                              calcItems={calcItems}
                              updateSortItems={this.updateSortItems}
                              updateGroupItems={this.updateGroupItems}
                              updateSortOrder={this.updateSortOrder}
                              updateCalcItems={this.updateCalcItems}
                              editColumn={this.editColumn}
                              dbname={tableinfo.dbname}
                              isEditMode={selectedColumn !== null}
                              deleteColumn={this.confirmDeleteField}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {role === 3 ? (
            <div style={{ marginLeft: '30px', marginBottom: '10px' }}>
              <GBButton
                width="120px"
                icon={mdiPlus}
                iconColor="white"
                iconHoverColor="#FFFFFF"
                hoverBackgroundColor={`${color}80`}
                iconPosition="left"
                text="Add field"
                color={color}
                Action={this.addField}
              />
            </div>
          ) : null}

          <div style={{ marginLeft: '30px', marginRight: '30px' }}>
            * Group and summarize settings only display when using quickview. Numeric fields will
            have summarize options (sum, avg, min, max, median, range) appear for groupings only if
            the summarize option is checked.
          </div>

          {hasLockedColumns ? (
            <div style={{ marginLeft: '30px', marginBottom: '20px', marginRight: '10px' }}>
              🔒 Fields with the lock icon are not available for you to view due to security access
              level controls. It is not possible to view or filter on information stored in these
              fields.{' '}
              <a
                href="https://graceblocks.zendesk.com/knowledge/articles/4413031923220/en-us?brand_id=360003603191"
                target="_newwindow"
              >
                Learn more here
              </a>
            </div>
          ) : null}
        </div>

        {modalIsOpen && selectedColumn !== null ? (
          <div
            style={{
              position: 'absolute',
              overflow: 'auto',
              zIndex: 0,
              top: 40,
              right: 0,
              backgroundColor: '#FFFFFF', //'#F3FCF7'
              // height:'100%',
              height: 'calc(100vh - 250px)',
              width: '550px',
            }}
          >
            {/* <div onClick={()=>this.setState({modalIsOpen:false})} >Hello world</div> */}
            <ColumnConfig
              Rename={this.renameColumn}
              blockid={blockid}
              Refresh={this.refreshTableInfo}
              updateHeight={() => {
                console.log('update height');
              }}
              header={selectedColumn.header}
              tableid={tableid}
              field={selectedColumn.data}
              duplicateField={null}
              close={() => this.setState({ modalIsOpen: false, selectedColumn: null })}
              colIndex={0}
              color={color}
              ShowError={() => {
                console.log('show error');
              }}
              role={role}
              callingComponent="columnManager"
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColumnManager;
