/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Input, Dropdown, TextArea, Form } from 'semantic-ui-react';
import ReactJson from 'react-json-view';
import { mdiClose } from '@mdi/js';
import IconButton from '../Block/components/FilterData/IconButton';
import GBButton from '../../components/GBButton/GBButton';
import { aiProcessAPI } from '../../api/ai';
import constructPath from './constructJSONPath';
import AudioPlayer from './AudioPlayer';

const ActionAITextToSpeech = ({ localData, updateData, editMode, sourceNodes }) => {
  const [AIProcessName, setAIProcessName] = useState(localData.AIProcessName ?? '');
  const [allFields, setAllFields] = useState([]);
  const [promptMessage, setPromptMessage] = useState(localData.promptMessage ?? '');
  const [APIResponse, setAPIResponse] = useState(localData.apiData ?? null);
  const [tableData, setTableData] = useState(
    localData.tableData ?? {
      name: `api_${localData.id}`,
      id: `api_${localData.id}`,
      tableinfo: { columns: [{data:'publicUrl',header:'Mp3 URL',uitype:2}] },
    },
  );
  const [tokenArray, setTokenArray] = useState(localData.tokenArray ?? []);
  const [languageFilter, setLanguageFilter] = useState('');
  const [genderFilter, setGenderFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [voice, setVoice] = useState(localData.voice ?? 'en-US-Standard-A');
  const [fieldType, setFieldType] =useState(localData.fieldType ?? 4);

  useEffect(() => {
    getAllFields();
  }, []);

  const fieldTypeOptions = [
    {key:'2',value:2,text:'Short text'},
    {key:'4',value:4,text:'Attachment'},
    {key:'11',value:11,text:'URL'},
  ]

  const updateFieldType =(val) =>{
    setFieldType(val);
    tableData.tableinfo.columns[0].uitype=val;
    setTableData(tableData);
    
    localData.fieldType = val;
    localData.tableData = tableData;
    updateData(localData);
  }

  const languageOptions = [
    {
      key: 'Afrikaans (South Africa)  af-ZA-Standard-A  FEMALE',
      value: 'af-ZA-Standard-A',
      text: 'Afrikaans (South Africa)  af-ZA-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Afrikaans (South Africa)',
    },
    {
      key: 'Arabic  ar-XA-Standard-A  FEMALE',
      value: 'ar-XA-Standard-A',
      text: 'Arabic  ar-XA-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Arabic',
    },
    {
      key: 'Arabic  ar-XA-Standard-B  MALE',
      value: 'ar-XA-Standard-B',
      text: 'Arabic  ar-XA-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Arabic',
    },
    {
      key: 'Arabic  ar-XA-Standard-C  MALE',
      value: 'ar-XA-Standard-C',
      text: 'Arabic  ar-XA-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Arabic',
    },
    {
      key: 'Arabic  ar-XA-Standard-D  FEMALE',
      value: 'ar-XA-Standard-D',
      text: 'Arabic  ar-XA-Standard-D  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Arabic',
    },
    {
      key: 'Arabic  ar-XA-Wavenet-A  FEMALE',
      value: 'ar-XA-Wavenet-A',
      text: 'Arabic  ar-XA-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Arabic',
    },
    {
      key: 'Arabic  ar-XA-Wavenet-B  MALE',
      value: 'ar-XA-Wavenet-B',
      text: 'Arabic  ar-XA-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Arabic',
    },
    {
      key: 'Arabic  ar-XA-Wavenet-C  MALE',
      value: 'ar-XA-Wavenet-C',
      text: 'Arabic  ar-XA-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Arabic',
    },
    {
      key: 'Arabic  ar-XA-Wavenet-D  FEMALE',
      value: 'ar-XA-Wavenet-D',
      text: 'Arabic  ar-XA-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Arabic',
    },
    {
      key: 'Basque (Spain)  eu-ES-Standard-A  FEMALE',
      value: 'eu-ES-Standard-A',
      text: 'Basque (Spain)  eu-ES-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Basque (Spain)',
    },
    {
      key: 'Basque (Spain)  eu-ES-Standard-B  FEMALE',
      value: 'eu-ES-Standard-B',
      text: 'Basque (Spain)  eu-ES-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Basque (Spain)',
    },
    {
      key: 'Bengali (India)  bn-IN-Standard-A  FEMALE',
      value: 'bn-IN-Standard-A',
      text: 'Bengali (India)  bn-IN-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Bengali (India)',
    },
    {
      key: 'Bengali (India)  bn-IN-Standard-B  MALE',
      value: 'bn-IN-Standard-B',
      text: 'Bengali (India)  bn-IN-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Bengali (India)',
    },
    {
      key: 'Bengali (India)  bn-IN-Standard-C  FEMALE',
      value: 'bn-IN-Standard-C',
      text: 'Bengali (India)  bn-IN-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Bengali (India)',
    },
    {
      key: 'Bengali (India)  bn-IN-Standard-D  MALE',
      value: 'bn-IN-Standard-D',
      text: 'Bengali (India)  bn-IN-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Bengali (India)',
    },
    {
      key: 'Bengali (India)  bn-IN-Wavenet-A  FEMALE',
      value: 'bn-IN-Wavenet-A',
      text: 'Bengali (India)  bn-IN-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Bengali (India)',
    },
    {
      key: 'Bengali (India)  bn-IN-Wavenet-B  MALE',
      value: 'bn-IN-Wavenet-B',
      text: 'Bengali (India)  bn-IN-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Bengali (India)',
    },
    {
      key: 'Bengali (India)  bn-IN-Wavenet-C  FEMALE',
      value: 'bn-IN-Wavenet-C',
      text: 'Bengali (India)  bn-IN-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Bengali (India)',
    },
    {
      key: 'Bengali (India)  bn-IN-Wavenet-D  MALE',
      value: 'bn-IN-Wavenet-D',
      text: 'Bengali (India)  bn-IN-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Bengali (India)',
    },
    {
      key: 'Bulgarian (Bulgaria)  bg-BG-Standard-A  FEMALE',
      value: 'bg-BG-Standard-A',
      text: 'Bulgarian (Bulgaria)  bg-BG-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Bulgarian (Bulgaria)',
    },
    {
      key: 'Bulgarian (Bulgaria)  bg-BG-Standard-B  FEMALE',
      value: 'bg-BG-Standard-B',
      text: 'Bulgarian (Bulgaria)  bg-BG-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Bulgarian (Bulgaria)',
    },
    {
      key: 'Catalan (Spain)  ca-ES-Standard-A  FEMALE',
      value: 'ca-ES-Standard-A',
      text: 'Catalan (Spain)  ca-ES-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Catalan (Spain)',
    },
    {
      key: 'Catalan (Spain)  ca-ES-Standard-B  FEMALE',
      value: 'ca-ES-Standard-B',
      text: 'Catalan (Spain)  ca-ES-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Catalan (Spain)',
    },
    {
      key: 'Chinese (Hong Kong)  yue-HK-Standard-A  FEMALE',
      value: 'yue-HK-Standard-A',
      text: 'Chinese (Hong Kong)  yue-HK-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Chinese (Hong Kong)',
    },
    {
      key: 'Chinese (Hong Kong)  yue-HK-Standard-B  MALE',
      value: 'yue-HK-Standard-B',
      text: 'Chinese (Hong Kong)  yue-HK-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Chinese (Hong Kong)',
    },
    {
      key: 'Chinese (Hong Kong)  yue-HK-Standard-C  FEMALE',
      value: 'yue-HK-Standard-C',
      text: 'Chinese (Hong Kong)  yue-HK-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Chinese (Hong Kong)',
    },
    {
      key: 'Chinese (Hong Kong)  yue-HK-Standard-D  MALE',
      value: 'yue-HK-Standard-D',
      text: 'Chinese (Hong Kong)  yue-HK-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Chinese (Hong Kong)',
    },
    {
      key: 'Czech (Czech Republic)  cs-CZ-Standard-A  FEMALE',
      value: 'cs-CZ-Standard-A',
      text: 'Czech (Czech Republic)  cs-CZ-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Czech (Czech Republic)',
    },
    {
      key: 'Czech (Czech Republic)  cs-CZ-Standard-B  FEMALE',
      value: 'cs-CZ-Standard-B',
      text: 'Czech (Czech Republic)  cs-CZ-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Czech (Czech Republic)',
    },
    {
      key: 'Czech (Czech Republic)  cs-CZ-Wavenet-A  FEMALE',
      value: 'cs-CZ-Wavenet-A',
      text: 'Czech (Czech Republic)  cs-CZ-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Czech (Czech Republic)',
    },
    {
      key: 'Danish (Denmark)  da-DK-Neural2-D  FEMALE',
      value: 'da-DK-Neural2-D',
      text: 'Danish (Denmark)  da-DK-Neural2-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Danish (Denmark)',
    },
    {
      key: 'Danish (Denmark)  da-DK-Standard-A  FEMALE',
      value: 'da-DK-Standard-A',
      text: 'Danish (Denmark)  da-DK-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Danish (Denmark)',
    },
    {
      key: 'Danish (Denmark)  da-DK-Standard-C  MALE',
      value: 'da-DK-Standard-C',
      text: 'Danish (Denmark)  da-DK-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Danish (Denmark)',
    },
    {
      key: 'Danish (Denmark)  da-DK-Standard-D  FEMALE',
      value: 'da-DK-Standard-D',
      text: 'Danish (Denmark)  da-DK-Standard-D  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Danish (Denmark)',
    },
    {
      key: 'Danish (Denmark)  da-DK-Standard-E  FEMALE',
      value: 'da-DK-Standard-E',
      text: 'Danish (Denmark)  da-DK-Standard-E  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Danish (Denmark)',
    },
    {
      key: 'Danish (Denmark)  da-DK-Standard-F  FEMALE',
      value: 'da-DK-Standard-F',
      text: 'Danish (Denmark)  da-DK-Standard-F  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Danish (Denmark)',
    },
    {
      key: 'Danish (Denmark)  da-DK-Standard-G  MALE',
      value: 'da-DK-Standard-G',
      text: 'Danish (Denmark)  da-DK-Standard-G  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Danish (Denmark)',
    },
    {
      key: 'Danish (Denmark)  da-DK-Wavenet-A  FEMALE',
      value: 'da-DK-Wavenet-A',
      text: 'Danish (Denmark)  da-DK-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Danish (Denmark)',
    },
    {
      key: 'Danish (Denmark)  da-DK-Wavenet-C  MALE',
      value: 'da-DK-Wavenet-C',
      text: 'Danish (Denmark)  da-DK-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Danish (Denmark)',
    },
    {
      key: 'Danish (Denmark)  da-DK-Wavenet-D  FEMALE',
      value: 'da-DK-Wavenet-D',
      text: 'Danish (Denmark)  da-DK-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Danish (Denmark)',
    },
    {
      key: 'Danish (Denmark)  da-DK-Wavenet-E  FEMALE',
      value: 'da-DK-Wavenet-E',
      text: 'Danish (Denmark)  da-DK-Wavenet-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Danish (Denmark)',
    },
    {
      key: 'Dutch (Belgium)  nl-BE-Standard-A  FEMALE',
      value: 'nl-BE-Standard-A',
      text: 'Dutch (Belgium)  nl-BE-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Dutch (Belgium)',
    },
    {
      key: 'Dutch (Belgium)  nl-BE-Standard-B  MALE',
      value: 'nl-BE-Standard-B',
      text: 'Dutch (Belgium)  nl-BE-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Dutch (Belgium)',
    },
    {
      key: 'Dutch (Belgium)  nl-BE-Standard-C  FEMALE',
      value: 'nl-BE-Standard-C',
      text: 'Dutch (Belgium)  nl-BE-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Dutch (Belgium)',
    },
    {
      key: 'Dutch (Belgium)  nl-BE-Standard-D  MALE',
      value: 'nl-BE-Standard-D',
      text: 'Dutch (Belgium)  nl-BE-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Dutch (Belgium)',
    },
    {
      key: 'Dutch (Belgium)  nl-BE-Wavenet-A  FEMALE',
      value: 'nl-BE-Wavenet-A',
      text: 'Dutch (Belgium)  nl-BE-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Dutch (Belgium)',
    },
    {
      key: 'Dutch (Belgium)  nl-BE-Wavenet-B  MALE',
      value: 'nl-BE-Wavenet-B',
      text: 'Dutch (Belgium)  nl-BE-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Dutch (Belgium)',
    },
    {
      key: 'Dutch (Netherlands)  nl-NL-Standard-A  FEMALE',
      value: 'nl-NL-Standard-A',
      text: 'Dutch (Netherlands)  nl-NL-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Dutch (Netherlands)',
    },
    {
      key: 'Dutch (Netherlands)  nl-NL-Standard-B  MALE',
      value: 'nl-NL-Standard-B',
      text: 'Dutch (Netherlands)  nl-NL-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Dutch (Netherlands)',
    },
    {
      key: 'Dutch (Netherlands)  nl-NL-Standard-C  MALE',
      value: 'nl-NL-Standard-C',
      text: 'Dutch (Netherlands)  nl-NL-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Dutch (Netherlands)',
    },
    {
      key: 'Dutch (Netherlands)  nl-NL-Standard-D  FEMALE',
      value: 'nl-NL-Standard-D',
      text: 'Dutch (Netherlands)  nl-NL-Standard-D  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Dutch (Netherlands)',
    },
    {
      key: 'Dutch (Netherlands)  nl-NL-Standard-E  FEMALE',
      value: 'nl-NL-Standard-E',
      text: 'Dutch (Netherlands)  nl-NL-Standard-E  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Dutch (Netherlands)',
    },
    {
      key: 'Dutch (Netherlands)  nl-NL-Standard-F  FEMALE',
      value: 'nl-NL-Standard-F',
      text: 'Dutch (Netherlands)  nl-NL-Standard-F  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Dutch (Netherlands)',
    },
    {
      key: 'Dutch (Netherlands)  nl-NL-Standard-G  MALE',
      value: 'nl-NL-Standard-G',
      text: 'Dutch (Netherlands)  nl-NL-Standard-G  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Dutch (Netherlands)',
    },
    {
      key: 'Dutch (Netherlands)  nl-NL-Wavenet-A  FEMALE',
      value: 'nl-NL-Wavenet-A',
      text: 'Dutch (Netherlands)  nl-NL-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Dutch (Netherlands)',
    },
    {
      key: 'Dutch (Netherlands)  nl-NL-Wavenet-B  MALE',
      value: 'nl-NL-Wavenet-B',
      text: 'Dutch (Netherlands)  nl-NL-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Dutch (Netherlands)',
    },
    {
      key: 'Dutch (Netherlands)  nl-NL-Wavenet-C  MALE',
      value: 'nl-NL-Wavenet-C',
      text: 'Dutch (Netherlands)  nl-NL-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Dutch (Netherlands)',
    },
    {
      key: 'Dutch (Netherlands)  nl-NL-Wavenet-D  FEMALE',
      value: 'nl-NL-Wavenet-D',
      text: 'Dutch (Netherlands)  nl-NL-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Dutch (Netherlands)',
    },
    {
      key: 'Dutch (Netherlands)  nl-NL-Wavenet-E  FEMALE',
      value: 'nl-NL-Wavenet-E',
      text: 'Dutch (Netherlands)  nl-NL-Wavenet-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Dutch (Netherlands)',
    },
    {
      key: 'English (Australia)  en-AU-Journey-D  MALE',
      value: 'en-AU-Journey-D',
      text: 'English (Australia)  en-AU-Journey-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Journey-F  FEMALE',
      value: 'en-AU-Journey-F',
      text: 'English (Australia)  en-AU-Journey-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Journey-O  FEMALE',
      value: 'en-AU-Journey-O',
      text: 'English (Australia)  en-AU-Journey-O  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Neural2-A  FEMALE',
      value: 'en-AU-Neural2-A',
      text: 'English (Australia)  en-AU-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Neural2-B  MALE',
      value: 'en-AU-Neural2-B',
      text: 'English (Australia)  en-AU-Neural2-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Neural2-C  FEMALE',
      value: 'en-AU-Neural2-C',
      text: 'English (Australia)  en-AU-Neural2-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Neural2-D  MALE',
      value: 'en-AU-Neural2-D',
      text: 'English (Australia)  en-AU-Neural2-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-News-E  FEMALE',
      value: 'en-AU-News-E',
      text: 'English (Australia)  en-AU-News-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-News-F  FEMALE',
      value: 'en-AU-News-F',
      text: 'English (Australia)  en-AU-News-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-News-G  MALE',
      value: 'en-AU-News-G',
      text: 'English (Australia)  en-AU-News-G  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Polyglot-1  MALE',
      value: 'en-AU-Polyglot-1',
      text: 'English (Australia)  en-AU-Polyglot-1  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Standard-A  FEMALE',
      value: 'en-AU-Standard-A',
      text: 'English (Australia)  en-AU-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Standard-B  MALE',
      value: 'en-AU-Standard-B',
      text: 'English (Australia)  en-AU-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Standard-C  FEMALE',
      value: 'en-AU-Standard-C',
      text: 'English (Australia)  en-AU-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Standard-D  MALE',
      value: 'en-AU-Standard-D',
      text: 'English (Australia)  en-AU-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Wavenet-A  FEMALE',
      value: 'en-AU-Wavenet-A',
      text: 'English (Australia)  en-AU-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Wavenet-B  MALE',
      value: 'en-AU-Wavenet-B',
      text: 'English (Australia)  en-AU-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Wavenet-C  FEMALE',
      value: 'en-AU-Wavenet-C',
      text: 'English (Australia)  en-AU-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (Australia)  en-AU-Wavenet-D  MALE',
      value: 'en-AU-Wavenet-D',
      text: 'English (Australia)  en-AU-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (Australia)',
    },
    {
      key: 'English (India)  en-IN-Journey-D  MALE',
      value: 'en-IN-Journey-D',
      text: 'English (India)  en-IN-Journey-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Journey-F  FEMALE',
      value: 'en-IN-Journey-F',
      text: 'English (India)  en-IN-Journey-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Journey-O  FEMALE',
      value: 'en-IN-Journey-O',
      text: 'English (India)  en-IN-Journey-O  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Neural2-A  FEMALE',
      value: 'en-IN-Neural2-A',
      text: 'English (India)  en-IN-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Neural2-B  MALE',
      value: 'en-IN-Neural2-B',
      text: 'English (India)  en-IN-Neural2-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Neural2-C  MALE',
      value: 'en-IN-Neural2-C',
      text: 'English (India)  en-IN-Neural2-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Neural2-D  FEMALE',
      value: 'en-IN-Neural2-D',
      text: 'English (India)  en-IN-Neural2-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Standard-A  FEMALE',
      value: 'en-IN-Standard-A',
      text: 'English (India)  en-IN-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Standard-B  MALE',
      value: 'en-IN-Standard-B',
      text: 'English (India)  en-IN-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Standard-C  MALE',
      value: 'en-IN-Standard-C',
      text: 'English (India)  en-IN-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Standard-D  FEMALE',
      value: 'en-IN-Standard-D',
      text: 'English (India)  en-IN-Standard-D  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Standard-E  FEMALE',
      value: 'en-IN-Standard-E',
      text: 'English (India)  en-IN-Standard-E  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Standard-F  MALE',
      value: 'en-IN-Standard-F',
      text: 'English (India)  en-IN-Standard-F  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Wavenet-A  FEMALE',
      value: 'en-IN-Wavenet-A',
      text: 'English (India)  en-IN-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Wavenet-B  MALE',
      value: 'en-IN-Wavenet-B',
      text: 'English (India)  en-IN-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Wavenet-C  MALE',
      value: 'en-IN-Wavenet-C',
      text: 'English (India)  en-IN-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Wavenet-D  FEMALE',
      value: 'en-IN-Wavenet-D',
      text: 'English (India)  en-IN-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Wavenet-E  FEMALE',
      value: 'en-IN-Wavenet-E',
      text: 'English (India)  en-IN-Wavenet-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (India)',
    },
    {
      key: 'English (India)  en-IN-Wavenet-F  MALE',
      value: 'en-IN-Wavenet-F',
      text: 'English (India)  en-IN-Wavenet-F  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (India)',
    },
    {
      key: 'English (UK)  en-GB-Journey-D  MALE',
      value: 'en-GB-Journey-D',
      text: 'English (UK)  en-GB-Journey-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Journey-F  FEMALE',
      value: 'en-GB-Journey-F',
      text: 'English (UK)  en-GB-Journey-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Journey-O  FEMALE',
      value: 'en-GB-Journey-O',
      text: 'English (UK)  en-GB-Journey-O  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Neural2-A  FEMALE',
      value: 'en-GB-Neural2-A',
      text: 'English (UK)  en-GB-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Neural2-B  MALE',
      value: 'en-GB-Neural2-B',
      text: 'English (UK)  en-GB-Neural2-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Neural2-C  FEMALE',
      value: 'en-GB-Neural2-C',
      text: 'English (UK)  en-GB-Neural2-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Neural2-D  MALE',
      value: 'en-GB-Neural2-D',
      text: 'English (UK)  en-GB-Neural2-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Neural2-F  FEMALE',
      value: 'en-GB-Neural2-F',
      text: 'English (UK)  en-GB-Neural2-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-News-G  FEMALE',
      value: 'en-GB-News-G',
      text: 'English (UK)  en-GB-News-G  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-News-H  FEMALE',
      value: 'en-GB-News-H',
      text: 'English (UK)  en-GB-News-H  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-News-I  FEMALE',
      value: 'en-GB-News-I',
      text: 'English (UK)  en-GB-News-I  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-News-J  MALE',
      value: 'en-GB-News-J',
      text: 'English (UK)  en-GB-News-J  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-News-K  MALE',
      value: 'en-GB-News-K',
      text: 'English (UK)  en-GB-News-K  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-News-L  MALE',
      value: 'en-GB-News-L',
      text: 'English (UK)  en-GB-News-L  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-News-M  MALE',
      value: 'en-GB-News-M',
      text: 'English (UK)  en-GB-News-M  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Standard-A  FEMALE',
      value: 'en-GB-Standard-A',
      text: 'English (UK)  en-GB-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Standard-B  MALE',
      value: 'en-GB-Standard-B',
      text: 'English (UK)  en-GB-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Standard-C  FEMALE',
      value: 'en-GB-Standard-C',
      text: 'English (UK)  en-GB-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Standard-D  MALE',
      value: 'en-GB-Standard-D',
      text: 'English (UK)  en-GB-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Standard-F  FEMALE',
      value: 'en-GB-Standard-F',
      text: 'English (UK)  en-GB-Standard-F  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Standard-N  FEMALE',
      value: 'en-GB-Standard-N',
      text: 'English (UK)  en-GB-Standard-N  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Standard-O  MALE',
      value: 'en-GB-Standard-O',
      text: 'English (UK)  en-GB-Standard-O  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Studio-B  MALE',
      value: 'en-GB-Studio-B',
      text: 'English (UK)  en-GB-Studio-B  MALE',
      type: 'Studio',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Studio-C  FEMALE',
      value: 'en-GB-Studio-C',
      text: 'English (UK)  en-GB-Studio-C  FEMALE',
      type: 'Studio',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Wavenet-A  FEMALE',
      value: 'en-GB-Wavenet-A',
      text: 'English (UK)  en-GB-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Wavenet-B  MALE',
      value: 'en-GB-Wavenet-B',
      text: 'English (UK)  en-GB-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Wavenet-C  FEMALE',
      value: 'en-GB-Wavenet-C',
      text: 'English (UK)  en-GB-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Wavenet-D  MALE',
      value: 'en-GB-Wavenet-D',
      text: 'English (UK)  en-GB-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (UK)',
    },
    {
      key: 'English (UK)  en-GB-Wavenet-F  FEMALE',
      value: 'en-GB-Wavenet-F',
      text: 'English (UK)  en-GB-Wavenet-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (UK)',
    },
    {
      key: 'English (US)  en-US-Casual-K  MALE',
      value: 'en-US-Casual-K',
      text: 'English (US)  en-US-Casual-K  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Journey-D  MALE',
      value: 'en-US-Journey-D',
      text: 'English (US)  en-US-Journey-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Journey-F  FEMALE',
      value: 'en-US-Journey-F',
      text: 'English (US)  en-US-Journey-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Journey-O  FEMALE',
      value: 'en-US-Journey-O',
      text: 'English (US)  en-US-Journey-O  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Neural2-A  MALE',
      value: 'en-US-Neural2-A',
      text: 'English (US)  en-US-Neural2-A  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Neural2-C  FEMALE',
      value: 'en-US-Neural2-C',
      text: 'English (US)  en-US-Neural2-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Neural2-D  MALE',
      value: 'en-US-Neural2-D',
      text: 'English (US)  en-US-Neural2-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Neural2-E  FEMALE',
      value: 'en-US-Neural2-E',
      text: 'English (US)  en-US-Neural2-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Neural2-F  FEMALE',
      value: 'en-US-Neural2-F',
      text: 'English (US)  en-US-Neural2-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Neural2-G  FEMALE',
      value: 'en-US-Neural2-G',
      text: 'English (US)  en-US-Neural2-G  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Neural2-H  FEMALE',
      value: 'en-US-Neural2-H',
      text: 'English (US)  en-US-Neural2-H  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Neural2-I  MALE',
      value: 'en-US-Neural2-I',
      text: 'English (US)  en-US-Neural2-I  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Neural2-J  MALE',
      value: 'en-US-Neural2-J',
      text: 'English (US)  en-US-Neural2-J  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-News-K  FEMALE',
      value: 'en-US-News-K',
      text: 'English (US)  en-US-News-K  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-News-L  FEMALE',
      value: 'en-US-News-L',
      text: 'English (US)  en-US-News-L  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-News-N  MALE',
      value: 'en-US-News-N',
      text: 'English (US)  en-US-News-N  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Polyglot-1  MALE',
      value: 'en-US-Polyglot-1',
      text: 'English (US)  en-US-Polyglot-1  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Standard-A  MALE',
      value: 'en-US-Standard-A',
      text: 'English (US)  en-US-Standard-A  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Standard-B  MALE',
      value: 'en-US-Standard-B',
      text: 'English (US)  en-US-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Standard-C  FEMALE',
      value: 'en-US-Standard-C',
      text: 'English (US)  en-US-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Standard-D  MALE',
      value: 'en-US-Standard-D',
      text: 'English (US)  en-US-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Standard-E  FEMALE',
      value: 'en-US-Standard-E',
      text: 'English (US)  en-US-Standard-E  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Standard-F  FEMALE',
      value: 'en-US-Standard-F',
      text: 'English (US)  en-US-Standard-F  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Standard-G  FEMALE',
      value: 'en-US-Standard-G',
      text: 'English (US)  en-US-Standard-G  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Standard-H  FEMALE',
      value: 'en-US-Standard-H',
      text: 'English (US)  en-US-Standard-H  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Standard-I  MALE',
      value: 'en-US-Standard-I',
      text: 'English (US)  en-US-Standard-I  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Standard-J  MALE',
      value: 'en-US-Standard-J',
      text: 'English (US)  en-US-Standard-J  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Studio-O  FEMALE',
      value: 'en-US-Studio-O',
      text: 'English (US)  en-US-Studio-O  FEMALE',
      type: 'Studio',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Studio-Q  MALE',
      value: 'en-US-Studio-Q',
      text: 'English (US)  en-US-Studio-Q  MALE',
      type: 'Studio',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Wavenet-A  MALE',
      value: 'en-US-Wavenet-A',
      text: 'English (US)  en-US-Wavenet-A  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Wavenet-B  MALE',
      value: 'en-US-Wavenet-B',
      text: 'English (US)  en-US-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Wavenet-C  FEMALE',
      value: 'en-US-Wavenet-C',
      text: 'English (US)  en-US-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Wavenet-D  MALE',
      value: 'en-US-Wavenet-D',
      text: 'English (US)  en-US-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Wavenet-E  FEMALE',
      value: 'en-US-Wavenet-E',
      text: 'English (US)  en-US-Wavenet-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Wavenet-F  FEMALE',
      value: 'en-US-Wavenet-F',
      text: 'English (US)  en-US-Wavenet-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Wavenet-G  FEMALE',
      value: 'en-US-Wavenet-G',
      text: 'English (US)  en-US-Wavenet-G  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Wavenet-H  FEMALE',
      value: 'en-US-Wavenet-H',
      text: 'English (US)  en-US-Wavenet-H  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Wavenet-I  MALE',
      value: 'en-US-Wavenet-I',
      text: 'English (US)  en-US-Wavenet-I  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'English (US)  en-US-Wavenet-J  MALE',
      value: 'en-US-Wavenet-J',
      text: 'English (US)  en-US-Wavenet-J  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'English (US)',
    },
    {
      key: 'Filipino (Philippines)  fil-PH-Standard-A  FEMALE',
      value: 'fil-PH-Standard-A',
      text: 'Filipino (Philippines)  fil-PH-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Filipino (Philippines)',
    },
    {
      key: 'Filipino (Philippines)  fil-PH-Standard-B  FEMALE',
      value: 'fil-PH-Standard-B',
      text: 'Filipino (Philippines)  fil-PH-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Filipino (Philippines)',
    },
    {
      key: 'Filipino (Philippines)  fil-PH-Standard-C  MALE',
      value: 'fil-PH-Standard-C',
      text: 'Filipino (Philippines)  fil-PH-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Filipino (Philippines)',
    },
    {
      key: 'Filipino (Philippines)  fil-PH-Standard-D  MALE',
      value: 'fil-PH-Standard-D',
      text: 'Filipino (Philippines)  fil-PH-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Filipino (Philippines)',
    },
    {
      key: 'Filipino (Philippines)  fil-PH-Wavenet-A  FEMALE',
      value: 'fil-PH-Wavenet-A',
      text: 'Filipino (Philippines)  fil-PH-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Filipino (Philippines)',
    },
    {
      key: 'Filipino (Philippines)  fil-PH-Wavenet-B  FEMALE',
      value: 'fil-PH-Wavenet-B',
      text: 'Filipino (Philippines)  fil-PH-Wavenet-B  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Filipino (Philippines)',
    },
    {
      key: 'Filipino (Philippines)  fil-PH-Wavenet-C  MALE',
      value: 'fil-PH-Wavenet-C',
      text: 'Filipino (Philippines)  fil-PH-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Filipino (Philippines)',
    },
    {
      key: 'Filipino (Philippines)  fil-PH-Wavenet-D  MALE',
      value: 'fil-PH-Wavenet-D',
      text: 'Filipino (Philippines)  fil-PH-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Filipino (Philippines)',
    },
    {
      key: 'Filipino (Philippines)  fil-ph-Neural2-A  FEMALE',
      value: 'fil-ph-Neural2-A',
      text: 'Filipino (Philippines)  fil-ph-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Filipino (Philippines)',
    },
    {
      key: 'Filipino (Philippines)  fil-ph-Neural2-D  MALE',
      value: 'fil-ph-Neural2-D',
      text: 'Filipino (Philippines)  fil-ph-Neural2-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Filipino (Philippines)',
    },
    {
      key: 'Finnish (Finland)  fi-FI-Standard-A  FEMALE',
      value: 'fi-FI-Standard-A',
      text: 'Finnish (Finland)  fi-FI-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Finnish (Finland)',
    },
    {
      key: 'Finnish (Finland)  fi-FI-Standard-B  FEMALE',
      value: 'fi-FI-Standard-B',
      text: 'Finnish (Finland)  fi-FI-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Finnish (Finland)',
    },
    {
      key: 'Finnish (Finland)  fi-FI-Wavenet-A  FEMALE',
      value: 'fi-FI-Wavenet-A',
      text: 'Finnish (Finland)  fi-FI-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Finnish (Finland)',
    },
    {
      key: 'French (Canada)  fr-CA-Journey-D  MALE',
      value: 'fr-CA-Journey-D',
      text: 'French (Canada)  fr-CA-Journey-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Journey-F  FEMALE',
      value: 'fr-CA-Journey-F',
      text: 'French (Canada)  fr-CA-Journey-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Journey-O  FEMALE',
      value: 'fr-CA-Journey-O',
      text: 'French (Canada)  fr-CA-Journey-O  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Neural2-A  FEMALE',
      value: 'fr-CA-Neural2-A',
      text: 'French (Canada)  fr-CA-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Neural2-B  MALE',
      value: 'fr-CA-Neural2-B',
      text: 'French (Canada)  fr-CA-Neural2-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Neural2-C  FEMALE',
      value: 'fr-CA-Neural2-C',
      text: 'French (Canada)  fr-CA-Neural2-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Neural2-D  MALE',
      value: 'fr-CA-Neural2-D',
      text: 'French (Canada)  fr-CA-Neural2-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Standard-A  FEMALE',
      value: 'fr-CA-Standard-A',
      text: 'French (Canada)  fr-CA-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Standard-B  MALE',
      value: 'fr-CA-Standard-B',
      text: 'French (Canada)  fr-CA-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Standard-C  FEMALE',
      value: 'fr-CA-Standard-C',
      text: 'French (Canada)  fr-CA-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Standard-D  MALE',
      value: 'fr-CA-Standard-D',
      text: 'French (Canada)  fr-CA-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Wavenet-A  FEMALE',
      value: 'fr-CA-Wavenet-A',
      text: 'French (Canada)  fr-CA-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Wavenet-B  MALE',
      value: 'fr-CA-Wavenet-B',
      text: 'French (Canada)  fr-CA-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Wavenet-C  FEMALE',
      value: 'fr-CA-Wavenet-C',
      text: 'French (Canada)  fr-CA-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (Canada)  fr-CA-Wavenet-D  MALE',
      value: 'fr-CA-Wavenet-D',
      text: 'French (Canada)  fr-CA-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'French (Canada)',
    },
    {
      key: 'French (France)  fr-FR-Journey-D  MALE',
      value: 'fr-FR-Journey-D',
      text: 'French (France)  fr-FR-Journey-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Journey-F  FEMALE',
      value: 'fr-FR-Journey-F',
      text: 'French (France)  fr-FR-Journey-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Journey-O  FEMALE',
      value: 'fr-FR-Journey-O',
      text: 'French (France)  fr-FR-Journey-O  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Neural2-A  FEMALE',
      value: 'fr-FR-Neural2-A',
      text: 'French (France)  fr-FR-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Neural2-B  MALE',
      value: 'fr-FR-Neural2-B',
      text: 'French (France)  fr-FR-Neural2-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Neural2-C  FEMALE',
      value: 'fr-FR-Neural2-C',
      text: 'French (France)  fr-FR-Neural2-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Neural2-D  MALE',
      value: 'fr-FR-Neural2-D',
      text: 'French (France)  fr-FR-Neural2-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Neural2-E  FEMALE',
      value: 'fr-FR-Neural2-E',
      text: 'French (France)  fr-FR-Neural2-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Polyglot-1  MALE',
      value: 'fr-FR-Polyglot-1',
      text: 'French (France)  fr-FR-Polyglot-1  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Standard-A  FEMALE',
      value: 'fr-FR-Standard-A',
      text: 'French (France)  fr-FR-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Standard-B  MALE',
      value: 'fr-FR-Standard-B',
      text: 'French (France)  fr-FR-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Standard-C  FEMALE',
      value: 'fr-FR-Standard-C',
      text: 'French (France)  fr-FR-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Standard-D  MALE',
      value: 'fr-FR-Standard-D',
      text: 'French (France)  fr-FR-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Standard-E  FEMALE',
      value: 'fr-FR-Standard-E',
      text: 'French (France)  fr-FR-Standard-E  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Standard-F  FEMALE',
      value: 'fr-FR-Standard-F',
      text: 'French (France)  fr-FR-Standard-F  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Standard-G  MALE',
      value: 'fr-FR-Standard-G',
      text: 'French (France)  fr-FR-Standard-G  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Studio-A  FEMALE',
      value: 'fr-FR-Studio-A',
      text: 'French (France)  fr-FR-Studio-A  FEMALE',
      type: 'Studio',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Studio-D  MALE',
      value: 'fr-FR-Studio-D',
      text: 'French (France)  fr-FR-Studio-D  MALE',
      type: 'Studio',
      gender: 'MALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Wavenet-A  FEMALE',
      value: 'fr-FR-Wavenet-A',
      text: 'French (France)  fr-FR-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Wavenet-B  MALE',
      value: 'fr-FR-Wavenet-B',
      text: 'French (France)  fr-FR-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Wavenet-C  FEMALE',
      value: 'fr-FR-Wavenet-C',
      text: 'French (France)  fr-FR-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Wavenet-D  MALE',
      value: 'fr-FR-Wavenet-D',
      text: 'French (France)  fr-FR-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Wavenet-E  FEMALE',
      value: 'fr-FR-Wavenet-E',
      text: 'French (France)  fr-FR-Wavenet-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Wavenet-F  FEMALE',
      value: 'fr-FR-Wavenet-F',
      text: 'French (France)  fr-FR-Wavenet-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'French (France)',
    },
    {
      key: 'French (France)  fr-FR-Wavenet-G  MALE',
      value: 'fr-FR-Wavenet-G',
      text: 'French (France)  fr-FR-Wavenet-G  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'French (France)',
    },
    {
      key: 'Galician (Spain)  gl-ES-Standard-A  FEMALE',
      value: 'gl-ES-Standard-A',
      text: 'Galician (Spain)  gl-ES-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Galician (Spain)',
    },
    {
      key: 'Galician (Spain)  gl-ES-Standard-B  FEMALE',
      value: 'gl-ES-Standard-B',
      text: 'Galician (Spain)  gl-ES-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Galician (Spain)',
    },
    {
      key: 'German (Germany)  de-DE-Journey-D  MALE',
      value: 'de-DE-Journey-D',
      text: 'German (Germany)  de-DE-Journey-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Journey-F  FEMALE',
      value: 'de-DE-Journey-F',
      text: 'German (Germany)  de-DE-Journey-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Journey-O  FEMALE',
      value: 'de-DE-Journey-O',
      text: 'German (Germany)  de-DE-Journey-O  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Neural2-A  FEMALE',
      value: 'de-DE-Neural2-A',
      text: 'German (Germany)  de-DE-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Neural2-B  MALE',
      value: 'de-DE-Neural2-B',
      text: 'German (Germany)  de-DE-Neural2-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Neural2-C  FEMALE',
      value: 'de-DE-Neural2-C',
      text: 'German (Germany)  de-DE-Neural2-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Neural2-D  MALE',
      value: 'de-DE-Neural2-D',
      text: 'German (Germany)  de-DE-Neural2-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Neural2-F  FEMALE',
      value: 'de-DE-Neural2-F',
      text: 'German (Germany)  de-DE-Neural2-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Polyglot-1  MALE',
      value: 'de-DE-Polyglot-1',
      text: 'German (Germany)  de-DE-Polyglot-1  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Standard-A  FEMALE',
      value: 'de-DE-Standard-A',
      text: 'German (Germany)  de-DE-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Standard-B  MALE',
      value: 'de-DE-Standard-B',
      text: 'German (Germany)  de-DE-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Standard-C  FEMALE',
      value: 'de-DE-Standard-C',
      text: 'German (Germany)  de-DE-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Standard-D  MALE',
      value: 'de-DE-Standard-D',
      text: 'German (Germany)  de-DE-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Standard-E  MALE',
      value: 'de-DE-Standard-E',
      text: 'German (Germany)  de-DE-Standard-E  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Standard-F  FEMALE',
      value: 'de-DE-Standard-F',
      text: 'German (Germany)  de-DE-Standard-F  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Standard-G  FEMALE',
      value: 'de-DE-Standard-G',
      text: 'German (Germany)  de-DE-Standard-G  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Standard-H  MALE',
      value: 'de-DE-Standard-H',
      text: 'German (Germany)  de-DE-Standard-H  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Studio-B  MALE',
      value: 'de-DE-Studio-B',
      text: 'German (Germany)  de-DE-Studio-B  MALE',
      type: 'Studio',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Studio-C  FEMALE',
      value: 'de-DE-Studio-C',
      text: 'German (Germany)  de-DE-Studio-C  FEMALE',
      type: 'Studio',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Wavenet-A  FEMALE',
      value: 'de-DE-Wavenet-A',
      text: 'German (Germany)  de-DE-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Wavenet-B  MALE',
      value: 'de-DE-Wavenet-B',
      text: 'German (Germany)  de-DE-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Wavenet-C  FEMALE',
      value: 'de-DE-Wavenet-C',
      text: 'German (Germany)  de-DE-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Wavenet-D  MALE',
      value: 'de-DE-Wavenet-D',
      text: 'German (Germany)  de-DE-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Wavenet-E  MALE',
      value: 'de-DE-Wavenet-E',
      text: 'German (Germany)  de-DE-Wavenet-E  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Wavenet-F  FEMALE',
      value: 'de-DE-Wavenet-F',
      text: 'German (Germany)  de-DE-Wavenet-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Wavenet-G  FEMALE',
      value: 'de-DE-Wavenet-G',
      text: 'German (Germany)  de-DE-Wavenet-G  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'German (Germany)',
    },
    {
      key: 'German (Germany)  de-DE-Wavenet-H  MALE',
      value: 'de-DE-Wavenet-H',
      text: 'German (Germany)  de-DE-Wavenet-H  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'German (Germany)',
    },
    {
      key: 'Greek (Greece)  el-GR-Standard-A  FEMALE',
      value: 'el-GR-Standard-A',
      text: 'Greek (Greece)  el-GR-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Greek (Greece)',
    },
    {
      key: 'Greek (Greece)  el-GR-Standard-B  FEMALE',
      value: 'el-GR-Standard-B',
      text: 'Greek (Greece)  el-GR-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Greek (Greece)',
    },
    {
      key: 'Greek (Greece)  el-GR-Wavenet-A  FEMALE',
      value: 'el-GR-Wavenet-A',
      text: 'Greek (Greece)  el-GR-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Greek (Greece)',
    },
    {
      key: 'Gujarati (India)  gu-IN-Standard-A  FEMALE',
      value: 'gu-IN-Standard-A',
      text: 'Gujarati (India)  gu-IN-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Gujarati (India)',
    },
    {
      key: 'Gujarati (India)  gu-IN-Standard-B  MALE',
      value: 'gu-IN-Standard-B',
      text: 'Gujarati (India)  gu-IN-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Gujarati (India)',
    },
    {
      key: 'Gujarati (India)  gu-IN-Standard-C  FEMALE',
      value: 'gu-IN-Standard-C',
      text: 'Gujarati (India)  gu-IN-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Gujarati (India)',
    },
    {
      key: 'Gujarati (India)  gu-IN-Standard-D  MALE',
      value: 'gu-IN-Standard-D',
      text: 'Gujarati (India)  gu-IN-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Gujarati (India)',
    },
    {
      key: 'Gujarati (India)  gu-IN-Wavenet-A  FEMALE',
      value: 'gu-IN-Wavenet-A',
      text: 'Gujarati (India)  gu-IN-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Gujarati (India)',
    },
    {
      key: 'Gujarati (India)  gu-IN-Wavenet-B  MALE',
      value: 'gu-IN-Wavenet-B',
      text: 'Gujarati (India)  gu-IN-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Gujarati (India)',
    },
    {
      key: 'Gujarati (India)  gu-IN-Wavenet-C  FEMALE',
      value: 'gu-IN-Wavenet-C',
      text: 'Gujarati (India)  gu-IN-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Gujarati (India)',
    },
    {
      key: 'Gujarati (India)  gu-IN-Wavenet-D  MALE',
      value: 'gu-IN-Wavenet-D',
      text: 'Gujarati (India)  gu-IN-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Gujarati (India)',
    },
    {
      key: 'Hebrew (Israel)  he-IL-Standard-A  FEMALE',
      value: 'he-IL-Standard-A',
      text: 'Hebrew (Israel)  he-IL-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Hebrew (Israel)',
    },
    {
      key: 'Hebrew (Israel)  he-IL-Standard-B  MALE',
      value: 'he-IL-Standard-B',
      text: 'Hebrew (Israel)  he-IL-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Hebrew (Israel)',
    },
    {
      key: 'Hebrew (Israel)  he-IL-Standard-C  FEMALE',
      value: 'he-IL-Standard-C',
      text: 'Hebrew (Israel)  he-IL-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Hebrew (Israel)',
    },
    {
      key: 'Hebrew (Israel)  he-IL-Standard-D  MALE',
      value: 'he-IL-Standard-D',
      text: 'Hebrew (Israel)  he-IL-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Hebrew (Israel)',
    },
    {
      key: 'Hebrew (Israel)  he-IL-Wavenet-A  FEMALE',
      value: 'he-IL-Wavenet-A',
      text: 'Hebrew (Israel)  he-IL-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Hebrew (Israel)',
    },
    {
      key: 'Hebrew (Israel)  he-IL-Wavenet-B  MALE',
      value: 'he-IL-Wavenet-B',
      text: 'Hebrew (Israel)  he-IL-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Hebrew (Israel)',
    },
    {
      key: 'Hebrew (Israel)  he-IL-Wavenet-C  FEMALE',
      value: 'he-IL-Wavenet-C',
      text: 'Hebrew (Israel)  he-IL-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Hebrew (Israel)',
    },
    {
      key: 'Hebrew (Israel)  he-IL-Wavenet-D  MALE',
      value: 'he-IL-Wavenet-D',
      text: 'Hebrew (Israel)  he-IL-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Hebrew (Israel)',
    },
    {
      key: 'Hindi (India)  hi-IN-Neural2-A  FEMALE',
      value: 'hi-IN-Neural2-A',
      text: 'Hindi (India)  hi-IN-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Neural2-B  MALE',
      value: 'hi-IN-Neural2-B',
      text: 'Hindi (India)  hi-IN-Neural2-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Neural2-C  MALE',
      value: 'hi-IN-Neural2-C',
      text: 'Hindi (India)  hi-IN-Neural2-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Neural2-D  FEMALE',
      value: 'hi-IN-Neural2-D',
      text: 'Hindi (India)  hi-IN-Neural2-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Standard-A  FEMALE',
      value: 'hi-IN-Standard-A',
      text: 'Hindi (India)  hi-IN-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Standard-B  MALE',
      value: 'hi-IN-Standard-B',
      text: 'Hindi (India)  hi-IN-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Standard-C  MALE',
      value: 'hi-IN-Standard-C',
      text: 'Hindi (India)  hi-IN-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Standard-D  FEMALE',
      value: 'hi-IN-Standard-D',
      text: 'Hindi (India)  hi-IN-Standard-D  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Standard-E  FEMALE',
      value: 'hi-IN-Standard-E',
      text: 'Hindi (India)  hi-IN-Standard-E  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Standard-F  MALE',
      value: 'hi-IN-Standard-F',
      text: 'Hindi (India)  hi-IN-Standard-F  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Wavenet-A  FEMALE',
      value: 'hi-IN-Wavenet-A',
      text: 'Hindi (India)  hi-IN-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Wavenet-B  MALE',
      value: 'hi-IN-Wavenet-B',
      text: 'Hindi (India)  hi-IN-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Wavenet-C  MALE',
      value: 'hi-IN-Wavenet-C',
      text: 'Hindi (India)  hi-IN-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Wavenet-D  FEMALE',
      value: 'hi-IN-Wavenet-D',
      text: 'Hindi (India)  hi-IN-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Wavenet-E  FEMALE',
      value: 'hi-IN-Wavenet-E',
      text: 'Hindi (India)  hi-IN-Wavenet-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hindi (India)  hi-IN-Wavenet-F  MALE',
      value: 'hi-IN-Wavenet-F',
      text: 'Hindi (India)  hi-IN-Wavenet-F  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Hindi (India)',
    },
    {
      key: 'Hungarian (Hungary)  hu-HU-Standard-A  FEMALE',
      value: 'hu-HU-Standard-A',
      text: 'Hungarian (Hungary)  hu-HU-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Hungarian (Hungary)',
    },
    {
      key: 'Hungarian (Hungary)  hu-HU-Standard-B  FEMALE',
      value: 'hu-HU-Standard-B',
      text: 'Hungarian (Hungary)  hu-HU-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Hungarian (Hungary)',
    },
    {
      key: 'Hungarian (Hungary)  hu-HU-Wavenet-A  FEMALE',
      value: 'hu-HU-Wavenet-A',
      text: 'Hungarian (Hungary)  hu-HU-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Hungarian (Hungary)',
    },
    {
      key: 'Icelandic (Iceland)  is-IS-Standard-A  FEMALE',
      value: 'is-IS-Standard-A',
      text: 'Icelandic (Iceland)  is-IS-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Icelandic (Iceland)',
    },
    {
      key: 'Icelandic (Iceland)  is-IS-Standard-B  FEMALE',
      value: 'is-IS-Standard-B',
      text: 'Icelandic (Iceland)  is-IS-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Icelandic (Iceland)',
    },
    {
      key: 'Indonesian (Indonesia)  id-ID-Standard-A  FEMALE',
      value: 'id-ID-Standard-A',
      text: 'Indonesian (Indonesia)  id-ID-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Indonesian (Indonesia)',
    },
    {
      key: 'Indonesian (Indonesia)  id-ID-Standard-B  MALE',
      value: 'id-ID-Standard-B',
      text: 'Indonesian (Indonesia)  id-ID-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Indonesian (Indonesia)',
    },
    {
      key: 'Indonesian (Indonesia)  id-ID-Standard-C  MALE',
      value: 'id-ID-Standard-C',
      text: 'Indonesian (Indonesia)  id-ID-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Indonesian (Indonesia)',
    },
    {
      key: 'Indonesian (Indonesia)  id-ID-Standard-D  FEMALE',
      value: 'id-ID-Standard-D',
      text: 'Indonesian (Indonesia)  id-ID-Standard-D  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Indonesian (Indonesia)',
    },
    {
      key: 'Indonesian (Indonesia)  id-ID-Wavenet-A  FEMALE',
      value: 'id-ID-Wavenet-A',
      text: 'Indonesian (Indonesia)  id-ID-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Indonesian (Indonesia)',
    },
    {
      key: 'Indonesian (Indonesia)  id-ID-Wavenet-B  MALE',
      value: 'id-ID-Wavenet-B',
      text: 'Indonesian (Indonesia)  id-ID-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Indonesian (Indonesia)',
    },
    {
      key: 'Indonesian (Indonesia)  id-ID-Wavenet-C  MALE',
      value: 'id-ID-Wavenet-C',
      text: 'Indonesian (Indonesia)  id-ID-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Indonesian (Indonesia)',
    },
    {
      key: 'Indonesian (Indonesia)  id-ID-Wavenet-D  FEMALE',
      value: 'id-ID-Wavenet-D',
      text: 'Indonesian (Indonesia)  id-ID-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Indonesian (Indonesia)',
    },
    {
      key: 'Italian (Italy)  it-IT-Journey-D  MALE',
      value: 'it-IT-Journey-D',
      text: 'Italian (Italy)  it-IT-Journey-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Journey-F  FEMALE',
      value: 'it-IT-Journey-F',
      text: 'Italian (Italy)  it-IT-Journey-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Journey-O  FEMALE',
      value: 'it-IT-Journey-O',
      text: 'Italian (Italy)  it-IT-Journey-O  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Neural2-A  FEMALE',
      value: 'it-IT-Neural2-A',
      text: 'Italian (Italy)  it-IT-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Neural2-C  MALE',
      value: 'it-IT-Neural2-C',
      text: 'Italian (Italy)  it-IT-Neural2-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Standard-A  FEMALE',
      value: 'it-IT-Standard-A',
      text: 'Italian (Italy)  it-IT-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Standard-B  FEMALE',
      value: 'it-IT-Standard-B',
      text: 'Italian (Italy)  it-IT-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Standard-C  MALE',
      value: 'it-IT-Standard-C',
      text: 'Italian (Italy)  it-IT-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Standard-D  MALE',
      value: 'it-IT-Standard-D',
      text: 'Italian (Italy)  it-IT-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Standard-E  FEMALE',
      value: 'it-IT-Standard-E',
      text: 'Italian (Italy)  it-IT-Standard-E  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Standard-F  MALE',
      value: 'it-IT-Standard-F',
      text: 'Italian (Italy)  it-IT-Standard-F  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Wavenet-A  FEMALE',
      value: 'it-IT-Wavenet-A',
      text: 'Italian (Italy)  it-IT-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Wavenet-B  FEMALE',
      value: 'it-IT-Wavenet-B',
      text: 'Italian (Italy)  it-IT-Wavenet-B  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Wavenet-C  MALE',
      value: 'it-IT-Wavenet-C',
      text: 'Italian (Italy)  it-IT-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Italian (Italy)  it-IT-Wavenet-D  MALE',
      value: 'it-IT-Wavenet-D',
      text: 'Italian (Italy)  it-IT-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Italian (Italy)',
    },
    {
      key: 'Japanese (Japan)  ja-JP-Neural2-B  FEMALE',
      value: 'ja-JP-Neural2-B',
      text: 'Japanese (Japan)  ja-JP-Neural2-B  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Japanese (Japan)',
    },
    {
      key: 'Japanese (Japan)  ja-JP-Neural2-C  MALE',
      value: 'ja-JP-Neural2-C',
      text: 'Japanese (Japan)  ja-JP-Neural2-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Japanese (Japan)',
    },
    {
      key: 'Japanese (Japan)  ja-JP-Neural2-D  MALE',
      value: 'ja-JP-Neural2-D',
      text: 'Japanese (Japan)  ja-JP-Neural2-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Japanese (Japan)',
    },
    {
      key: 'Japanese (Japan)  ja-JP-Standard-A  FEMALE',
      value: 'ja-JP-Standard-A',
      text: 'Japanese (Japan)  ja-JP-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Japanese (Japan)',
    },
    {
      key: 'Japanese (Japan)  ja-JP-Standard-B  FEMALE',
      value: 'ja-JP-Standard-B',
      text: 'Japanese (Japan)  ja-JP-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Japanese (Japan)',
    },
    {
      key: 'Japanese (Japan)  ja-JP-Standard-C  MALE',
      value: 'ja-JP-Standard-C',
      text: 'Japanese (Japan)  ja-JP-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Japanese (Japan)',
    },
    {
      key: 'Japanese (Japan)  ja-JP-Standard-D  MALE',
      value: 'ja-JP-Standard-D',
      text: 'Japanese (Japan)  ja-JP-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Japanese (Japan)',
    },
    {
      key: 'Japanese (Japan)  ja-JP-Wavenet-A  FEMALE',
      value: 'ja-JP-Wavenet-A',
      text: 'Japanese (Japan)  ja-JP-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Japanese (Japan)',
    },
    {
      key: 'Japanese (Japan)  ja-JP-Wavenet-B  FEMALE',
      value: 'ja-JP-Wavenet-B',
      text: 'Japanese (Japan)  ja-JP-Wavenet-B  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Japanese (Japan)',
    },
    {
      key: 'Japanese (Japan)  ja-JP-Wavenet-C  MALE',
      value: 'ja-JP-Wavenet-C',
      text: 'Japanese (Japan)  ja-JP-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Japanese (Japan)',
    },
    {
      key: 'Japanese (Japan)  ja-JP-Wavenet-D  MALE',
      value: 'ja-JP-Wavenet-D',
      text: 'Japanese (Japan)  ja-JP-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Japanese (Japan)',
    },
    {
      key: 'Kannada (India)  kn-IN-Standard-A  FEMALE',
      value: 'kn-IN-Standard-A',
      text: 'Kannada (India)  kn-IN-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Kannada (India)',
    },
    {
      key: 'Kannada (India)  kn-IN-Standard-B  MALE',
      value: 'kn-IN-Standard-B',
      text: 'Kannada (India)  kn-IN-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Kannada (India)',
    },
    {
      key: 'Kannada (India)  kn-IN-Standard-C  FEMALE',
      value: 'kn-IN-Standard-C',
      text: 'Kannada (India)  kn-IN-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Kannada (India)',
    },
    {
      key: 'Kannada (India)  kn-IN-Standard-D  MALE',
      value: 'kn-IN-Standard-D',
      text: 'Kannada (India)  kn-IN-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Kannada (India)',
    },
    {
      key: 'Kannada (India)  kn-IN-Wavenet-A  FEMALE',
      value: 'kn-IN-Wavenet-A',
      text: 'Kannada (India)  kn-IN-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Kannada (India)',
    },
    {
      key: 'Kannada (India)  kn-IN-Wavenet-B  MALE',
      value: 'kn-IN-Wavenet-B',
      text: 'Kannada (India)  kn-IN-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Kannada (India)',
    },
    {
      key: 'Kannada (India)  kn-IN-Wavenet-C  FEMALE',
      value: 'kn-IN-Wavenet-C',
      text: 'Kannada (India)  kn-IN-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Kannada (India)',
    },
    {
      key: 'Kannada (India)  kn-IN-Wavenet-D  MALE',
      value: 'kn-IN-Wavenet-D',
      text: 'Kannada (India)  kn-IN-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Kannada (India)',
    },
    {
      key: 'Korean (South Korea)  ko-KR-Neural2-A  FEMALE',
      value: 'ko-KR-Neural2-A',
      text: 'Korean (South Korea)  ko-KR-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Korean (South Korea)',
    },
    {
      key: 'Korean (South Korea)  ko-KR-Neural2-B  FEMALE',
      value: 'ko-KR-Neural2-B',
      text: 'Korean (South Korea)  ko-KR-Neural2-B  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Korean (South Korea)',
    },
    {
      key: 'Korean (South Korea)  ko-KR-Neural2-C  MALE',
      value: 'ko-KR-Neural2-C',
      text: 'Korean (South Korea)  ko-KR-Neural2-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Korean (South Korea)',
    },
    {
      key: 'Korean (South Korea)  ko-KR-Standard-A  FEMALE',
      value: 'ko-KR-Standard-A',
      text: 'Korean (South Korea)  ko-KR-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Korean (South Korea)',
    },
    {
      key: 'Korean (South Korea)  ko-KR-Standard-B  FEMALE',
      value: 'ko-KR-Standard-B',
      text: 'Korean (South Korea)  ko-KR-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Korean (South Korea)',
    },
    {
      key: 'Korean (South Korea)  ko-KR-Standard-C  MALE',
      value: 'ko-KR-Standard-C',
      text: 'Korean (South Korea)  ko-KR-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Korean (South Korea)',
    },
    {
      key: 'Korean (South Korea)  ko-KR-Standard-D  MALE',
      value: 'ko-KR-Standard-D',
      text: 'Korean (South Korea)  ko-KR-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Korean (South Korea)',
    },
    {
      key: 'Korean (South Korea)  ko-KR-Wavenet-A  FEMALE',
      value: 'ko-KR-Wavenet-A',
      text: 'Korean (South Korea)  ko-KR-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Korean (South Korea)',
    },
    {
      key: 'Korean (South Korea)  ko-KR-Wavenet-B  FEMALE',
      value: 'ko-KR-Wavenet-B',
      text: 'Korean (South Korea)  ko-KR-Wavenet-B  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Korean (South Korea)',
    },
    {
      key: 'Korean (South Korea)  ko-KR-Wavenet-C  MALE',
      value: 'ko-KR-Wavenet-C',
      text: 'Korean (South Korea)  ko-KR-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Korean (South Korea)',
    },
    {
      key: 'Korean (South Korea)  ko-KR-Wavenet-D  MALE',
      value: 'ko-KR-Wavenet-D',
      text: 'Korean (South Korea)  ko-KR-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Korean (South Korea)',
    },
    {
      key: 'Latvian (Latvia)  lv-LV-Standard-A  MALE',
      value: 'lv-LV-Standard-A',
      text: 'Latvian (Latvia)  lv-LV-Standard-A  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Latvian (Latvia)',
    },
    {
      key: 'Latvian (Latvia)  lv-LV-Standard-B  MALE',
      value: 'lv-LV-Standard-B',
      text: 'Latvian (Latvia)  lv-LV-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Latvian (Latvia)',
    },
    {
      key: 'Lithuanian (Lithuania)  lt-LT-Standard-A  MALE',
      value: 'lt-LT-Standard-A',
      text: 'Lithuanian (Lithuania)  lt-LT-Standard-A  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Lithuanian (Lithuania)',
    },
    {
      key: 'Lithuanian (Lithuania)  lt-LT-Standard-B  MALE',
      value: 'lt-LT-Standard-B',
      text: 'Lithuanian (Lithuania)  lt-LT-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Lithuanian (Lithuania)',
    },
    {
      key: 'Malay (Malaysia)  ms-MY-Standard-A  FEMALE',
      value: 'ms-MY-Standard-A',
      text: 'Malay (Malaysia)  ms-MY-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Malay (Malaysia)',
    },
    {
      key: 'Malay (Malaysia)  ms-MY-Standard-B  MALE',
      value: 'ms-MY-Standard-B',
      text: 'Malay (Malaysia)  ms-MY-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Malay (Malaysia)',
    },
    {
      key: 'Malay (Malaysia)  ms-MY-Standard-C  FEMALE',
      value: 'ms-MY-Standard-C',
      text: 'Malay (Malaysia)  ms-MY-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Malay (Malaysia)',
    },
    {
      key: 'Malay (Malaysia)  ms-MY-Standard-D  MALE',
      value: 'ms-MY-Standard-D',
      text: 'Malay (Malaysia)  ms-MY-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Malay (Malaysia)',
    },
    {
      key: 'Malay (Malaysia)  ms-MY-Wavenet-A  FEMALE',
      value: 'ms-MY-Wavenet-A',
      text: 'Malay (Malaysia)  ms-MY-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Malay (Malaysia)',
    },
    {
      key: 'Malay (Malaysia)  ms-MY-Wavenet-B  MALE',
      value: 'ms-MY-Wavenet-B',
      text: 'Malay (Malaysia)  ms-MY-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Malay (Malaysia)',
    },
    {
      key: 'Malay (Malaysia)  ms-MY-Wavenet-C  FEMALE',
      value: 'ms-MY-Wavenet-C',
      text: 'Malay (Malaysia)  ms-MY-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Malay (Malaysia)',
    },
    {
      key: 'Malay (Malaysia)  ms-MY-Wavenet-D  MALE',
      value: 'ms-MY-Wavenet-D',
      text: 'Malay (Malaysia)  ms-MY-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Malay (Malaysia)',
    },
    {
      key: 'Malayalam (India)  ml-IN-Standard-A  FEMALE',
      value: 'ml-IN-Standard-A',
      text: 'Malayalam (India)  ml-IN-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Malayalam (India)',
    },
    {
      key: 'Malayalam (India)  ml-IN-Standard-B  MALE',
      value: 'ml-IN-Standard-B',
      text: 'Malayalam (India)  ml-IN-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Malayalam (India)',
    },
    {
      key: 'Malayalam (India)  ml-IN-Standard-C  FEMALE',
      value: 'ml-IN-Standard-C',
      text: 'Malayalam (India)  ml-IN-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Malayalam (India)',
    },
    {
      key: 'Malayalam (India)  ml-IN-Standard-D  MALE',
      value: 'ml-IN-Standard-D',
      text: 'Malayalam (India)  ml-IN-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Malayalam (India)',
    },
    {
      key: 'Malayalam (India)  ml-IN-Wavenet-A  FEMALE',
      value: 'ml-IN-Wavenet-A',
      text: 'Malayalam (India)  ml-IN-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Malayalam (India)',
    },
    {
      key: 'Malayalam (India)  ml-IN-Wavenet-B  MALE',
      value: 'ml-IN-Wavenet-B',
      text: 'Malayalam (India)  ml-IN-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Malayalam (India)',
    },
    {
      key: 'Malayalam (India)  ml-IN-Wavenet-C  FEMALE',
      value: 'ml-IN-Wavenet-C',
      text: 'Malayalam (India)  ml-IN-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Malayalam (India)',
    },
    {
      key: 'Malayalam (India)  ml-IN-Wavenet-D  MALE',
      value: 'ml-IN-Wavenet-D',
      text: 'Malayalam (India)  ml-IN-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Malayalam (India)',
    },
    {
      key: 'Mandarin Chinese  cmn-CN-Standard-A  FEMALE',
      value: 'cmn-CN-Standard-A',
      text: 'Mandarin Chinese  cmn-CN-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-CN-Standard-B  MALE',
      value: 'cmn-CN-Standard-B',
      text: 'Mandarin Chinese  cmn-CN-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-CN-Standard-C  MALE',
      value: 'cmn-CN-Standard-C',
      text: 'Mandarin Chinese  cmn-CN-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-CN-Standard-D  FEMALE',
      value: 'cmn-CN-Standard-D',
      text: 'Mandarin Chinese  cmn-CN-Standard-D  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-CN-Wavenet-A  FEMALE',
      value: 'cmn-CN-Wavenet-A',
      text: 'Mandarin Chinese  cmn-CN-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-CN-Wavenet-B  MALE',
      value: 'cmn-CN-Wavenet-B',
      text: 'Mandarin Chinese  cmn-CN-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-CN-Wavenet-C  MALE',
      value: 'cmn-CN-Wavenet-C',
      text: 'Mandarin Chinese  cmn-CN-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-CN-Wavenet-D  FEMALE',
      value: 'cmn-CN-Wavenet-D',
      text: 'Mandarin Chinese  cmn-CN-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-TW-Standard-A  FEMALE',
      value: 'cmn-TW-Standard-A',
      text: 'Mandarin Chinese  cmn-TW-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-TW-Standard-B  MALE',
      value: 'cmn-TW-Standard-B',
      text: 'Mandarin Chinese  cmn-TW-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-TW-Standard-C  MALE',
      value: 'cmn-TW-Standard-C',
      text: 'Mandarin Chinese  cmn-TW-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-TW-Wavenet-A  FEMALE',
      value: 'cmn-TW-Wavenet-A',
      text: 'Mandarin Chinese  cmn-TW-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-TW-Wavenet-B  MALE',
      value: 'cmn-TW-Wavenet-B',
      text: 'Mandarin Chinese  cmn-TW-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Mandarin Chinese  cmn-TW-Wavenet-C  MALE',
      value: 'cmn-TW-Wavenet-C',
      text: 'Mandarin Chinese  cmn-TW-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Mandarin Chinese',
    },
    {
      key: 'Marathi (India)  mr-IN-Standard-A  FEMALE',
      value: 'mr-IN-Standard-A',
      text: 'Marathi (India)  mr-IN-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Marathi (India)',
    },
    {
      key: 'Marathi (India)  mr-IN-Standard-B  MALE',
      value: 'mr-IN-Standard-B',
      text: 'Marathi (India)  mr-IN-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Marathi (India)',
    },
    {
      key: 'Marathi (India)  mr-IN-Standard-C  FEMALE',
      value: 'mr-IN-Standard-C',
      text: 'Marathi (India)  mr-IN-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Marathi (India)',
    },
    {
      key: 'Marathi (India)  mr-IN-Wavenet-A  FEMALE',
      value: 'mr-IN-Wavenet-A',
      text: 'Marathi (India)  mr-IN-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Marathi (India)',
    },
    {
      key: 'Marathi (India)  mr-IN-Wavenet-B  MALE',
      value: 'mr-IN-Wavenet-B',
      text: 'Marathi (India)  mr-IN-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Marathi (India)',
    },
    {
      key: 'Marathi (India)  mr-IN-Wavenet-C  FEMALE',
      value: 'mr-IN-Wavenet-C',
      text: 'Marathi (India)  mr-IN-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Marathi (India)',
    },
    {
      key: 'Norwegian (Norway)  nb-NO-Standard-A  FEMALE',
      value: 'nb-NO-Standard-A',
      text: 'Norwegian (Norway)  nb-NO-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Norwegian (Norway)',
    },
    {
      key: 'Norwegian (Norway)  nb-NO-Standard-B  MALE',
      value: 'nb-NO-Standard-B',
      text: 'Norwegian (Norway)  nb-NO-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Norwegian (Norway)',
    },
    {
      key: 'Norwegian (Norway)  nb-NO-Standard-C  FEMALE',
      value: 'nb-NO-Standard-C',
      text: 'Norwegian (Norway)  nb-NO-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Norwegian (Norway)',
    },
    {
      key: 'Norwegian (Norway)  nb-NO-Standard-D  MALE',
      value: 'nb-NO-Standard-D',
      text: 'Norwegian (Norway)  nb-NO-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Norwegian (Norway)',
    },
    {
      key: 'Norwegian (Norway)  nb-NO-Standard-E  FEMALE',
      value: 'nb-NO-Standard-E',
      text: 'Norwegian (Norway)  nb-NO-Standard-E  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Norwegian (Norway)',
    },
    {
      key: 'Norwegian (Norway)  nb-NO-Standard-F  FEMALE',
      value: 'nb-NO-Standard-F',
      text: 'Norwegian (Norway)  nb-NO-Standard-F  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Norwegian (Norway)',
    },
    {
      key: 'Norwegian (Norway)  nb-NO-Standard-G  MALE',
      value: 'nb-NO-Standard-G',
      text: 'Norwegian (Norway)  nb-NO-Standard-G  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Norwegian (Norway)',
    },
    {
      key: 'Norwegian (Norway)  nb-NO-Wavenet-A  FEMALE',
      value: 'nb-NO-Wavenet-A',
      text: 'Norwegian (Norway)  nb-NO-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Norwegian (Norway)',
    },
    {
      key: 'Norwegian (Norway)  nb-NO-Wavenet-B  MALE',
      value: 'nb-NO-Wavenet-B',
      text: 'Norwegian (Norway)  nb-NO-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Norwegian (Norway)',
    },
    {
      key: 'Norwegian (Norway)  nb-NO-Wavenet-C  FEMALE',
      value: 'nb-NO-Wavenet-C',
      text: 'Norwegian (Norway)  nb-NO-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Norwegian (Norway)',
    },
    {
      key: 'Norwegian (Norway)  nb-NO-Wavenet-D  MALE',
      value: 'nb-NO-Wavenet-D',
      text: 'Norwegian (Norway)  nb-NO-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Norwegian (Norway)',
    },
    {
      key: 'Norwegian (Norway)  nb-NO-Wavenet-E  FEMALE',
      value: 'nb-NO-Wavenet-E',
      text: 'Norwegian (Norway)  nb-NO-Wavenet-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Norwegian (Norway)',
    },
    {
      key: 'Polish (Poland)  pl-PL-Standard-A  FEMALE',
      value: 'pl-PL-Standard-A',
      text: 'Polish (Poland)  pl-PL-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Polish (Poland)',
    },
    {
      key: 'Polish (Poland)  pl-PL-Standard-B  MALE',
      value: 'pl-PL-Standard-B',
      text: 'Polish (Poland)  pl-PL-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Polish (Poland)',
    },
    {
      key: 'Polish (Poland)  pl-PL-Standard-C  MALE',
      value: 'pl-PL-Standard-C',
      text: 'Polish (Poland)  pl-PL-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Polish (Poland)',
    },
    {
      key: 'Polish (Poland)  pl-PL-Standard-D  FEMALE',
      value: 'pl-PL-Standard-D',
      text: 'Polish (Poland)  pl-PL-Standard-D  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Polish (Poland)',
    },
    {
      key: 'Polish (Poland)  pl-PL-Standard-E  FEMALE',
      value: 'pl-PL-Standard-E',
      text: 'Polish (Poland)  pl-PL-Standard-E  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Polish (Poland)',
    },
    {
      key: 'Polish (Poland)  pl-PL-Standard-F  FEMALE',
      value: 'pl-PL-Standard-F',
      text: 'Polish (Poland)  pl-PL-Standard-F  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Polish (Poland)',
    },
    {
      key: 'Polish (Poland)  pl-PL-Standard-G  MALE',
      value: 'pl-PL-Standard-G',
      text: 'Polish (Poland)  pl-PL-Standard-G  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Polish (Poland)',
    },
    {
      key: 'Polish (Poland)  pl-PL-Wavenet-A  FEMALE',
      value: 'pl-PL-Wavenet-A',
      text: 'Polish (Poland)  pl-PL-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Polish (Poland)',
    },
    {
      key: 'Polish (Poland)  pl-PL-Wavenet-B  MALE',
      value: 'pl-PL-Wavenet-B',
      text: 'Polish (Poland)  pl-PL-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Polish (Poland)',
    },
    {
      key: 'Polish (Poland)  pl-PL-Wavenet-C  MALE',
      value: 'pl-PL-Wavenet-C',
      text: 'Polish (Poland)  pl-PL-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Polish (Poland)',
    },
    {
      key: 'Polish (Poland)  pl-PL-Wavenet-D  FEMALE',
      value: 'pl-PL-Wavenet-D',
      text: 'Polish (Poland)  pl-PL-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Polish (Poland)',
    },
    {
      key: 'Polish (Poland)  pl-PL-Wavenet-E  FEMALE',
      value: 'pl-PL-Wavenet-E',
      text: 'Polish (Poland)  pl-PL-Wavenet-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Polish (Poland)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Neural2-A  FEMALE',
      value: 'pt-BR-Neural2-A',
      text: 'Portuguese (Brazil)  pt-BR-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Neural2-B  MALE',
      value: 'pt-BR-Neural2-B',
      text: 'Portuguese (Brazil)  pt-BR-Neural2-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Neural2-C  FEMALE',
      value: 'pt-BR-Neural2-C',
      text: 'Portuguese (Brazil)  pt-BR-Neural2-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Standard-A  FEMALE',
      value: 'pt-BR-Standard-A',
      text: 'Portuguese (Brazil)  pt-BR-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Standard-B  MALE',
      value: 'pt-BR-Standard-B',
      text: 'Portuguese (Brazil)  pt-BR-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Standard-C  FEMALE',
      value: 'pt-BR-Standard-C',
      text: 'Portuguese (Brazil)  pt-BR-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Standard-D  FEMALE',
      value: 'pt-BR-Standard-D',
      text: 'Portuguese (Brazil)  pt-BR-Standard-D  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Standard-E  MALE',
      value: 'pt-BR-Standard-E',
      text: 'Portuguese (Brazil)  pt-BR-Standard-E  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Wavenet-A  FEMALE',
      value: 'pt-BR-Wavenet-A',
      text: 'Portuguese (Brazil)  pt-BR-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Wavenet-B  MALE',
      value: 'pt-BR-Wavenet-B',
      text: 'Portuguese (Brazil)  pt-BR-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Wavenet-C  FEMALE',
      value: 'pt-BR-Wavenet-C',
      text: 'Portuguese (Brazil)  pt-BR-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Wavenet-D  FEMALE',
      value: 'pt-BR-Wavenet-D',
      text: 'Portuguese (Brazil)  pt-BR-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Brazil)  pt-BR-Wavenet-E  MALE',
      value: 'pt-BR-Wavenet-E',
      text: 'Portuguese (Brazil)  pt-BR-Wavenet-E  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Portuguese (Brazil)',
    },
    {
      key: 'Portuguese (Portugal)  pt-PT-Standard-A  FEMALE',
      value: 'pt-PT-Standard-A',
      text: 'Portuguese (Portugal)  pt-PT-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Portuguese (Portugal)',
    },
    {
      key: 'Portuguese (Portugal)  pt-PT-Standard-B  MALE',
      value: 'pt-PT-Standard-B',
      text: 'Portuguese (Portugal)  pt-PT-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Portuguese (Portugal)',
    },
    {
      key: 'Portuguese (Portugal)  pt-PT-Standard-C  MALE',
      value: 'pt-PT-Standard-C',
      text: 'Portuguese (Portugal)  pt-PT-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Portuguese (Portugal)',
    },
    {
      key: 'Portuguese (Portugal)  pt-PT-Standard-D  FEMALE',
      value: 'pt-PT-Standard-D',
      text: 'Portuguese (Portugal)  pt-PT-Standard-D  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Portuguese (Portugal)',
    },
    {
      key: 'Portuguese (Portugal)  pt-PT-Standard-E  FEMALE',
      value: 'pt-PT-Standard-E',
      text: 'Portuguese (Portugal)  pt-PT-Standard-E  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Portuguese (Portugal)',
    },
    {
      key: 'Portuguese (Portugal)  pt-PT-Standard-F  MALE',
      value: 'pt-PT-Standard-F',
      text: 'Portuguese (Portugal)  pt-PT-Standard-F  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Portuguese (Portugal)',
    },
    {
      key: 'Portuguese (Portugal)  pt-PT-Wavenet-A  FEMALE',
      value: 'pt-PT-Wavenet-A',
      text: 'Portuguese (Portugal)  pt-PT-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Portuguese (Portugal)',
    },
    {
      key: 'Portuguese (Portugal)  pt-PT-Wavenet-B  MALE',
      value: 'pt-PT-Wavenet-B',
      text: 'Portuguese (Portugal)  pt-PT-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Portuguese (Portugal)',
    },
    {
      key: 'Portuguese (Portugal)  pt-PT-Wavenet-C  MALE',
      value: 'pt-PT-Wavenet-C',
      text: 'Portuguese (Portugal)  pt-PT-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Portuguese (Portugal)',
    },
    {
      key: 'Portuguese (Portugal)  pt-PT-Wavenet-D  FEMALE',
      value: 'pt-PT-Wavenet-D',
      text: 'Portuguese (Portugal)  pt-PT-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Portuguese (Portugal)',
    },
    {
      key: 'Punjabi (India)  pa-IN-Standard-A  FEMALE',
      value: 'pa-IN-Standard-A',
      text: 'Punjabi (India)  pa-IN-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Punjabi (India)',
    },
    {
      key: 'Punjabi (India)  pa-IN-Standard-B  MALE',
      value: 'pa-IN-Standard-B',
      text: 'Punjabi (India)  pa-IN-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Punjabi (India)',
    },
    {
      key: 'Punjabi (India)  pa-IN-Standard-C  FEMALE',
      value: 'pa-IN-Standard-C',
      text: 'Punjabi (India)  pa-IN-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Punjabi (India)',
    },
    {
      key: 'Punjabi (India)  pa-IN-Standard-D  MALE',
      value: 'pa-IN-Standard-D',
      text: 'Punjabi (India)  pa-IN-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Punjabi (India)',
    },
    {
      key: 'Punjabi (India)  pa-IN-Wavenet-A  FEMALE',
      value: 'pa-IN-Wavenet-A',
      text: 'Punjabi (India)  pa-IN-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Punjabi (India)',
    },
    {
      key: 'Punjabi (India)  pa-IN-Wavenet-B  MALE',
      value: 'pa-IN-Wavenet-B',
      text: 'Punjabi (India)  pa-IN-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Punjabi (India)',
    },
    {
      key: 'Punjabi (India)  pa-IN-Wavenet-C  FEMALE',
      value: 'pa-IN-Wavenet-C',
      text: 'Punjabi (India)  pa-IN-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Punjabi (India)',
    },
    {
      key: 'Punjabi (India)  pa-IN-Wavenet-D  MALE',
      value: 'pa-IN-Wavenet-D',
      text: 'Punjabi (India)  pa-IN-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Punjabi (India)',
    },
    {
      key: 'Romanian (Romania)  ro-RO-Standard-A  FEMALE',
      value: 'ro-RO-Standard-A',
      text: 'Romanian (Romania)  ro-RO-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Romanian (Romania)',
    },
    {
      key: 'Romanian (Romania)  ro-RO-Standard-B  FEMALE',
      value: 'ro-RO-Standard-B',
      text: 'Romanian (Romania)  ro-RO-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Romanian (Romania)',
    },
    {
      key: 'Romanian (Romania)  ro-RO-Wavenet-A  FEMALE',
      value: 'ro-RO-Wavenet-A',
      text: 'Romanian (Romania)  ro-RO-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Romanian (Romania)',
    },
    {
      key: 'Russian (Russia)  ru-RU-Standard-A  FEMALE',
      value: 'ru-RU-Standard-A',
      text: 'Russian (Russia)  ru-RU-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Russian (Russia)',
    },
    {
      key: 'Russian (Russia)  ru-RU-Standard-B  MALE',
      value: 'ru-RU-Standard-B',
      text: 'Russian (Russia)  ru-RU-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Russian (Russia)',
    },
    {
      key: 'Russian (Russia)  ru-RU-Standard-C  FEMALE',
      value: 'ru-RU-Standard-C',
      text: 'Russian (Russia)  ru-RU-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Russian (Russia)',
    },
    {
      key: 'Russian (Russia)  ru-RU-Standard-D  MALE',
      value: 'ru-RU-Standard-D',
      text: 'Russian (Russia)  ru-RU-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Russian (Russia)',
    },
    {
      key: 'Russian (Russia)  ru-RU-Standard-E  FEMALE',
      value: 'ru-RU-Standard-E',
      text: 'Russian (Russia)  ru-RU-Standard-E  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Russian (Russia)',
    },
    {
      key: 'Russian (Russia)  ru-RU-Wavenet-A  FEMALE',
      value: 'ru-RU-Wavenet-A',
      text: 'Russian (Russia)  ru-RU-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Russian (Russia)',
    },
    {
      key: 'Russian (Russia)  ru-RU-Wavenet-B  MALE',
      value: 'ru-RU-Wavenet-B',
      text: 'Russian (Russia)  ru-RU-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Russian (Russia)',
    },
    {
      key: 'Russian (Russia)  ru-RU-Wavenet-C  FEMALE',
      value: 'ru-RU-Wavenet-C',
      text: 'Russian (Russia)  ru-RU-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Russian (Russia)',
    },
    {
      key: 'Russian (Russia)  ru-RU-Wavenet-D  MALE',
      value: 'ru-RU-Wavenet-D',
      text: 'Russian (Russia)  ru-RU-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Russian (Russia)',
    },
    {
      key: 'Russian (Russia)  ru-RU-Wavenet-E  FEMALE',
      value: 'ru-RU-Wavenet-E',
      text: 'Russian (Russia)  ru-RU-Wavenet-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Russian (Russia)',
    },
    {
      key: 'Serbian (Cyrillic)  sr-RS-Standard-A  FEMALE',
      value: 'sr-RS-Standard-A',
      text: 'Serbian (Cyrillic)  sr-RS-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Serbian (Cyrillic)',
    },
    {
      key: 'Slovak (Slovakia)  sk-SK-Standard-A  FEMALE',
      value: 'sk-SK-Standard-A',
      text: 'Slovak (Slovakia)  sk-SK-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Slovak (Slovakia)',
    },
    {
      key: 'Slovak (Slovakia)  sk-SK-Standard-B  FEMALE',
      value: 'sk-SK-Standard-B',
      text: 'Slovak (Slovakia)  sk-SK-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Slovak (Slovakia)',
    },
    {
      key: 'Slovak (Slovakia)  sk-SK-Wavenet-A  FEMALE',
      value: 'sk-SK-Wavenet-A',
      text: 'Slovak (Slovakia)  sk-SK-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Slovak (Slovakia)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Journey-D  MALE',
      value: 'es-ES-Journey-D',
      text: 'Spanish (Spain)  es-ES-Journey-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Journey-F  FEMALE',
      value: 'es-ES-Journey-F',
      text: 'Spanish (Spain)  es-ES-Journey-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Journey-O  FEMALE',
      value: 'es-ES-Journey-O',
      text: 'Spanish (Spain)  es-ES-Journey-O  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Neural2-A  FEMALE',
      value: 'es-ES-Neural2-A',
      text: 'Spanish (Spain)  es-ES-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Neural2-B  MALE',
      value: 'es-ES-Neural2-B',
      text: 'Spanish (Spain)  es-ES-Neural2-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Neural2-C  FEMALE',
      value: 'es-ES-Neural2-C',
      text: 'Spanish (Spain)  es-ES-Neural2-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Neural2-D  FEMALE',
      value: 'es-ES-Neural2-D',
      text: 'Spanish (Spain)  es-ES-Neural2-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Neural2-E  FEMALE',
      value: 'es-ES-Neural2-E',
      text: 'Spanish (Spain)  es-ES-Neural2-E  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Neural2-F  MALE',
      value: 'es-ES-Neural2-F',
      text: 'Spanish (Spain)  es-ES-Neural2-F  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Polyglot-1  MALE',
      value: 'es-ES-Polyglot-1',
      text: 'Spanish (Spain)  es-ES-Polyglot-1  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Standard-A  FEMALE',
      value: 'es-ES-Standard-A',
      text: 'Spanish (Spain)  es-ES-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Standard-B  MALE',
      value: 'es-ES-Standard-B',
      text: 'Spanish (Spain)  es-ES-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Standard-C  FEMALE',
      value: 'es-ES-Standard-C',
      text: 'Spanish (Spain)  es-ES-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Standard-D  FEMALE',
      value: 'es-ES-Standard-D',
      text: 'Spanish (Spain)  es-ES-Standard-D  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Standard-E  MALE',
      value: 'es-ES-Standard-E',
      text: 'Spanish (Spain)  es-ES-Standard-E  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Standard-F  FEMALE',
      value: 'es-ES-Standard-F',
      text: 'Spanish (Spain)  es-ES-Standard-F  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Studio-C  FEMALE',
      value: 'es-ES-Studio-C',
      text: 'Spanish (Spain)  es-ES-Studio-C  FEMALE',
      type: 'Studio',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Studio-F  MALE',
      value: 'es-ES-Studio-F',
      text: 'Spanish (Spain)  es-ES-Studio-F  MALE',
      type: 'Studio',
      gender: 'MALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Wavenet-B  MALE',
      value: 'es-ES-Wavenet-B',
      text: 'Spanish (Spain)  es-ES-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Wavenet-C  FEMALE',
      value: 'es-ES-Wavenet-C',
      text: 'Spanish (Spain)  es-ES-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Wavenet-D  FEMALE',
      value: 'es-ES-Wavenet-D',
      text: 'Spanish (Spain)  es-ES-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Wavenet-E  MALE',
      value: 'es-ES-Wavenet-E',
      text: 'Spanish (Spain)  es-ES-Wavenet-E  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (Spain)  es-ES-Wavenet-F  FEMALE',
      value: 'es-ES-Wavenet-F',
      text: 'Spanish (Spain)  es-ES-Wavenet-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (Spain)',
    },
    {
      key: 'Spanish (US)  es-US-Journey-D  MALE',
      value: 'es-US-Journey-D',
      text: 'Spanish (US)  es-US-Journey-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Journey-F  FEMALE',
      value: 'es-US-Journey-F',
      text: 'Spanish (US)  es-US-Journey-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Journey-O  FEMALE',
      value: 'es-US-Journey-O',
      text: 'Spanish (US)  es-US-Journey-O  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Neural2-A  FEMALE',
      value: 'es-US-Neural2-A',
      text: 'Spanish (US)  es-US-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Neural2-B  MALE',
      value: 'es-US-Neural2-B',
      text: 'Spanish (US)  es-US-Neural2-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Neural2-C  MALE',
      value: 'es-US-Neural2-C',
      text: 'Spanish (US)  es-US-Neural2-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-News-D  MALE',
      value: 'es-US-News-D',
      text: 'Spanish (US)  es-US-News-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-News-E  MALE',
      value: 'es-US-News-E',
      text: 'Spanish (US)  es-US-News-E  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-News-F  FEMALE',
      value: 'es-US-News-F',
      text: 'Spanish (US)  es-US-News-F  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-News-G  FEMALE',
      value: 'es-US-News-G',
      text: 'Spanish (US)  es-US-News-G  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Polyglot-1  MALE',
      value: 'es-US-Polyglot-1',
      text: 'Spanish (US)  es-US-Polyglot-1  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Standard-A  FEMALE',
      value: 'es-US-Standard-A',
      text: 'Spanish (US)  es-US-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Standard-B  MALE',
      value: 'es-US-Standard-B',
      text: 'Spanish (US)  es-US-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Standard-C  MALE',
      value: 'es-US-Standard-C',
      text: 'Spanish (US)  es-US-Standard-C  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Studio-B  MALE',
      value: 'es-US-Studio-B',
      text: 'Spanish (US)  es-US-Studio-B  MALE',
      type: 'Studio',
      gender: 'MALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Wavenet-A  FEMALE',
      value: 'es-US-Wavenet-A',
      text: 'Spanish (US)  es-US-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Wavenet-B  MALE',
      value: 'es-US-Wavenet-B',
      text: 'Spanish (US)  es-US-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Spanish (US)  es-US-Wavenet-C  MALE',
      value: 'es-US-Wavenet-C',
      text: 'Spanish (US)  es-US-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Spanish (US)',
    },
    {
      key: 'Swedish (Sweden)  sv-SE-Standard-A  FEMALE',
      value: 'sv-SE-Standard-A',
      text: 'Swedish (Sweden)  sv-SE-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Swedish (Sweden)',
    },
    {
      key: 'Swedish (Sweden)  sv-SE-Standard-B  FEMALE',
      value: 'sv-SE-Standard-B',
      text: 'Swedish (Sweden)  sv-SE-Standard-B  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Swedish (Sweden)',
    },
    {
      key: 'Swedish (Sweden)  sv-SE-Standard-C  FEMALE',
      value: 'sv-SE-Standard-C',
      text: 'Swedish (Sweden)  sv-SE-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Swedish (Sweden)',
    },
    {
      key: 'Swedish (Sweden)  sv-SE-Standard-D  MALE',
      value: 'sv-SE-Standard-D',
      text: 'Swedish (Sweden)  sv-SE-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Swedish (Sweden)',
    },
    {
      key: 'Swedish (Sweden)  sv-SE-Standard-E  MALE',
      value: 'sv-SE-Standard-E',
      text: 'Swedish (Sweden)  sv-SE-Standard-E  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Swedish (Sweden)',
    },
    {
      key: 'Swedish (Sweden)  sv-SE-Standard-F  FEMALE',
      value: 'sv-SE-Standard-F',
      text: 'Swedish (Sweden)  sv-SE-Standard-F  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Swedish (Sweden)',
    },
    {
      key: 'Swedish (Sweden)  sv-SE-Standard-G  MALE',
      value: 'sv-SE-Standard-G',
      text: 'Swedish (Sweden)  sv-SE-Standard-G  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Swedish (Sweden)',
    },
    {
      key: 'Swedish (Sweden)  sv-SE-Wavenet-A  FEMALE',
      value: 'sv-SE-Wavenet-A',
      text: 'Swedish (Sweden)  sv-SE-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Swedish (Sweden)',
    },
    {
      key: 'Swedish (Sweden)  sv-SE-Wavenet-B  FEMALE',
      value: 'sv-SE-Wavenet-B',
      text: 'Swedish (Sweden)  sv-SE-Wavenet-B  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Swedish (Sweden)',
    },
    {
      key: 'Swedish (Sweden)  sv-SE-Wavenet-C  MALE',
      value: 'sv-SE-Wavenet-C',
      text: 'Swedish (Sweden)  sv-SE-Wavenet-C  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Swedish (Sweden)',
    },
    {
      key: 'Swedish (Sweden)  sv-SE-Wavenet-D  FEMALE',
      value: 'sv-SE-Wavenet-D',
      text: 'Swedish (Sweden)  sv-SE-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Swedish (Sweden)',
    },
    {
      key: 'Swedish (Sweden)  sv-SE-Wavenet-E  MALE',
      value: 'sv-SE-Wavenet-E',
      text: 'Swedish (Sweden)  sv-SE-Wavenet-E  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Swedish (Sweden)',
    },
    {
      key: 'Tamil (India)  ta-IN-Standard-A  FEMALE',
      value: 'ta-IN-Standard-A',
      text: 'Tamil (India)  ta-IN-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Tamil (India)',
    },
    {
      key: 'Tamil (India)  ta-IN-Standard-B  MALE',
      value: 'ta-IN-Standard-B',
      text: 'Tamil (India)  ta-IN-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Tamil (India)',
    },
    {
      key: 'Tamil (India)  ta-IN-Standard-C  FEMALE',
      value: 'ta-IN-Standard-C',
      text: 'Tamil (India)  ta-IN-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Tamil (India)',
    },
    {
      key: 'Tamil (India)  ta-IN-Standard-D  MALE',
      value: 'ta-IN-Standard-D',
      text: 'Tamil (India)  ta-IN-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Tamil (India)',
    },
    {
      key: 'Tamil (India)  ta-IN-Wavenet-A  FEMALE',
      value: 'ta-IN-Wavenet-A',
      text: 'Tamil (India)  ta-IN-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Tamil (India)',
    },
    {
      key: 'Tamil (India)  ta-IN-Wavenet-B  MALE',
      value: 'ta-IN-Wavenet-B',
      text: 'Tamil (India)  ta-IN-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Tamil (India)',
    },
    {
      key: 'Tamil (India)  ta-IN-Wavenet-C  FEMALE',
      value: 'ta-IN-Wavenet-C',
      text: 'Tamil (India)  ta-IN-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Tamil (India)',
    },
    {
      key: 'Tamil (India)  ta-IN-Wavenet-D  MALE',
      value: 'ta-IN-Wavenet-D',
      text: 'Tamil (India)  ta-IN-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Tamil (India)',
    },
    {
      key: 'Telugu (India)  te-IN-Standard-A  FEMALE',
      value: 'te-IN-Standard-A',
      text: 'Telugu (India)  te-IN-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Telugu (India)',
    },
    {
      key: 'Telugu (India)  te-IN-Standard-B  MALE',
      value: 'te-IN-Standard-B',
      text: 'Telugu (India)  te-IN-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Telugu (India)',
    },
    {
      key: 'Telugu (India)  te-IN-Standard-C  FEMALE',
      value: 'te-IN-Standard-C',
      text: 'Telugu (India)  te-IN-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Telugu (India)',
    },
    {
      key: 'Telugu (India)  te-IN-Standard-D  MALE',
      value: 'te-IN-Standard-D',
      text: 'Telugu (India)  te-IN-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Telugu (India)',
    },
    {
      key: 'Thai (Thailand)  th-TH-Neural2-C  FEMALE',
      value: 'th-TH-Neural2-C',
      text: 'Thai (Thailand)  th-TH-Neural2-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Thai (Thailand)',
    },
    {
      key: 'Thai (Thailand)  th-TH-Standard-A  FEMALE',
      value: 'th-TH-Standard-A',
      text: 'Thai (Thailand)  th-TH-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Thai (Thailand)',
    },
    {
      key: 'Turkish (Turkey)  tr-TR-Standard-A  FEMALE',
      value: 'tr-TR-Standard-A',
      text: 'Turkish (Turkey)  tr-TR-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Turkish (Turkey)',
    },
    {
      key: 'Turkish (Turkey)  tr-TR-Standard-B  MALE',
      value: 'tr-TR-Standard-B',
      text: 'Turkish (Turkey)  tr-TR-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Turkish (Turkey)',
    },
    {
      key: 'Turkish (Turkey)  tr-TR-Standard-C  FEMALE',
      value: 'tr-TR-Standard-C',
      text: 'Turkish (Turkey)  tr-TR-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Turkish (Turkey)',
    },
    {
      key: 'Turkish (Turkey)  tr-TR-Standard-D  FEMALE',
      value: 'tr-TR-Standard-D',
      text: 'Turkish (Turkey)  tr-TR-Standard-D  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Turkish (Turkey)',
    },
    {
      key: 'Turkish (Turkey)  tr-TR-Standard-E  MALE',
      value: 'tr-TR-Standard-E',
      text: 'Turkish (Turkey)  tr-TR-Standard-E  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Turkish (Turkey)',
    },
    {
      key: 'Turkish (Turkey)  tr-TR-Wavenet-A  FEMALE',
      value: 'tr-TR-Wavenet-A',
      text: 'Turkish (Turkey)  tr-TR-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Turkish (Turkey)',
    },
    {
      key: 'Turkish (Turkey)  tr-TR-Wavenet-B  MALE',
      value: 'tr-TR-Wavenet-B',
      text: 'Turkish (Turkey)  tr-TR-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Turkish (Turkey)',
    },
    {
      key: 'Turkish (Turkey)  tr-TR-Wavenet-C  FEMALE',
      value: 'tr-TR-Wavenet-C',
      text: 'Turkish (Turkey)  tr-TR-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Turkish (Turkey)',
    },
    {
      key: 'Turkish (Turkey)  tr-TR-Wavenet-D  FEMALE',
      value: 'tr-TR-Wavenet-D',
      text: 'Turkish (Turkey)  tr-TR-Wavenet-D  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Turkish (Turkey)',
    },
    {
      key: 'Turkish (Turkey)  tr-TR-Wavenet-E  MALE',
      value: 'tr-TR-Wavenet-E',
      text: 'Turkish (Turkey)  tr-TR-Wavenet-E  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Turkish (Turkey)',
    },
    {
      key: 'Ukrainian (Ukraine)  uk-UA-Standard-A  FEMALE',
      value: 'uk-UA-Standard-A',
      text: 'Ukrainian (Ukraine)  uk-UA-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Ukrainian (Ukraine)',
    },
    {
      key: 'Ukrainian (Ukraine)  uk-UA-Wavenet-A  FEMALE',
      value: 'uk-UA-Wavenet-A',
      text: 'Ukrainian (Ukraine)  uk-UA-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Ukrainian (Ukraine)',
    },
    {
      key: 'Vietnamese (Vietnam)  vi-VN-Neural2-A  FEMALE',
      value: 'vi-VN-Neural2-A',
      text: 'Vietnamese (Vietnam)  vi-VN-Neural2-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Vietnamese (Vietnam)',
    },
    {
      key: 'Vietnamese (Vietnam)  vi-VN-Neural2-D  MALE',
      value: 'vi-VN-Neural2-D',
      text: 'Vietnamese (Vietnam)  vi-VN-Neural2-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Vietnamese (Vietnam)',
    },
    {
      key: 'Vietnamese (Vietnam)  vi-VN-Standard-A  FEMALE',
      value: 'vi-VN-Standard-A',
      text: 'Vietnamese (Vietnam)  vi-VN-Standard-A  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Vietnamese (Vietnam)',
    },
    {
      key: 'Vietnamese (Vietnam)  vi-VN-Standard-B  MALE',
      value: 'vi-VN-Standard-B',
      text: 'Vietnamese (Vietnam)  vi-VN-Standard-B  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Vietnamese (Vietnam)',
    },
    {
      key: 'Vietnamese (Vietnam)  vi-VN-Standard-C  FEMALE',
      value: 'vi-VN-Standard-C',
      text: 'Vietnamese (Vietnam)  vi-VN-Standard-C  FEMALE',
      type: 'Standard',
      gender: 'FEMALE',
      language: 'Vietnamese (Vietnam)',
    },
    {
      key: 'Vietnamese (Vietnam)  vi-VN-Standard-D  MALE',
      value: 'vi-VN-Standard-D',
      text: 'Vietnamese (Vietnam)  vi-VN-Standard-D  MALE',
      type: 'Standard',
      gender: 'MALE',
      language: 'Vietnamese (Vietnam)',
    },
    {
      key: 'Vietnamese (Vietnam)  vi-VN-Wavenet-A  FEMALE',
      value: 'vi-VN-Wavenet-A',
      text: 'Vietnamese (Vietnam)  vi-VN-Wavenet-A  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Vietnamese (Vietnam)',
    },
    {
      key: 'Vietnamese (Vietnam)  vi-VN-Wavenet-B  MALE',
      value: 'vi-VN-Wavenet-B',
      text: 'Vietnamese (Vietnam)  vi-VN-Wavenet-B  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Vietnamese (Vietnam)',
    },
    {
      key: 'Vietnamese (Vietnam)  vi-VN-Wavenet-C  FEMALE',
      value: 'vi-VN-Wavenet-C',
      text: 'Vietnamese (Vietnam)  vi-VN-Wavenet-C  FEMALE',
      type: 'Premium',
      gender: 'FEMALE',
      language: 'Vietnamese (Vietnam)',
    },
    {
      key: 'Vietnamese (Vietnam)  vi-VN-Wavenet-D  MALE',
      value: 'vi-VN-Wavenet-D',
      text: 'Vietnamese (Vietnam)  vi-VN-Wavenet-D  MALE',
      type: 'Premium',
      gender: 'MALE',
      language: 'Vietnamese (Vietnam)',
    },
  ];

  const uniqueLanguages = [...new Set(languageOptions.map((option) => option.language))];
  const FilterLanguages = uniqueLanguages.map((language) => ({
    key: language,
    value: language,
    text: language,
  }));
  
  FilterLanguages.unshift({ key: '', value: '', text: 'Choose one' });

  const FilterTypes = [
    { key: 'choose', value: '', text: 'Choose one' },
    { key: 'standard', value: 'Standard', text: 'Standard' },
    { key: 'premium', value: 'Premium', text: 'Premium' },
  ];

  const FilterGenders = [
    { key: 'choose', value: '', text: 'Choose one' },
    { key: 'male', value: 'MALE', text: 'MALE' },
    { key: 'female', value: 'FEMALE', text: 'FEMALE' },
    { key: 'neutral', value: 'NEUTRAL', text: 'NEUTRAL' },
  ];

  const getVoices = () => {
    return languageOptions
    .filter(itm => itm.gender === genderFilter || genderFilter === '')
    .filter(el => el.language === languageFilter || languageFilter === '')
    .filter(el => el.type === typeFilter || typeFilter === '');
  };

  const updateAIProcessName = (val) => {
    setAIProcessName(val);
    tableData.name = val;
    localData.tableData = tableData;
    localData.AIProcessName = val;

    if(localData.fieldType===undefined) {
      localData.fieldType= fieldType;
    }

    updateData(localData);
  };


  const updateVoice = (val) => {
    setVoice(val);
    localData.voice = val;
    updateData(localData);
  };


  const extractUniqueTokens = (inputString) => {
    const regex = /\{\{([^}]+)\}\}/g;
    const tokens = [];
    let match;

    while ((match = regex.exec(inputString)) !== null) {
      const token = match[0];

      // Check if the token is already in the array
      const tokenExists = tokens.some((existingToken) => existingToken.token === token);

      if (!tokenExists) {
        tokens.push({
          token: token,
          value: '',
        });
      }
    }

    return tokens;
  };

  const updateTokenValue = (token, value) => {
    const copyArray = structuredClone(tokenArray);
    const idx = copyArray.findIndex((tkn) => tkn.token === token);
    if (idx !== -1) {
      copyArray[idx].value = value;
    }
    setTokenArray(copyArray);
  };

  const updatePromptMessage = (val) => {
    setPromptMessage(val);
    const tokens = extractUniqueTokens(val);
    setTokenArray(tokens);

    localData.promptMessage = val;
    localData.tokenArray = tokens;
    updateData(localData);
    //pare out tokena nd put into array, so user can enter test values.
  };

  const callAIProcess = async () => {
    let finalPromptMessage = promptMessage;

    tokenArray.map((tkn) => {
      finalPromptMessage = finalPromptMessage.replaceAll(tkn.token, tkn.value);
    });

    const eventData = {voice};

    const gbResponse = await aiProcessAPI(finalPromptMessage, 'speech', eventData);
    setAPIResponse(gbResponse);

    localData.apiData = gbResponse;
    updateData(localData);
  };

  const getAllFields = () => {
    const allOptions = [{ key: '0', value: '', text: 'Choose field' }];

    sourceNodes.map((node) => {
      node.data.insertMultiple == null &&
        node.data?.tableData?.tableinfo?.columns.map((col) => {
          const idx = allOptions.findIndex(
            (itm) => itm.key === `${node.data.tableData.id}||${col.data}`,
          );
          if (idx === -1) {
            allOptions.push({
              key: `${node.data.tableData.id}||${col.data}`,
              value: `${node.data.tableData.name}::${node.data.tableData.id}||${col.header}`,
              text: `${node.data.tableData.name}||${col.header}`,
            });
          }
        });
    });

    setAllFields(allOptions);
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div>
        AI Text-to-Speech Name:{' '}
        <Input
          value={AIProcessName}
          fluid
          onChange={(e, data) => updateAIProcessName(data.value)}
        />
      </div>
      <div style={{marginTop:'10px'}}>Field type (how field will be seen in subsequent actions)</div>
      <div>
        <Dropdown selection fluid options={fieldTypeOptions} value={fieldType} onChange={(e,data)=>updateFieldType(data.value)} />
      </div>

      <div style={{ marginTop: '10px' }}>Voice filters</div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',height:'60px' }}>
        <div>
          <Dropdown
            clearable
            search
            value={languageFilter}
            selection
            options={FilterLanguages}
            onChange={(e, data) => setLanguageFilter(data.value)}
          />
        </div>
        <div style={{ width: '100px', marginLeft: '10px', marginRight: '10px' }}>
          <Dropdown  clearable value={genderFilter} onChange={(e,data)=>setGenderFilter(data.value)} fluid selection options={FilterGenders} />
        </div>
        <div style={{ width: '100px' }}>
          <Dropdown  clearable fluid value={typeFilter} selection options={FilterTypes}  onChange={(e,data)=>setTypeFilter(data.value)} />
        </div>
      </div>
      <div style={{marginTop:'10px'}}>
        <div>Voices</div>
        <Dropdown fluid value={voice} onChange={(e,data)=>updateVoice(data.value)}  search selection options={getVoices()} />
      </div>
      <div>
        <div style={{ marginTop: '15px', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>Field tokens</div>
            <Dropdown
              clearable
              search
              selectOnBlur={false}
              options={allFields}
              fluid
              selection
              onChange={(e, data) =>
                data.value != '' ? updatePromptMessage(promptMessage + `{{${data.value}}}`) : null
              }
            />
          </div>

          <Form>
            <TextArea
              style={{
                marginTop: '20px',
                fontSize: '12px',
                height: '200px',
                border: '1px solid black',
                borderRadius: '10px',
                // width: '470px',
              }}
              onChange={(e, data) => updatePromptMessage(data.value)}
              value={promptMessage}
              placeholder="Enter text here"
            />
          </Form>

          {tokenArray.length > 0 ? (
            <table style={{ marginTop: '10px' }}>
              <tr>
                <th>Token</th>
                <th>Test value</th>
              </tr>
              {tokenArray.map((tkn) => (
                <tr>
                  <td>{tkn.token}</td>
                  <td>
                    <Input
                      value={tkn.value}
                      onChange={(e, data) => updateTokenValue(tkn.token, data.value)}
                    />
                  </td>
                </tr>
              ))}
            </table>
          ) : null}

          <div style={{ marginTop: '10px', marginBottom: '10px', }}>
            <GBButton text="Get response" padding="10px" color={'#0D99FF'} Action={callAIProcess} />
          </div>

          {APIResponse ? (
            <div style={{ maxHeight: '300px', overflow: 'auto',padding:'20px' }}>
              MP3 URL: {APIResponse.publicUrl}
              <AudioPlayer mp3Url={APIResponse.publicUrl} />
             </div>
          ) : null}
     

        </div>
      </div>
    </div>
  );
};

export default ActionAITextToSpeech;
