/* eslint-disable */
import cloudrun from '../clients/httpcloudrun';


export const getWorkFlows = async () => {
    const payload={dbfunction:'getWorkFlows'}
    const dataresponse = await cloudrun.post('/getWorkflowsAPI', {payload})

     return dataresponse
  }

export const getWorkflow = async (workflowid) =>{
    const payload={dbfunction:'getWorkflow',workflowid}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return dataresponse
}

export const getWorkflowHistory = async (workflowid,tableid) =>{
   const payload={workflowid,tableid}
   const dataresponse = await cloudrun.post('/workflowHistory', {payload})
   return dataresponse
}

export const refreshToken = async (service,userid) =>{
   const payload={userid,service};
   const dataresponse = await cloudrun.post('/oauth/refreshToken', {payload})
   return dataresponse
}

export const updateWorkFlow = async (workflow) =>{
   const payload={workflow, dbfunction:'updateWorkflow'}
   const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})

   await cloudrun.post('/refreshWorkflowCache', {payload})

   return dataresponse
}

export const manageCloudScheduler = async (workflowid,blockid,tableid,frequency,schedulerState,rowid,scheduleJobId=null) => {

   const payload={workflowid,blockid,tableid,rowid,state:schedulerState,frequency,scheduleJobId}
   const dataresponse = await cloudrun.post('/manageCloudSchedulerAPI', {payload})
   return dataresponse
}

export const updateWorkFlowName = async (name, workflowid) =>{
   const payload={name,workflowid, dbfunction:'updateWorkFlowName'}
   const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
   return dataresponse
}

export const updateWorkFlowStatus = async (workflowid,isactive,trigger) =>{

   const payload={isactive,workflowid,trigger}
   const dataresponse = await cloudrun.post('/updateWorkflowStatus', {payload})
   return dataresponse
}

export const addWorkFlow = async (name) =>{
   const payload={name, dbfunction:'addWorkflow'}
   const result = await cloudrun.post('/executeQueryAPI', {payload})
   return result[0].workflowid;
}

export const deleteWorkFlow = async (workflowid,trigger) =>{
   const payload={workflowid, trigger}
   await cloudrun.post('/deleteWorkflow', {payload})
   return "success";
}

export const duplicateWorkFlow = async (workflowid) =>{
   const payload={workflowid, dbfunction:'duplicateWorkflow'}
   await cloudrun.post('/executeQueryAPI', {payload})
   return "success";
}

  