/* eslint-disable */
import React, { useState,useEffect } from 'react';
import { toast } from 'react-toastify';
import { Buffer } from 'buffer';
import Global from '../../../../clients/global';
import { Dropdown } from 'semantic-ui-react';
import {mdiPlus,mdiClose,mdiClipboardCheck,mdiLink } from '@mdi/js';
import CodeEditor from '../../../../components/CodeEditor/CodeEditor';
import TextButton from '../../../../components/TextButton/TextButton';
import FieldButton from '../../../Workflow/FieldButton';
import EvaluateFunction from '../../../Workflow/EvaluateFunction';
import IconButton from '../FilterData/IconButton';
import { updateTableInfoAPI } from '../../../../api/tables';

const EmailMapping = ({tableid,tableinfo,color,updateEmailFields}) =>{

    /*
        "insertFields": [
          {
            "field": "field_1",
            "value": "subject",
          },
          {
            "field": "field_2",
            "value": "fromemail",
          },
          {
            "field": "field_22",
            "value": "function",
            "mappedvalue": "return {{fromemail}} +'test'""
          },

    */

    const [emailFields, setEmailFields] =useState(tableinfo.emailFields ?? []);
    const [availableFields, setAvailableFields] = useState([]);

    const { REACT_APP_SITE_URL} = process.env;
    let domain='@parse.graceblocks.com'
    if(REACT_APP_SITE_URL==='https://dev.graceblocks.com') {
      domain='@parsedev.graceblocks.com'
    } else if( REACT_APP_SITE_URL==='http://localhost:3000') {
      domain='@parsetest.graceblocks.com'
    }

    const encodeEmailPrefix = () => {
        const combined = `${Global.zoneid}|${tableid}`;
        const encoded = Buffer.from(combined).toString('hex'); 
        return encoded;
      }

    const tableEmail =`x${encodeEmailPrefix()}${domain}`;
    
    useEffect(() =>{
        let aFields=[{key:'notes',value:'notes',text:'Collaboration tab on record',uitype:33}];
        tableinfo.columns.filter(col=>[2,3,4,5,6,7,8,9,10,11,13,14,22,23].includes(col.uitype) && col.data !=='id').map(fld=>{
            aFields.push({key:fld.data,value:fld.data,text:fld.header,uitype:fld.uitype});
        })

        aFields.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));

        aFields = aFields.filter(
            (col) => !tableinfo.emailFields?.map((e) => e.field).includes(col.value),
          );

        setAvailableFields(aFields);

    },[])


    const copyToClipboard = (content) => {
        const el = document.createElement('textarea');
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast.success(<div style={{margin:'10px'}}>  Email copied!</div>, {
         position: toast.POSITION.BOTTOM_CENTER,
         autoClose: 2000,
       });
      };

    const addRemoveField = (value) => {
        const idx = emailFields.findIndex((el) => el.value === value);
        const idx2 = availableFields.findIndex((el) => el.value === value);
    
        let tempInsert = structuredClone(emailFields);
        let tempAvailable = structuredClone(availableFields);
    
        if (idx === -1) {
          const fld = availableFields[idx2];
          fld.mappedValue = 'manual';
          tempInsert.push(fld);
          tempAvailable = tempAvailable.filter((itm) => itm.value !== value);
        } else {
          const fld = emailFields[idx];
          fld.mappedValue = 'manual';
          tempAvailable.push(fld);
          tempInsert = tempInsert.filter((itm) => itm.value !== value);
        }
    
        setEmailFields(tempInsert);
        setAvailableFields(tempAvailable);
        updateEmailFields(tempInsert);

      };


    // const emailOptions =[
    //     {key:'subject',value:'subject',text:'Subject line'},
    //     {key:'body',value:'body',text:'Email body'},
    //     {key:'date',value:'date',text:'Date / time received'},
    //     {key:'fromname',value:'fromname',text:'From name'},
    //     {key:'fromemail',value:'fromemail',text:'From email'},
    //     {key:'cc',value:'cc',text:'CC email'},
    //     {key:'firstemail',value:'firstemail',text:'First email in body'},
    //     {key:'lastemail',value:'lastemail',text:'Last email in body'},
    //     {key:'attachments',value:'attachments',text:'Email attachments'},
    // ]

    const getMappedValue = (field, attribute) => {
        const idx = emailFields.findIndex((col) => col.key === field);
        if (idx !== -1) {
          return emailFields[idx][attribute] ?? false;
        }
      };

        const handleMappedValueChange = (field, val, attribute) => {
        const idx = emailFields.findIndex((col) => col.value === field);
        const temp = structuredClone(emailFields);
        temp[idx][attribute] = val;
        setEmailFields(temp);

        updateEmailFields(temp);

    };


    const getOptions = (uitype) => {
        const options = [
          { key: 'function', value: 'function', text: 'Javascript function' },
        ];
        
        if(uitype===2) {
            options.push({key:'subject',value:'subject',text:'Subject line'})
            options.push({key:'fromname',value:'fromname',text:'From name'})
            options.push({key:'cc',value:'cc',text:'CC email'})
        } 
        else if([3,13,33].includes(uitype)) {
            options.push( {key:'body',value:'body',text:'Email body'});
        }
        else if(uitype===22) {
            options.push({key:'date',value:'date',text:'Date / time received'});
        }
        else if(uitype===4) {
            options.push({key:'attachments',value:'attachments',text:'Email attachments'});
        }
        
        else if(uitype===10) {
            options.push({key:'fromemail',value:'fromemail',text:'From email'});
            options.push({key:'firstemail',value:'firstemail',text:'First email in body'});
            options.push({key:'lastemail',value:'lastemail',text:'Last email in body'});
            options.push({key:'cc',value:'cc',text:'CC email'});
        }

        else if(uitype===undefined) {
            options.push({key:'subject',value:'subject',text:'Subject line'});
            options.push({key:'body',value:'body',text:'Email body'});
            options.push({key:'date',value:'date',text:'Date / time received'});
            options.push({key:'fromname',value:'fromname',text:'From name'});
            options.push({key:'fromemail',value:'fromemail',text:'From email'});
            options.push({key:'cc',value:'cc',text:'CC email'});
            options.push({key:'firstemail',value:'firstemail',text:'First email in body'});
            options.push({key:'lastemail',value:'lastemail',text:'Last email in body'});
            options.push({key:'attachments',value:'attachments',text:'Email attachments'});
        }
            return options;
      };


    const updateFieldValue = (field, value) => {
        const idx = emailFields.findIndex((el) => el.key === field);
        if (idx !== -1) {
            emailFields[idx].dataValue = value;
            setEmailFields(emailFields);
            updateEmailFields(emailFields);
        }
    };

    return (
        <div style={{margin:'10px'}}>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', color:color}}>
                {tableEmail} 
                <div style={{width:'20px'}} />
                <TextButton textColor='black' text="Copy email" iconSize='20px' icon={mdiLink} Action={copyToClipboard} ActionValue={tableEmail} />
            </div> 
            <div>
                This is the is the dedicated email for this tab. Any email sent to this address will be processed to create a new record as long as mapping instructions are configured below.
            </div>
        <div>
            <FieldButton label="Add mapping" color={color} options={availableFields} addRemoveField={addRemoveField} icon={mdiPlus} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            {emailFields.length > 0
              ? emailFields.map((fld) => (
                  <div
                    key={fld.key}
                    style={{
                      margin: '10px',
                      padding: '10px',
                      border: '1px solid black',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        marginTop: '10px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ marginRight: '10px' }}>{fld.text}</div>
                      <Dropdown
                        selectOnBlur={false}
                        fluid
                        search
                        value={getMappedValue(fld.key, 'mappedValue')}
                        selection
                        options={getOptions(fld.uitype)}
                        onChange={(e, data) =>
                          handleMappedValueChange(fld.key, data.value, 'mappedValue')
                        }
                      />
                      <IconButton
                        color="red"
                        hoverColor="white"
                        hoverBackgroundColor="#F6685E"
                        icon={mdiClose}
                        Action={addRemoveField}
                        ActionValue={fld.value}
                      />
                    </div>                   
                    {getMappedValue(fld.key, 'mappedValue') === 'function' ? (
                      <>
                        <div style={{ marginTop: '10px' }}>
                          <CodeEditor
                            fields={getOptions().map((el) => el.text)}
                            updateFormula={updateFieldValue}
                            formula={fld.dataValue?.toString()}
                            isReadOnly={false}
                            mode="javascript"
                            field={fld.key}
                          />
                        </div>
                        <div>
                          <TextButton
                            tooltip="Enter sample values into the token fields for this function and click the play icon to see what will return as the result."
                            text="Evaluate"
                            textColor="#0D99FF"
                            color="#0D99FF"
                            hoverColor="#0D99FF80"
                            icon={mdiClipboardCheck}
                            Action={() => {
                              handleMappedValueChange(
                                fld.key,
                                !getMappedValue(fld.key, 'evaluateFunction'),
                                'evaluateFunction',
                              );
                            }}
                            iconSize="30px"
                            iconPosition="left"
                          />
                        </div>
                        {getMappedValue(fld.key, 'evaluateFunction') == true ? (
                          <div>
                            <EvaluateFunction functionString={fld.dataValue} />
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                ))
              : null}
          </div>
        </div>
        </div>
    )
}

export default EmailMapping;