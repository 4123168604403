/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { mdiPaperclip, mdiClose } from '@mdi/js';
import GBButton from '../../../../components/GBButton/GBButton';
import AttachmentCarousel from './AttachmentCarousel';
import { getOptionsAPI } from '../../../../api/options';
import { deleteFile } from '../../../../api/images';

const AttachmentViewer = ({
  tablename,
  lastUpdatedItem,
  recordname,
  removeItem,
  items,
  color,
  switchMode,
  tableid,
  field,
  updateItem,
  zoneid,
  isReadOnly,
  caller = 'grid',
  lastUpdate,
  selectmode,
  width,
  canEdit=true,
  allowCategories=false
}) => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [optionid, setOptionid] = useState(0);
  const [allCategories, setAllCategories] = useState([]);
  const [currentField, setCurrentField] = useState(field);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const FetchOptions = async () => {
    if(allowCategories) {
    const options = await getOptionsAPI(field, tableid);
    setAllCategories(options);
    }
  };

  useEffect(() => {
    setCurrentField(field);
    // let tempitems =items.filter(el=>el.optionid !==null)

    if (items !==null && items?.length > 0 && items[0].optionid !==null ) {
      var counts = items.reduce((p, c) => {
        var name = c.data;
        if (!p.hasOwnProperty(name)) {
          p[name] = 0;
        }
        p[name]++;
        return p;
      }, {});

      var countsExtended = Object.keys(counts).map((k) => {
        return {
          name: k,
          count: counts[k],
          item: items.filter((el) => el.data === k)[0],
          rank: items.filter((el) => el.data === k)[0].rank,
        };
      });

      countsExtended.sort((a, b) => (a.rank > b.rank ? 1 : -1));
      setCategories(countsExtended);
      // console.log(countsExtended)

      if (category.item === undefined || field !== currentField) {
        setCategory(countsExtended[0]);
        setOptionid(countsExtended[0].item.optionid);
        setCurrentImageIndex(0);
      }
    }
  }, [items,lastUpdate, field]); //items,lastUpdate, field

  useEffect(() => {
    FetchOptions();
  }, [field]); //field

  const changeCategory = (el) => {
    setCategory(el);
    setCurrentImageIndex(0)
    setOptionid(el.item.optionid);
  };

  const updateFileLocal = (url, newCategory, filename, imageIndex) => {
    setCurrentImageIndex(imageIndex);
    updateItem(url, newCategory, filename);
  };

  const removeFile = async (file) => {
    const catIndex = categories.findIndex(el=>el.name===file.data)
    //check if last file in the category. If yes (count=1), remove the category and of other
    //categories exist, change default category to 1st available
    if(catIndex !==-1) {
      if(categories[catIndex].count===1) {
      var tempCat =categories.filter(el=>el.name !==file.data)
        setCategories(tempCat);
        if(tempCat.length>0){
          changeCategory(tempCat[0])
        }
      }
    }
    
    const schema = `schema_${zoneid}`;
    if (file.uploadtype === 'desktop') {
      await deleteFile(schema,tableid,field, file.guidname, 'attachments');
    }
    removeItem(file.url);
  };


  const createtags = () => {
    if (categories?.length > 0 && categories[0]?.optionid === '1') {
      categories.push(categories.splice(0, 1)[0]);
    }
    return (
      categories.length > 0 &&
      categories.map((el) => (
        <div
          onClick={() => changeCategory(el)}
          key={el.item.url}
          css={{
            cursor: 'pointer',
            width: 'max-content',
            backgroundColor:
              el.item.optionid === category.item.optionid ? el.item.attributes.color : '#FFFFFF',
            color:
              el.item.optionid === category.item.optionid ? el.item.attributes.text : '#757575',
            border: `2px solid ${el.item.attributes.color}`,
            paddingLeft: '10px',
            paddingTop: '12px',
            paddingRight:'10px',
            paddingBottom: '10px',
            height: '17px',
            whiteSpace: 'nowrap',
            marginRight: '10px',
            display: 'inline-flex',
            verticalAlign: 'center',
            alignItems: 'center',
            alignContent: 'center',
            paddingRight: '10px',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: `${el.item.attributes.color}80`,
              color: '#ffffff',
            },
          }}
        >
          {el.name}: ({el.count})
        </div>
      ))
    );
  };

  return (
    <div style={{position:'relative',  height: '100%',overflow:'auto' }}>

      {/* 1-29-2022 This input is needed to prperly get focus when the detail page is viewed. We use
        this dummy input to autofocus but keep it off the screen hidden. Without it
        when you hit enter/backspace, focus is still on grid and doesn't when editing a field, etc */}
      <input style={{position:'absolute',top:-1000,height:'0px'}} autoFocus={canEdit} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '20px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {caller === 'grid' ? (
          <div style={{ marginLeft: '20px', fontWeight: 'bold' }}>
            Total attachments for {tablename}: {recordname} ({items.length})
          </div>
        ) : (
          <div></div>
        )}

        {!isReadOnly && (selectmode==='multiple' || ( items.length===0 )) && canEdit ? (
          <div style={{ marginRight: '20px',height:'50px' }}>
            <GBButton
              text="Add attachments"
              iconHoverColor="White"
              textColor="#757575"
              textHoverColor="#1A1A1A"
              Action={switchMode}
              ActionValue="addition"
              iconPosition="left"
              iconHoverBackgroundColor={color}
              icon={mdiPaperclip}
              hoverBorder={`1px solid ${color}`}
            />{' '}
          </div>
        ) : null}
      </div>
   
      {allCategories  && allCategories?.length > 1 ? (
        <div style={{display:'flex',flexDirection:'row',alignItems:'center', marginLeft: '20px', marginTop: '10px' }}>
          {createtags()} 
        </div>
      ) : null}
      {items?.length > 0 && items[0].optionid !==null && category.item !== undefined ? (
        <AttachmentCarousel
          caller={caller}
          currentImageIndex={currentImageIndex}
          isReadOnly={isReadOnly}
          lastUpdatedItem={lastUpdatedItem}
          // updateItem={updateItem}
          updateItem={updateFileLocal}
          allCategories={allCategories}
          showCategories={allCategories.length > 1 && allowCategories}
          optionid={optionid}
          deleteFile={removeFile}
          color={color}
          tempfiles={items.filter((itm) => itm.optionid == category.item.optionid)}
          windowWidth={width}
          canEdit={canEdit}
        />
      ) : null}
    </div>
  );
};

export default AttachmentViewer;
