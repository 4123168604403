/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Input, Icon as SIcon, Dropdown, Checkbox } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { AddTemplate } from '../../../../../api/templates';
import { checkTableNameUnique, csvLoader } from '../../../../../api/tables';
import { mdiCheckboxBlankOutline, mdiArrowLeft,mdiInformationOutline } from '@mdi/js';
import IconButton from '../../FilterData/IconButton';
import GBIcons from '../../../../../utils/GBIcons';
import GBButton from '../../../../../components/GBButton/GBButton';
import GBSwitch from '../../../../../components/GBSwitch/GBSwitch';
import TextButton from '../../../../../components/TextButton/TextButton';
import TextInput from '../../../../../components/TextInput/TextInput';
import Spinner from '../../../../../components/Spinner/Spinner';
import TemplateStartPage from './TemplateStartPage';
import SecurityTabs from './securityTabs';
import EmailMapping from '../EmailMapping';
import styles from './index.module.css';

const Newtab = ({
  color,
  updateTable,
  updateTemplateTable,
  close,
  blockid,
  tabname,
  tabrecord,
  tabicon,
  tabMode,
  tableid,
  tableinfo,
  plan,
  isSubTable
}) => {
  const emptySecurityMap = {
    myRecords: 0,
    allRecords: 0,
    addRecord: 0,
    editMyRecord:0,
    editOthersRecord: 0,
    editAnyRecord: 0,
    shareRecords: 0,
    downloadRecords: 0,
    printRecords: 0,
    deleteRecords: 0,
    viewSpreadSheet: 0,
    unmask:0,
    viewPrivate:0
  };
  const [Icons, setIcons] = useState(GBIcons);
  const [selectedIcon, setSelectedIcon] = useState(mdiCheckboxBlankOutline);
  const [name, setName] = useState(tabname);
  const [recordName, setRecordName] = useState(tabrecord);
  const [templateMode, setTemplateMode] = useState(tabMode);
  const [fields, setFields] = useState([]);
  const [field_1, setField1] = useState('id');
  const [isLoading, setIsLoading] = useState(false);
  const [field_2, setField2] = useState('');
  const [field_3, setField3] = useState('');
  const [isFieldChanged, setisFieldChanged] = useState(false);
  const [isRecordNameChanged, setIsRecordNameChanged] =useState(false);
  const [isSecurityChanged, setIsSecurityChanged] = useState(false);
  const [showIDField, setShowIDField] = useState(
    tableinfo && tableinfo.showIDField !== undefined ? tableinfo.showIDField : false,
  );
  const [useSecurity, setUseSecurity] = useState(false);
  const [securityMap, setSecurityMap] = useState(
    tableinfo && tableinfo.security !== undefined ? tableinfo.security : emptySecurityMap,
  );
  const [emailFields,setEmailFields] =useState( tableinfo && tableinfo.emailFields !== undefined ? tableinfo.emailFields : [])
  const [csvData, setCsvData] = useState([]);
  const [Delim1, setDelim1] = useState(tableinfo && tableinfo.Delim1 ? tableinfo.Delim1 : ' - ');
  const [Delim2, setDelim2] = useState(tableinfo && tableinfo.Delim2 ? tableinfo.Delim2 : ' - ');
  const [Starter, setStarter] = useState(tableinfo && tableinfo.Starter ? tableinfo.Starter : '');
  const [processChanges,setProcessChanges]=useState(tableinfo?.processChanges ?? false);
  const [showEmailProcessing, setShowEmailProcessing]=useState(false);
  const [processChangeFields, setProcessChangeFields] = useState(tableinfo?.processChangeFields ?? []);
  const [cacheFields, setCacheFields] = useState(tableinfo?.cacheFields ?? []);
  const [isSubTableLocal,setIsSubTableLocal] =useState(isSubTable);
  const [cacheResults, setCacheResults] = useState(tableinfo?.cacheResults ?? false);
  const [limit, setLimit] = useState(tableinfo?.limit ?? 2000);

  const updateField = (fieldNumber, fieldValue) => {
    setisFieldChanged(true);

    if (fieldNumber === 1) {
      if (fieldValue === '') {
        setField2('');
        setField3('');
      }
      setField1(fieldValue);
    }
    if (fieldNumber === 2) {
      if (fieldValue === '') {
        setField3('');
      }
      setField2(fieldValue);
    }
    if (fieldNumber === 3) {
      setField3(fieldValue);
    }
  };
  
  //6-19-23 We're adding new feature to enable process changes of lookup and formula fields. This is a feature which
  //will check the table every X minutes , cache the version and then use the defined fields to compare them to cached version
  //if there is a difference, it will update the updateon field on the record, so it will be picked up by Zapier updates or
  //any API call that is looking for changes. 
  const getChangeFields =() =>{
    const options=[];
    tableinfo.columns.filter(col=>col.uitype==18 || col.uitype===16).map(el=>{
      options.push({key:el.data,value:el.data,text:el.header})
    })
    options.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));
    return options;
  }

  const getCacheFields =() =>{
    const options=[];
    tableinfo.columns.filter(el=>el.uitype===16 || (el.uitype===18 && (el.jointype==='relational-special' || el.jointype==='relational-special-special' ))).map(el=>{
      options.push({key:el.data,value:el.data,text:el.header})
    })
    options.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));
    return options;
  }

  const toggleChangeFields =() =>{

    if (plan === 'Free'|| plan==='Starter') {
      toast.info('To enable auto processing of lookup/formula fields changes, please upgrade to the Pro plan.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      });
      return false;
    }

    setProcessChanges(!processChanges)
    setisFieldChanged(true);
  }

  const toggleShowEmailProcessing = () => {
    setShowEmailProcessing(!showEmailProcessing);
  }
  
  const changeFields = (value) =>{
    setProcessChangeFields(value);
    setisFieldChanged(true);
  }

  const changeCacheFields = (value) =>{
    setCacheFields(value);
    setisFieldChanged(true);
  }

  const updateShowIDField = () => {
    setShowIDField(!showIDField);
    setisFieldChanged(true); // we set to true so updated tableinfo is used in update.
  };

  const updateMaxLimit =(val) =>{
    setMaxLookup(val > 30 ? 30 :val);
    setisFieldChanged(true);
  }

  const updateBatchSize=(val)=>{
    setLimit(val > 2000 ? 2000 : val);
    setisFieldChanged(true);

  }
  const updateCacheResults = (val) =>{
    setCacheResults(val);
    setisFieldChanged(true);
  }

  const updateSubTable =() =>{
    setIsSubTableLocal(!isSubTableLocal);
  }


  const toggleUseSecurity = () => {
    setUseSecurity(!useSecurity);
  };

  const updateEmailFields = (fields) =>{
    setEmailFields(fields);
    setIsSecurityChanged(true); //re-using this flag, as I believe it forces tableinfo to be updated.
  }

  const updateSecurityTab = (priv, setting) => {
    if (plan === 'Free' ||  plan==='Starter') {
      toast.info( <div style={{ margin: '10px' }}>To apply security, please upgrade to the pro plan. <a style={{color:'white',textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newWindow">Learn more here.</a> </div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 7000,
      });
      return false;
    }

    let tmp = { ...securityMap };
    tmp[priv] = setting;
    if (
      priv === 'myRecords' &&
      (setting > tmp.allRecords || setting > tmp.editOthersRecord || setting > tmp.editAnyRecord)
    ) {
      tmp.allRecords = setting;
    }
    if (priv === 'allRecords' && setting < tmp.myRecords) {
      tmp.myRecords = setting;
    }
    if (priv === 'editOthersRecord' && setting > tmp.editAnyRecord) {
      tmp.editAnyRecord = setting;
    }
    if (priv === 'editMyRecord' && setting > tmp.editAnyRecord) {
      tmp.editAnyRecord = setting;
    }

    if (priv === 'editAnyRecord' && setting < tmp.editOthersRecord) {
      tmp.editOthersRecord = setting;
    }

    if (priv === 'editAnyRecord' && setting < tmp.editMyRecord) {
      tmp.editMyRecord = setting;
    }

    setSecurityMap(tmp);
    setIsSecurityChanged(true);
  };

  const updateTableRecord = async () => {
    setIsLoading(true);

    // 2-11-2022 adding check on tab name, if it's changed, to ensure it is unique
    if (tabname !== name) {
      const id = await checkTableNameUnique(blockid, tableid, name);

      if (id.length > 0) {
        setIsLoading(false);
        setName(tabname);
        toast.info('Tab names must be unique.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
        });
        return false;
      }
    }

    tableinfo.columns.forEach((itm) => {
      delete itm.concat;
      if (itm.data === field_1) {
        itm.concat = 1;
      } else if (itm.data === field_2) {
        itm.concat = 2;
      } else if (itm.data === field_3) {
        itm.concat = 3;
      }
    });

    tableinfo.showIDField = showIDField;
    tableinfo.security = securityMap;
    tableinfo.processChanges = processChanges
    tableinfo.processChangeFields =processChangeFields;
    tableinfo.limit = limit !=='' ? limit :2000;
    tableinfo.cacheResults = cacheResults;
    tableinfo.cacheFields = cacheFields;
    tableinfo.emailFields = emailFields;

    // 1-4-23 adding new relational identifier settings to tableinfo

    let isFieldChangedTemp = isFieldChanged;

    if(tableinfo.Delim1 !== Delim1 || tableinfo.Delim2 !== Delim2 || tableinfo.Starter !==Starter) {
      isFieldChangedTemp=true;
    }

    tableinfo.Delim1 = Delim1;
    tableinfo.Delim2 = Delim2;
    tableinfo.Starter = Starter;


    //7-6-222 New logic to auto-update record name to whatever the tablename is, but if tablename
    //ends in "s", truncate the s. This only happens if current recordname is "Record".
    let newRecordName = recordName;
    if (recordName === 'Record' && !isRecordNameChanged) {
      newRecordName = name;
      if (newRecordName.endsWith('s')) {
        newRecordName = newRecordName.slice(0, -1);
      }
    }

    tableinfo.recordname = newRecordName;


    if (csvData.length > 0) {
      const result = await csvLoader(tableid, tableinfo, csvData);
      await updateTable(
        tableid,
        name,
        selectedIcon,
        newRecordName,
        isFieldChangedTemp,
        isSecurityChanged,
        tableinfo,
        true,
        isSubTableLocal
      );
    } else {
      await updateTable(
        tableid,
        name,
        selectedIcon,
        newRecordName,
        isFieldChangedTemp,
        isSecurityChanged,
        tableinfo,
        false,
        isSubTableLocal
      );
    }

    setIsLoading(false);
    close();
  };

  const saveTemplate = async (templateid, isConfigured, phoneNumber, mobileNumber, template, plan) => {
    await AddTemplate(tableid, templateid, isConfigured, phoneNumber, mobileNumber,template,plan);
    await updateTemplateTable(tableid, template.name, template.icon, template.record);
    close();
  };

  const loadCSVFile = async (data) => {
    setCsvData(data);
    setTemplateMode('edit');
  };

  const updateValue = (field, value) => {
    if (field === 'table') {
      setName(value);
    } else if (field === 'record') {
      setRecordName(value);
      setIsRecordNameChanged(true);
    }
    setisFieldChanged(true);
  };

  useEffect(() => {
    if (tabMode === 'edit') {
      setName(tabname);
      setSelectedIcon(tabicon);
    }

    if (tableinfo !== null && tableinfo !== undefined) {
      const validFields = [2, 9, 11, 10, 1, 7, 17, 19, 20, 22, 23,16];
      const tempfields = [];
      const test = [];
      tableinfo.columns
        .filter((itm) => validFields.includes(itm.uitype))
        .forEach((el) => {
          // only allow single select relation fields as options.
          if (
            el.lookup === undefined ||
            (el.lookup !== 'multiple' && el.source === undefined) ||
            (el.lookup === 'single' && el.source && parseInt(el.source.lookuptableid) !== tableid)
          ) {
            if (tableid !== parseInt(el.source && el.source.lookuptableid)) {
              test.push({ tableid, lookupid: el.source && el.source.lookuptableid });
            }

            const item = { key: el.data, text: el.header, value: el.data, concat: el.concat };
            tempfields.push(item);
          }
        });

      const concatColumns = tableinfo.columns.filter((el) => el.concat !== undefined);
      concatColumns.sort((a, b) => (a.concat > b.concat ? 1 : -1));

      concatColumns.forEach((itm, index) => {
        if (index === 0) {
          setField1(itm.data);
        } else if (index === 1) {
          setField2(itm.data);
        } else if (index === 2) {
          setField3(itm.data);
        }
      });
      tempfields.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1)
      setFields(tempfields);
    }
  }, []);

  const DisplayIcons = () => {
    if (Icons.length > 0) {
      return Icons.map((icon, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <span
          key={i}
          onClick={() => setSelectedIcon(icon.path)}
          onKeyPress={() => setSelectedIcon(icon.path)}
          role="button"
          tabIndex="-1"
          style={{ margin: '5px', cursor: 'pointer' }}
        >
          <Icon path={icon.path} title={icon.name} size="18px" color={color} />
        </span>
      ));
    } else {
      return (
        <div>
          No results, try different criteria, or remove your criteria to scroll and find a relevant
          icon.
        </div>
      );
    }
  };

  const FilterIcons = (value) => {
    const filteredIcons = GBIcons.filter(
      (el) => el.tag.toLowerCase().includes(value) || el.name.toLowerCase().includes(value),
    );
    setIcons(filteredIcons);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper1} style={{ backgroundColor: color, paddingLeft: '10px' }}>
        <TextButton
          text={tabMode === 'add' ? 'Add new tab' : 'Edit tab properties'}
          hoverColor="#FFFFFF80"
          iconSize="20px"
          icon={mdiArrowLeft}
          iconPosition="left"
          Action={close}
        />
      </div>

      {!isLoading && templateMode === 'edit' ? (
        <>
          <div style={{ marginLeft: '20px', marginTop: '10px' }}>
            <TextInput
              field="table"
              updateValue={updateValue}
              name="NAME"
              color={color}
              defaultValue={tabname}
              placeholder="Tab 1"
            />
            <TextInput
              field="record"
              updateValue={updateValue}
              name="RECORD NAME"
              color={color}
              defaultValue={tabrecord}
              placeholder="Record"
            />
          </div>
          <div style={{ marginLeft: '30px', marginRight: '20px', fontSize: '11px' }}>
            Choose a tab and record name as well tab icon that best explains the data that will be
            stored inside this tab. The record name will appear when viewing the details page of any
            single record in the tab.
          </div>
          <div className={styles.container2} style={{ color }}>
            ICON: <Icon color={color} path={selectedIcon} size="18px" />
          </div>

          <div className={styles.boxborder}>
            <div className={styles.innerBoxBorder}>
              <Input
                style={{ width: '100%' }}
                icon="search"
                onChange={(e, data) => FilterIcons(data.value)}
                iconPosition="left"
                fluid
                placeholder="find..."
              />
            </div>

            <div className={styles.showIcons}>{DisplayIcons()}</div>
          </div>
          <div
            style={{
              display: 'flex',
              marginLeft: '30px',
              marginRight: '30px',
              marginTop: '15px',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '250px', fontSize: '11px', fontWeight: 'bold' }}>
              RECORD IDENTIFIER
            </div>
            <div style={{ fontSize: '11px' }}>
              The fields specified here will be used to view and find records for this Tab in
              various places throughout the Zone.
            </div>
          </div>
          <div
            style={{
              marginLeft: '30px',
              marginRight: '20px',
              marginTop: '15px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <div style={{ width: '80px', marginLeft: '5px', marginRight: '5px' }}>
              <Input value={Starter} onChange={(e,data)=>setStarter(data.value)}  maxLength="6" fluid style={{ width: '80px' }} />
            </div>
            <Dropdown
            selectOnBlur={false}
              // defaultValue={fields[0].value && fields[0].value}
              fluid
              value={field_1}
              upward
              selection
              clearable
              options={fields}
              onChange={(e, data) => updateField(1, data.value)}
            />
            {/* <div> - </div> */}
            <div style={{ width: '45px', marginLeft: '5px', marginRight: '5px' }}>
              <Input value={Delim1} onChange={(e,data)=>setDelim1(data.value)}  maxLength="3" fluid style={{ width: '45px' }} />
            </div>
            <Dropdown
            selectOnBlur={false}
              // defaultValue={fields[0].value && fields[0].value}
              fluid
              disabled={field_1 === ''}
              value={field_2}
              upward
              selection
              clearable
              options={fields}
              onChange={(e, data) => updateField(2, data.value)}
            />
            {/* <div> - </div> */}
            <div style={{ width: '45px', marginLeft: '5px', marginRight: '5px' }}>
              <Input value={Delim2} onChange={(e,data)=>setDelim2(data.value)}  maxLength="3" fluid style={{ width: '45px' }} />
            </div>
            <Dropdown
            selectOnBlur={false}
              // defaultValue={fields[0].value && fields[0].value}
              fluid
              disabled={field_2 === ''}
              value={field_3}
              upward
              selection
              clearable
              options={fields}
              onChange={(e, data) => updateField(3, data.value)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '10px',
              paddingBottom: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '30px',
                marginRight: '20px',
              }}
            >
               
              <div>
                <Checkbox
                  checked={showIDField}
                  onChange={(e, data) => updateShowIDField(data.checked)}
                  style={{ fontSize: '11px' }}
                  label="Show the system ID for each record instead of a details icon in the first column. (Clicking this column launches the details page)"
                />
              </div>

            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <div style={{width:'340px'}}>
                <GBSwitch
                  color={color}
                  text="Show new record email processing configurations"
                  isChecked={showEmailProcessing}
                  Action={toggleShowEmailProcessing}
                />
                </div>
                <IconButton size='20px' icon={mdiInformationOutline} tooltip="Click here to learn about email processing." Action={()=>window.open('https://www.graceblocks.com/support-article/understanding-subtabs')} color={color} />
              </div>

              {showEmailProcessing ? (
                <EmailMapping tableid={tableid} tableinfo={tableinfo} color={color} updateEmailFields={updateEmailFields} />
              ):null}

              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <div style={{width:'390px'}}>
                <GBSwitch
                  color={color}
                  text="Designate as a sub-tab (always create from related fields)."
                  isChecked={isSubTableLocal}
                  Action={updateSubTable}
                />
                </div>
                <IconButton size='20px' icon={mdiInformationOutline} tooltip="Click here to learn about what a sub tab is." Action={()=>window.open('https://www.graceblocks.com/support-article/understanding-subtabs')} color={color} />
              </div>


              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
              <div style={{width:'200px'}}>
              <GBSwitch
                color={color}
                text="Show user access security."
                isChecked={useSecurity}
                Action={toggleUseSecurity}
              />
              </div>
              <IconButton size='20px' icon={mdiInformationOutline} tooltip="Click here to learn about security access and controls." Action={()=>window.open('https://www.graceblocks.com/support-article/blocks-roles-and-security-settings')} color={color} />
              </div>
              {useSecurity ? (
                <div style={{ marginTop: '10px' }}>
                  <SecurityTabs
                    securityMap={securityMap}
                    updateSecurityTab={updateSecurityTab}
                    color={color}
                  />
                </div>
              ) : null}
              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
              <div style={{width:'310px'}}>
              <GBSwitch
                color={color}
                text="Process lookup / formula changes as updates."
                isChecked={processChanges}
                Action={toggleChangeFields}
              />
              </div>
               <IconButton size='20px' icon={mdiInformationOutline} tooltip="If you need to trigger workflow or integrations when a lookup or formula field changes in value to meet a desired condition, toggle on this feature and select the fields that are important to monitor. Click here to learn more about this feature." Action={()=>window.open('https://www.graceblocks.com/support-article/Processing-updates-for-lookup-and-formula-fields')} color={color} />
              </div>
              {processChanges ? (
                <div style={{marginTop:'5px', display:'flex',flexDirection:'row', alignItems:'center'}}>
                  Choose which fields to check for updates
                <Dropdown selectOnBlur={false} fluid multiple selection options={getChangeFields()} value={processChangeFields} onChange={(e,data)=>changeFields(data.value)} />
                </div>
              ):null}
            <div style={{marginTop:'10px',marginBottom:'5px'}}><b>Optimization settings</b> </div>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center',marginBottom:'5px'}}>
              <div style={{width:'maxContent'}}>
              <GBSwitch
                  color={color}
                  text="Cache formula fields for 24 hours"
                  isChecked={cacheResults}
                  Action={()=>updateCacheResults(!cacheResults)}
                />
              </div>
              <div style={{width:'10px'}} />
              <IconButton size='20px' icon={mdiInformationOutline} tooltip="Enables formula fields to be cached for 24 hours. Formula fields that reference lookups or other formula fields can affect performnace. Caching results in much faster performance.You can get the latest data by clicking the refresh button icon at top of screen." Action={()=>window.open('https://www.graceblocks.com/support-article/tab-optimization-settings')} color={color} />
              </div>
              {cacheResults ? (
                <div style={{marginTop:'5px', display:'flex',flexDirection:'row', alignItems:'center'}}>
                  Choose which fields to cache
                <Dropdown selectOnBlur={false} fluid multiple selection options={getCacheFields()} value={cacheFields} onChange={(e,data)=>changeCacheFields(data.value)} />
                </div>
              ):null}
              <p/>
           
            {/* <div style={{display:'flex', flexDirection:'row', alignItems:'center',marginBottom:'5px'}}>
               Initial # related records to load
              <div style={{width:'100px',marginLeft:'10px'}}>
              <Input style={{height:'25px'}} type="number" min="1" max="30" fluid value={maxLookup} onChange={(e,data)=>updateMaxLimit(data.value)} />
              </div>
              <div style={{width:'10px'}} />
              <IconButton size='20px' icon={mdiInformationOutline} tooltip="Sets the limit for the number of related records and associated lookup information returned in the list. Decreasing this number reduce the time it takes to render a list of records. Max value is 30, min value is 1. Click to learn more." Action={()=>window.open('https://www.graceblocks.com/support-article/tab-optimization-settings')} color={color} />
              </div> */}

              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
               Record scrolling increment
              <div style={{width:'100px',marginLeft:'10px'}}>
              <Input  style={{height:'25px'}} type="number" min="1" max="2000" fluid value={limit} onChange={(e,data)=>updateBatchSize(data.value)} />
              </div>
              <div style={{width:'10px'}} />
              <IconButton size='20px' icon={mdiInformationOutline} tooltip="Specifies the batching of records for loading them into the page. For example, if a table has 5000 records, the default batch size is 2000. When you scroll to the bottom and then continue scrolling, the system will fetch the next batch of 2000 records. Reducing batch size will reduce the time it takes to render a list of records. Scrolling is always the method by which you can load the next batch of records. The total number of both available and currently loaded records is shown in the bottom right corner of the screen. Click to learn more." Action={()=>window.open('https://www.graceblocks.com/support-article/tab-optimization-settings')} color={color} />
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <TextButton text="Cancel" textColor="black" hoverColor="#75757580" Action={close} />
                <div style={{ width: '10px' }} />
                <GBButton text="Save" width="75px" Action={updateTableRecord} color={color} />
              </div>
            </div>
          </div>
        </>
      ) : null}

      {isLoading ? <Spinner color={color} /> : null}

      {templateMode === 'add' && !isLoading ? (
        <TemplateStartPage
          color={color}
          tableid={tableid}
          setTemplateMode={setTemplateMode}
          saveTemplate={saveTemplate}
          loadCSVFile={loadCSVFile}
        />
      ) : null}
    </div>
  );
};

Newtab.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Newtab;
