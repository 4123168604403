/* eslint-disable */
const constructPath =(selectInfo,jsonObject) =>{

    let path = "";

    // 1. Handle Namespace (Array Indexes and Object Keys)
    if (selectInfo.namespace && selectInfo.namespace.length > 0) {
        path = selectInfo.namespace.map((part, index) => {
            if (!isNaN(parseInt(part)) && isFinite(part)) {
                // Array index
                const parent = selectInfo.namespace.slice(0, index).reduce((obj, key) => {
                    return obj ? obj[key] : undefined;
                }, jsonObject);

                if (Array.isArray(parent)) {
                    return "[]"; // Replace array index with "[]" for iteration if parent is an array
                } else {
                    return `[${part}]`; // Otherwise, keep the index
                }
            } else {
                // Object key
                return `.${part}`;
            }
        }).join("");
    }
    // 2. Handle Root Array
    const isRootArray = Array.isArray(jsonObject);
    if (isRootArray && selectInfo.namespace.length === 0) {
        // Root is an array AND we are at the root level
        path = "[]";
    }

    // 3. Add the Selected Property Name
    if (selectInfo.name !== null && selectInfo.name !== undefined && selectInfo.name !== "__proto__") {
        if (path === "[]") {
            path += `.${selectInfo.name}`; // Special case for root array iteration
        } else if (path === "") {
            path = selectInfo.name; // Handle cases where the root is not an array and no namespace is present
        } else if (path.endsWith("]")) {
            path += `.${selectInfo.name}`; // Append property name after array index
        } else {
            path += `.${selectInfo.name}`; // Append property name for other cases
        }
    }

    return path.startsWith(".") ? path.slice(1) : path; 
  }

  export default constructPath