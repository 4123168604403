/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react';

import { Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import LetterColors from '../../../../../utils/LetterColors';

const NameControl = ({
  currentRecord,
  messageField,
  color,
  index,
  setCurrentRecord,
  isSelected,
  fontSize = '18px',
  cellHeight = '50px',
  isChecked,
  toggleCheck,
  showTextInfo
}) => {
  const getLetter = currentRecord.CustomID && currentRecord.CustomID.slice(0, 1);
  const lastMessage = currentRecord[messageField] && currentRecord[messageField][0];

  const getMessage = () => {
    if (lastMessage && lastMessage.message && lastMessage.message.length > 65) {
      return lastMessage.message.slice(0, 60) + '...';
    } else if (lastMessage) {
      return lastMessage.message;
    }
  };

  const getDuration = () => {
    if (lastMessage && lastMessage.addedon !== null) {
      const nowdate = new Date();
      const ms = moment().diff(moment(lastMessage && lastMessage.addedon), 'minutes');

      if (lastMessage && lastMessage.schedule !== null) {
        return 'Scheduled';
      } else if (ms === 0) {
        return 'Now';
      } else if (ms > 0 && ms < 60) {
        return `${ms}M ago`;
      } else if (ms > 60 && ms < 1440) {
        return `${moment().diff(moment(lastMessage && lastMessage.addedon), 'hours')}H ago`;
      } else if (ms > 1440 && ms <=10800) {
        return `${moment().diff(moment(lastMessage && lastMessage.addedon), 'days')}D ago`;
      } else if (ms > 10800) {
        return  moment(lastMessage && lastMessage.addedon).format('DD-MMM-YYYY');
      }
    } else {
      return '';
    }
  };

  const getLetterColor =(letter) => {
    const idx = LetterColors.findIndex(el=>el.letter===letter.toLowerCase());
    if(idx !==-1) {
      return LetterColors[idx].color
    } else {
      return '#1B8892'
    }
  }

  return (
    <div
    onClick={() => setCurrentRecord(currentRecord)}
      css={{
        display: 'flex',
        paddingLeft: '5px',
        cursor: 'pointer',
        // marginBottom: '5px',
        paddingTop: '10px',
        marginBottom: '2px',
        borderBottom: '1px solid #eee',
        border: isSelected ? `1px solid ${color}` : '',
        backgroundColor: isSelected ? '#eee' : 'white',
        color: '#312602',
        paddingBottom: '10px',
        flexDirection: 'row',
        position: 'relative',
        '&:hover': {
          backgroundColor: '#eeeeee66',
        },
      }}
    >
      {/* <div style={{ width: '20px', fontSize:'11px', color:"#757575" }}>{index+1}</div> */}
      <div style={{margin:'5px'}}>
      <Checkbox checked={isChecked} onChange={(e,data)=>toggleCheck(data.checked,currentRecord.id)} />
      </div>
    
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          backgroundColor: getLetterColor(getLetter),
          borderRadius: '15px',
          width: '30px',
          height: '30px',
        }}
      >
        {getLetter}
      </div>

      <>
        <div
        
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10px',
            width: '200px',
            height: cellHeight,
            //   overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <div style={{ fontSize: fontSize, fontWeight: 'bold',width:'325px' }}>{currentRecord.CustomID}</div>
          {lastMessage !== undefined && showTextInfo ? (
            <div style={{ fontSize: '13px', textOverflow: 'ellipsis', lineHeight:'1.0' }}>
            {getMessage()}
            </div>
          ) : null}
        </div>
        {lastMessage !== undefined  && showTextInfo ? (
          <div style={{ position: 'absolute', top: '5px', right: '10px', fontSize: '13px' }}>
            {getDuration()}
          </div>
        ) : null}
      </>
    </div>
  );
};

export default NameControl;
