/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Input, Dropdown, TextArea, Form } from 'semantic-ui-react';
import ReactJson from 'react-json-view';
import { mdiClose, mdiEmail, mdiPlus, mdiChat } from '@mdi/js';
import IconButton from '../Block/components/FilterData/IconButton';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import GBButton from '../../components/GBButton/GBButton';
import { aiProcessAPI } from '../../api/ai';
import constructPath from './constructJSONPath';

const ActionAIImageProcess = ({ localData, updateData, editMode, sourceNodes }) => {
  const [AIProcessName, setAIProcessName] = useState(localData.AIProcessName ?? '');
  const [imageFields, setImageFields] = useState([]);
  const [APIResponse, setAPIResponse] = useState(localData.apiData ?? null);
  const [imageField, setImageField] = useState(localData.imageField ?? '');
  const [feature, setFeature] = useState(localData.feature ?? '');
  const [testUrl, setTestUrl] = useState(localData.testUrl ?? '');
  const [processAllRecords, setProcessAllRecords] = useState(localData.processAllRecords ?? false);
  const [tableData, setTableData] = useState(
    localData.tableData ?? {
      name: `api_${localData.id}`,
      id: `api_${localData.id}`,
      tableinfo: { columns: [{ data: 'apiResponse', uitype: 2, header: 'API Response as text' }] },
    },
  );

  useEffect(() => {
    getAllFields();
  }, []);

  const updateAIProcessName = (val) => {
    setAIProcessName(val);
    tableData.name = val;
    localData.tableData = tableData;
    localData.AIProcessName = val;
    updateData(localData);
  };

  const updateProcessRecords = (val) => {
    setProcessAllRecords(val);
    localData.processAllRecords = val;
    updateData(localData);
  };

  const imageFeatures = [
    {
      key: 'DOCUMENT_TEXT_DETECTION',
      value: 'DOCUMENT_TEXT_DETECTION',
      text: 'DOCUMENT_TEXT_DETECTION',
    },
    { key: 'FACE_DETECTION', value: 'FACE_DETECTION', text: 'FACE_DETECTION' },
    { key: 'IMAGE_PROPERTIES', value: 'IMAGE_PROPERTIES', text: 'IMAGE_PROPERTIES' },
    { key: 'LABEL_DETECTION', value: 'LABEL_DETECTION', text: 'LABEL_DETECTION' },
    { key: 'LANDMARK_DETECTION', value: 'LANDMARK_DETECTION', text: 'LANDMARK_DETECTION' },
    { key: 'LOGO_DETECTION', value: 'LOGO_DETECTION', text: 'LOGO_DETECTION' },
    { key: 'SAFE_SEARCH_DETECTION', value: 'SAFE_SEARCH_DETECTION', text: 'SAFE_SEARCH_DETECTION' },
    { key: 'TEXT_DETECTION', value: 'TEXT_DETECTION', text: 'TEXT_DETECTION' },
    { key: 'WEB_DETECTION', value: 'WEB_DETECTION', text: 'WEB_DETECTION' },
  ];

  const updateTestUrl = (val) => {
    setTestUrl(val);
    localData.testUrl = val;
    updateData(localData);
  };

  const addJsonElement = (val) => {
    let path = constructPath(val, APIResponse);

    const copyTableData = structuredClone(tableData);
    copyTableData.tableinfo.columns = tableData.tableinfo.columns ?? [];
    const idx = copyTableData.tableinfo.columns.findIndex((col) => col.data === val.name);
    if (idx === -1) {
      const uitype = val.type === 'integer' ? 23 : 2;
      copyTableData.tableinfo.columns.push({
        data: val.name,
        header: val.name,
        uitype: uitype,
        path: path,
      });
      setTableData(copyTableData);
      localData.tableData = copyTableData;
      updateData(localData);
    }
  };

  const removeJsonElement = (header) => {
    const copyTableData = structuredClone(tableData);
    copyTableData.tableinfo.columns = copyTableData.tableinfo.columns.filter(
      (col) => col.header !== header,
    );
    setTableData(copyTableData);
    localData.tableData = copyTableData;
    updateData(localData);
  };

  const updateImageField = (val) => {
    setImageField(val);
    localData.imageField = val;
    updateData(localData);
  };

  const updateFeature = (val) => {
    setFeature(val);
    localData.feature = val;
    updateData(localData);
  };

  const callAIProcess = async () => {
    const eventData ={url:testUrl,feature:feature}
    const gbResponse = await aiProcessAPI(null, 'aiimageprocess', eventData);
    setAPIResponse(gbResponse);

    localData.apiData = gbResponse;
    updateData(localData);
  };

  const getAllFields = () => {
    const imageOptions = [{ key: '0', value: '', text: 'Choose field' }];

    sourceNodes.map((node) => {
      !node.data.insertMultiple &&
        node.data?.tableData?.tableinfo?.columns
          .filter(
            (el) =>
              (el.uitype == 4 && el.lookup === 'single') ||
              (el.source?.lookupuitype === 4 && el.lookup === 'single'),
          )
          .map((col) => {
            const idx = imageOptions.findIndex(
              (itm) => itm.key === `${node.data.tableData.id}|${col.data}`,
            );
            if (idx === -1) {
              imageOptions.push({
                key: `${node.data.tableData.id}|${col.data}`,
                value: `${node.data.tableData.id}|${col.data}`,
                text: `${node.data.tableData.name} (${col.header})`,
              });
            }
          });
    });

    setImageFields(imageOptions);
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div>
        AI Image Process Name:{' '}
        <Input
          value={AIProcessName}
          fluid
          onChange={(e, data) => updateAIProcessName(data.value)}
        />
      </div>

      <div>
        <div style={{ marginTop: '15px', marginBottom: '10px' }}>
          <div>
            <div style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>Image Processing</div>
            <Dropdown
              selection
              fluid
              options={imageFeatures}
              value={feature}
              onChange={(e, data) => updateFeature(data.value)}
            />
            <div style={{ whiteSpace: 'nowrap', marginRight: '5px', marginTop: '10px' }}>
              Single Attachment field
            </div>
            <div>
              <Dropdown
                clearable
                search
                value={imageField}
                options={imageFields}
                fluid
                selection
                onChange={(e, data) => updateImageField(data.value)}
              />
            </div>
          </div>
          {imageField !== '' ? (
            <div>
              <div style={{ marginTop: '10px' }}>
                Test URL (pdf, tif, jpg, png, gif, bmp, webp, ico)
              </div>
              <Input
                fluid
                value={testUrl}
                placeholder="Test Image URL"
                onChange={(e, data) => updateTestUrl(data.value)}
              />
            </div>
          ) : null}

          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            <GBButton text="Get response" padding="10px" color={'#0D99FF'} Action={callAIProcess} />
          </div>

          {APIResponse ? (
            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
              <ReactJson theme="ocean" onSelect={addJsonElement} src={APIResponse} />
            </div>
          ) : null}

          <div style={{ marginTop: '5px', marginBottom: '5px', fontWeight: 'bold' }}>
            Fields to capture
          </div>

          {tableData?.tableinfo.columns?.map((col) => (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ marginRight: '5px' }}>{col.header}</div>
              {col.data !== 'apiResponse' ? (
                <div>
                  <IconButton
                    icon={mdiClose}
                    iconSize="20px"
                    color={'#E06055'}
                    hoverColor={'#E0605580'}
                    Action={removeJsonElement}
                    ActionValue={col.header}
                  />
                </div>
              ) : null}
            </div>
          ))}
          {Array.isArray(APIResponse) ? (
            <div>
              <GBSwitch
                text="Process all records"
                isChecked={processAllRecords}
                Action={() => updateProcessRecords(!processAllRecords)}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ActionAIImageProcess;
