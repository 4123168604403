/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';

import {
  mdiEye,
  mdiEyeOff,
  mdiClose,
  mdiDelete,
  mdiPencil,
  mdiDragVertical,
  mdiNumeric1Circle,
  mdiNumeric2Circle,
  mdiNumeric3Circle,
  mdiSortAscending,
  mdiSortDescending,
} from '@mdi/js';
import { Checkbox } from 'semantic-ui-react';
import IconButton from '../FilterData/IconButton';

const ColumnItem = ({
  color,
  role,
  item,
  updateColumn,
  index,
  sortItems,
  groupItems,
  calcItems,
  updateSortItems,
  updateGroupItems,
  updateSortOrder,
  updateCalcItems,
  dbname,
  editColumn,
  isEditMode,
  deleteColumn,
}) => {
  function isOdd(num) {
    return num % 2;
  }

  const sortidx = sortItems.findIndex((el) => el.field === item.data);
  const groupidx = groupItems.findIndex((el) => el === item.data);
  const calcidx = calcItems.findIndex((el) => el === item.data);

  const getType = (uitype) => {
    let control = '';
    switch (uitype) {
      case 1:
        control = 'Relational';
        break;
      case 2:
        control = 'Short text';
        break;
      case 3:
        control = 'Long text';
        break;
      case 4:
        control = 'Attachment';
        break;
      case 5:
        control = 'Check box';
        break;
      case 6:
        control = 'Multi select';
        break;
      case 7:
        control = 'Single select';
        break;
      case 8:
        control = 'Collaborator';
        break;
      case 9:
        control = 'Phone number';
        break;
      case 10:
        control = 'Email';
        break;
      case 11:
        control = 'URL';
        break;
      case 12:
        control = 'Message';
        break;
      case 13:
        control = 'RTF';
        break;
      case 14:
        control = 'Masked';
        break;
      case 16:
        control = 'Formula';
        break;
      case 17:
        control = 'Counter';
        break;
      case 18:
        control = 'Lookup';
        break;
      case 19:
        control = 'Added on';
        break;
      case 20:
        control = 'Updated on';
        break;
      case 22:
        control = 'Date';
        break;
      case 23:
        control = 'Number';
        break;
      case 24:
        control = 'Added by';
        break;
      case 25:
        control = 'Updated by';
        break;
    }
    return control;
  };

  return (
    // <div style={{width:'100%'}}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '25px',
        width: '870px',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: isOdd(index) === 1 ? `${color}1A` : 'white',
      }}
    >
      <div style={{ width: '50px', paddingLeft: '15px', color: '#C4B7B7', fontSize: '10px' }}>
        {index + 1}
      </div>
      <div
        css={{
          width: '270px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          ':hover .icon-container':
            role === 3 && !isEditMode
              ? {
                  visibility: 'visible',
                  fontWeight: 'bold',
                }
              : null,
          ':hover .textHeader':
            role === 3 && !isEditMode
              ? {
                  fontWeight: 'bold',
                }
              : null,
          '.icon-container': {
            marginRight: '5px',
            visibility: 'hidden',
          },
        }}
      >
        <div
          onClick={role === 3 ? () => editColumn(item.data) : null}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <div className="icon-container" style={{ marginRight: '5px' }}>
            {' '}
            <Icon path={mdiPencil} size={'15px'} />
          </div>
          <div className="textHeader">{item.header}</div>
        </div>

        {item.uitype !==12 ? (
        <div title="Delete forever" onClick={() => deleteColumn(item.data)} className="icon-container" style={{ marginRight: '20px' }}>
        <IconButton
                size="20px"
                color={color}
                hoverColor={'white'}
                hoverBackgroundColor={`${color}80`}
                icon={mdiDelete}
              />
        </div>
        ): null}

      </div>
      <div style={{ width: '230px' }}>{getType(item.uitype)}</div>
      <div style={{ width: '170px', display: 'flex', flexDirection: 'row' }}>
        {groupidx === -1 &&
        !item.disableGroup &&
        !item.locked &&
        !['Users', 'BlockUsers'].includes(dbname) ? (
          <Checkbox
            checked={false}
            onChange={(e, data) => {
              updateGroupItems(item.data);
            }}
          />
        ) : groupidx !== -1 && !item.disableGroup ? (
          <div style={{ cursor: 'pointer' }}>
            <Icon
              path={
                groupidx === 0
                  ? mdiNumeric1Circle
                  : groupidx === 1
                  ? mdiNumeric2Circle
                  : mdiNumeric3Circle
              }
              size="20px"
              onClick={() => updateGroupItems(item.data)}
            />
          </div>
        ) : null}
        <div style={{ width: '10px' }} />
        {item.isNumericField && !item.locked ? (
          <Checkbox
            disabled={item.hide}
            checked={calcidx !== -1}
            onChange={(e, data) => {
              updateCalcItems(item.data);
            }}
          />
        ) : null}
      </div>

      <div style={{ width: '100px' }}>
        {sortidx === -1 && !item.disableSort && !['Users', 'BlockUsers'].includes(dbname) ? (
          <Checkbox
            checked={false}
            onChange={(e, data) => {
              updateSortItems({ field: item.data, sortOrder: 'asc', sortHeader: item.header });
            }}
          />
        ) : sortidx !== -1 && !item.disableSort ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Icon
              path={
                sortidx === 0
                  ? mdiNumeric1Circle
                  : sortidx === 1
                  ? mdiNumeric2Circle
                  : mdiNumeric3Circle
              }
              size="20px"
              onClick={() => updateSortItems({ field: item.data, sortOrder: 'asc' })}
            />
            {sortItems[sortidx].sortOrder === 'asc' ? (
              <Icon
                path={mdiSortDescending}
                size="20px"
                onClick={() => updateSortOrder(sortItems[sortidx], 'desc')}
              />
            ) : (
              <Icon
                path={mdiSortAscending}
                size="20px"
                onClick={() => updateSortOrder(sortItems[sortidx], 'asc')}
              />
            )}
          </div>
        ) : null}
      </div>

      <div
        css={{
          color:
            (item.hide === false || item.hide === undefined) && item.locked === undefined
              ? '#1A1A1A'
              : '#1A1A1A80',
          width: '75px',
          '&:hover': {
            color: '#1A1A1A1A',
          },
        }}
      >
        {item.hide === false || item.hide === undefined ? (
          <Icon
            onClick={() => (item.locked === undefined ? updateColumn(item) : null)}
            path={mdiEye}
            size="20px"
          />
        ) : (
          <Icon
            onClick={() => (item.locked === undefined ? updateColumn(item) : null)}
            path={mdiEyeOff}
            size="20px"
          />
        )}
      </div>
      <div style={{ width: '45px', display: 'flex', flexDirection: 'row' }}>
        <Icon path={mdiDragVertical} size="20px" />
        {item.locked ? <div>🔒</div> : null}
      </div>
    </div>
    // </div>
  );
};
export default ColumnItem;
