/* eslint-disable */

import cloudrun from '../clients/httpcloudrun';

export const generateText = async (query,isCsv=false) =>{
    const payload={query,isCsv};
    const result = await cloudrun.post('/generateText', { payload });
    return result;
  }

export const aiProcessAPI = async (prompt,actionEvent,eventData) =>{
  const payload={prompt,actionEvent,eventData};
  const result = await cloudrun.post('/aiProcessAPI', { payload });
  return result;
}


  export const test=()=>{
    console.log('done');
  }