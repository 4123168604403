/* eslint-disable */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Input, Dropdown, Form, Radio } from 'semantic-ui-react';
import GBSwitch from '../../components/GBSwitch/GBSwitch';

const EventSettings = ({localData, updateData, editMode,updateEvent,actionEvent,sourceNodes }) => {
  const [action, setAction] = useState(actionEvent ?? null);
  const [runCondition, setRunCondition]=useState(localData.runCondition ?? 'all')
  const [occurance, setOccurance]=useState(localData.occurance ?? 'always');
  const [occuranceLimit, setOccuanceLimit] =useState(localData.occuranceLimit ?? 7)
  const [occuranceTimeUnit, setOccuranceTimeUnit] =useState(localData.occuranceTimeUnit ?? 'day');
  const [showApiSwitch,setShowApiSwitch] = useState(false);
  const [allRecordsProcessed, setAllRecordsProcessed] =useState(localData.allRecordsProcessed ?? false);

  const updateAction = (val) => {
    setAction(val);
    localData.actionEvent=val;
    localData.runCondition=runCondition;
    localData.occurance =occurance;
    localData.occuranceLimit=occuranceLimit;
    localData.occuranceTimeUnit=occuranceTimeUnit;
    updateEvent(val);
    updateData(localData);
  };

  const updateRunCondition = (val) =>{
    setRunCondition(val);
    localData.runCondition=val;
    updateData(localData);
  }

  const updateOccurance =(val) =>{
    setOccurance(val);
    localData.occurance =val;
    updateData(localData)
  }

  const updateAllRecordsProcessed =(val) =>{
    setAllRecordsProcessed(val);
    localData.allRecordsProcessed = val;
    updateData(localData);
  }

  const updateOccuranceLimit=(val) =>{
    setOccuanceLimit(val)
    localData.occuranceLimit=val;
    updateData(localData);
  }

  const updateOccuranceTimeUnit =(val) =>{
    setOccuranceTimeUnit(val);
    localData.occuranceTimeUnit=val;
    updateData(localData);
  }

  const get_all_source_nodes = (nodeData,currentDepth=0) => {
    const allNodeData = [];
    const seenIds = new Set(); // Use a Set to track unique IDs
  
    const extractSourceNodes = (data,depth) => {
    if (depth > 10) { // Check if we've reached the max depth
        return; // Stop recursion if we have
    }

      if (data.sourceNodes) {
        data.sourceNodes.forEach((sourceNode) => {
          if (sourceNode.id && sourceNode.data && !seenIds.has(sourceNode.id) ) { // Check for 'id' and uniqueness
            allNodeData.push(sourceNode);
            seenIds.add(sourceNode.id); 
          }
  
          if (sourceNode.data) {
            extractSourceNodes(sourceNode.data, depth + 1);
          }
        });
      }
    };
  
    extractSourceNodes(nodeData,currentDepth);
    return allNodeData;
  };

  useEffect(() =>{
    //1-8-25 If any previos actions have apicall or aiprocess, we show the switch that lets them
    //choose whether all api records need to be processed before action is called.
    //1-13-24 added aiprocess, as we've added the ability to ave the ai process generate
    //multiple records. 

    const allNodes = get_all_source_nodes(localData,0)
    const idx =allNodes.findIndex(itm=>['aiprocess','apicall'].includes(itm.data.actionEvent));
    if(idx !==-1 && localData.actionEvent !=='apicall') {
      setShowApiSwitch(true);
    } 
  },[])


//  add after initial implementation  { key: '10', value: 'callapi', text: 'Call external API' }, 
  const actionOptions = [
    { key: '1', value: 'newrecord', text: 'Add new record' },
    { key: '2', value: 'updaterecord', text: 'Update triggered record' },
    { key: '3', value: 'deleterecord', text: 'Delete record' },
    { key: '4', value: 'sendmessage', text: 'Send message' },
    { key: '5', value: 'formtask', text: 'Create form task' },
    { key: '6', value: 'adduser', text: 'Add or update a user' },
    { key: '7', value: 'deactivateuser', text: 'Deactivate a user' },
    { key: '8', value: 'confetti', text: 'Launch confetti' },
    { key: '9', value: 'apicall', text: 'External API call' },
    { key: '10', value: 'aiprocess', text: 'AI Process (beta)' },
    { key: '11', value: 'aiimageprocess', text: 'AI Image Detection Process (beta)' },
    { key: '12', value: 'speech', text: 'AI Text to Speech Process (beta)' },
  ];

  const timeUnit=[
    {key:'hour',value:'hour',text:'Hour'},
    {key:'day',value:'day',text:'Day'},
    {key:'week',value:'week',text:'Week'},
  ]

  const stateMap = { actionOptions };

  const DisplayValue = (source, value) => {
    const sourceArray = stateMap[source];
    if (!sourceArray) {
      console.error(`Source ${source} not found.`);
      return;
    }
    const idx = sourceArray.findIndex((el) => el.value === value);
    if (idx !== -1) {
      return sourceArray[idx].text;
    } else {
      return 'Value not found';
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Event and settings
      </div>
      <div style={{fontSize:'15px',fontWeight:'bold'}}>
        Event <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
      </div>
      <div>
        {!editMode ? (
          <div>{DisplayValue('actionOptions', action)}</div>
        ) : (
          <Dropdown
            selectOnBlur={false}
            options={actionOptions}
            fluid
            selection
            value={action}
            onChange={(e, data) => updateAction(data.value)}
          />
        )}
      </div>
      <div style={{ marginTop: '20px' }}></div>
      <div style={{fontWeight:'bold',fontSize:'15px'}}>Run condition <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
      <div>
        {/* {playOptions.map(el=>(
         <div>
          <Form.Field>
            <Radio 
              label={el.Text} 
              name='group1' 
              value={el.runCondition} 
              checked={runCondition===el.runCondition}
              onChange={(e,data)=>updateRunCondition(data.value)}
            />
          </Form.Field>
         </div> 
        ))}
        {runCondition==='AnySelected' || runCondition==='AllSelected' ? (
           <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:'100%'}}>
            <div style={{marginRight:'5px'}}>Criteria</div>
            <div style={{width:'100%'}}><Dropdown value={runCriteria} multiple selection fluid options={sourceOptions} onChange={(e,data)=>updateRunCriteria(data.value)} /></div>
           </div>
          ):null} */}

        <div>
          <Form.Field>
            <Radio
              label="Once ALL linked events have occurred."
              name="group1"
              value="all"
              checked={runCondition === 'all'}
              onChange={(e, data) => updateRunCondition(data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="Once ANY single linked event has occurred."
              name="group1"
              value="any"
              checked={runCondition === 'any'}
              onChange={(e, data) => updateRunCondition(data.value)}
            />
          </Form.Field>
        </div>
      </div>
      <div style={{ marginTop: '20px' }}></div>
      <div style={{fontWeight:'bold',fontSize:'15px'}}>Occurance <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
      <div>
        <div>
          <Form.Field>
            <Radio
              label="Run once"
              name="group2"
              value="once"
              checked={occurance === 'once'}
              onChange={(e, data) => updateOccurance(data.value)}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="Run always"
              name="group2"
              value="always"
              checked={occurance === 'always'}
              onChange={(e, data) => updateOccurance(data.value)}
            />
          </Form.Field>
          <Form.Field>
            <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
            <Radio
              label="Limit repeats to no more than once per "
              name="group2"
              value="limited"
              checked={occurance === 'limited'}
              onChange={(e, data) => updateOccurance(data.value)}
            />
            <div style={{width: '10px' }}></div>
            <Input style={{width:'50px'}} value={occuranceLimit} onChange={(e,data)=>updateOccuranceLimit(data.value)} />
            <div style={{width: '10px' }}></div>
            <Dropdown style={{width:'100px'}} selectOnBlur={false} fluid selection options={timeUnit} value={occuranceTimeUnit} onChange={(e,data)=>updateOccuranceTimeUnit(data.value)} />
            </div>

          </Form.Field>
          {showApiSwitch ? (
          <div style={{marginTop:'10px'}}>
            <div title="Determins whether this action will run for every api record, or whether to run when all records are processed." style={{fontWeight:'bold',fontSize:'15px'}}>API records</div>
            <GBSwitch isChecked={allRecordsProcessed} Action={updateAllRecordsProcessed} ActionValue={!allRecordsProcessed} color={'#0D99FF'} text="Run when All API Records complete" />
          </div>
          ):null}

        </div>
      </div>
    </div>
  );
};

export default EventSettings;
