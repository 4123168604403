/* eslint-disable */
import React, { useEffect, useState,useContext } from 'react';
import cloudrun from '../../../../clients/httpcloudrun';
import { Buffer } from 'buffer';
import { AccountContext } from '../../../../providers/AccountProvider';
import {Checkbox,label} from 'semantic-ui-react';
import { mdiPlus} from '@mdi/js';
import {getBookmark} from '../../../../api/bookmarks'
import NewRecord from './NewRecord';
import TopBar from './TopBar';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';
import Spinner from '../../../../components/Spinner/Spinner';
import NestedFieldsDisplay from './nestedFieldsDisplay';
import QuickViewVirtual from '../GridViews/QuickView/QuickViewVirtual';
import FormWrapper from '../Forms/FormWrapper';
import Global from '../../../../clients/global'


const DataList = ({
  tableid,
  sourcetableid,
  filters,
  tableinfo,
  color,
  displayMode,
  AddItem,
  RemoveItem,
  renderfield,
  selectMode,
  Close,
  Apply,
  id,
  callingComponent,
  expandRelatedRecord,
  UpdateExpandRelatedRecord,
  loadRelatedRecord,
  count,
  height,
  blockid,
  field,
  role,
  canEdit=true,
  relationalSecurity=true,
  nestedFields,
  newRecordOnly=false,
}) => {
  const [items, setItems] = useState([]);
  const [baseItems, setBaseItems]=useState([]); //7-27-added to support display of nestedfields
  const [isLoading,setIsLoading] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const [isNewEntry, setIsNewEntry] = useState( displayMode==='addition' && newRecordOnly ? true :false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [sortField,setSortField] = useState('addedon')
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortMultiple, setSortMultiple] =useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [mode, setMode] = useState(displayMode);
  const [currentFilters, setCurrentFilters] = useState(filters);
  const {userInfo} = useContext(AccountContext);
  const [bookmarkFilters,setBookmarkFilters]=useState([]);
  const [canShowDetails, setCanShowDetails]=useState(true);
  const [nestedFilters,setNestedFilters] =useState([]);
  const [tableWidth, setTableWidth]=useState(0);
  const [rowHeight,setRowHeight] = useState(userInfo.rowHeight ?? 33);
  const [selected,setSelected]=useState([]);
  const [totalRecords,setTtotalRecords] =useState(0);
  const [showAllColumns, setShowAllColumns]=useState(userInfo.toggleShowAllDataListColumns ?? false);
  const [formToken,setFormToken] =useState(null);
  const [hiddenFields,setHiddenFields] =useState([]);
  const [filteredTableInfo,setFilteredTableInfo] =useState(null);

  const defaultPageCount = 10;
  const resultSize=100;

  const LoadData = async () => {

    setIsLoading(true)
    setSelected([])
  
    let copytableinfo = structuredClone(tableinfo);

    let x = 250;
    let displayCols =  copytableinfo.columns.filter((el) => el.hide === undefined || !el.hide);
    if(userInfo.toggleShowAllDataListColumns===undefined || !userInfo.toggleShowAllDataListColumns) {
      displayCols=displayCols.slice(0,3);
    }
    else {
      displayCols=displayCols.slice(0,30);
    }

      displayCols.map((col) => {
        x = x + parseInt(col.width);
      });

      //this means we are showing delete icon, so need to bump overall table by 60 
      //so we remove interal horizontal scroll in detail view.
      if(mode==="review") {
        x=x+60;
      }

      //6/6-23 check to see if they have already changed default sort fields for this table-field
      const tablefield=`${tableid}-${field}`
      let sortMultipleTemp=null;
      let idx=null;
      if(userInfo.systemattributes?.relationSorting?.length>0) {
        idx = userInfo.systemattributes.relationSorting.findIndex(el=>el.tablefield===tablefield);
      }
      if(idx !==null && idx !==-1) {
        sortMultipleTemp =userInfo.systemattributes.relationSorting[idx].fields;
        setSortMultiple(sortMultipleTemp);
      }

     

    /******* 11-29-2021 Adding logic to lookup/add bookmark filter ************
     * if bookmark is defined for this relationship, we add whatever filters to existing filters object.
     * Should only be added when adding new relational records (not viewing existing). This
     * is deteremined by the operator='not', which indicated we're adding a new relational record.
    */
    // Load bookmarks if they exist
    const tempidx = copytableinfo.columns.findIndex(el=>el.source && el.source.lookuptableid ==sourcetableid && el.source.returnfield===field)

    let col=null;
    if(tempidx !==-1) {
      col = copytableinfo.columns[tempidx];
    }

      //8-31-23 we check if defaultFormId is defined. if yes, create formToken to use with FormWrapper
      //and create hiddenFields =[{relationalfield: value}] so this field is removed from form by default
      //as we are passing in id. This will make the "Create new record" use the default form of the 
      //relational table. FYI, this defaultFormID is added dynamically when calling getusertable(). It's not a permanent addition.
      if(copytableinfo.defaultFormId !==undefined && col !==null && col !==undefined) {
     
        const dbname = userInfo.zones.filter(itm=>itm.id===parseInt(Global.zoneid))[0].database;
        const c = Buffer.from(`${Global.zoneid}|${dbname}|${copytableinfo.defaultFormId}`);
        let base64data = c.toString('base64');
        setFormToken(base64data);
       
        const hf ={}
        hf[col.data] = id;
        setHiddenFields(hf);
       
      } else {
        setFormToken(null)
        setHiddenFields({});
      }
    

    // Does this relationship have a boomark defined.
    let tmpBookmarkFilter=[];
    if(col && col.source.useBookmark && col.source.bookmarkName !==''){
     
      //get the bookmark
      const bm = await getBookmark(col.source.bookmarkName);
      let currentSettings=null
     
      //does it have defined tabsetting
      if(bm.length>0 && bm[0].tabsettings !==undefined){
        currentSettings =bm[0].tabsettings;
      }

      //does it have defined filters.
      if(currentSettings?.filters !==undefined){
        tmpBookmarkFilter = currentSettings.filters;
        setBookmarkFilters(currentSettings.filters)
      } 

      //6/6/23 if they have not personalized sort and their is a bookmark with sortfields, update accordingly.
      if(currentSettings && sortMultipleTemp===null && currentSettings?.sortMultipleFields !==undefined){
        sortMultipleTemp = currentSettings.sortMultipleFields;
        setSortMultiple(currentSettings.sortMultipleFields)
      }      
      else if (currentSettings && currentSettings.sortMultipleFields===undefined && sortMultipleTemp===null) {
        //if there is no multi sort defined, all bookmarks have a defined sortfield and sortOrder
        //we convert the single sort into single array so we can use popup box.
        sortMultipleTemp=[{field:currentSettings.sortField, sortHeader:currentSettings.sortHeader,sortOrder:currentSettings.sortOrder}]
        setSortMultiple(sortMultipleTemp);
      }

    } else {
        setBookmarkFilters([]);
    }
    
    if(filters[0].operator==='not' && tmpBookmarkFilter.length>0) {
      filters = filters.concat(tmpBookmarkFilter)
    }
    /***********End of Bookmark logic  */

    if(count ===undefined || count >=0) {

   /*
    7-20-22 added change to limit paramter to support nested fields. 
    If nesting, retrieve entire table instead of top 100 rows. This data is then provided
    to new nested control.
   */

    //4-6-24 added logic to remove all unused fields when showAllFields is false. 
    //this method adds all necessary filter, sort and relational field required to query the table
    //based on bookmarks, security, etc.

    copytableinfo = configureColumns(filters,sortMultipleTemp)
 

    const payload={
      tableinfo:copytableinfo,
      tableid:tableid,
      callingComponent:'datalist',
      blockid:blockid,
      limit: nestedFields===undefined ? 100 : 10000, 
      offset:0,
      role:role,
      relationalSecurity:relationalSecurity,
      filters:filters,
      sortfield:'addedon',
      sortorder:'desc',
      sortMultipleFields: sortMultipleTemp,
      requiredFields: nestedFields !==undefined ? nestedFields.map(g=>g.field) : null //If nestedfieldds, must be included in query. 
    }

      const tabledata = await cloudrun.post('/executeTableQuery', { payload });
      const recordcount = tabledata.count;
      const shapedData =tabledata.rows;

      setItems(shapedData)
      setBaseItems(shapedData);
      setItemCount(recordcount)
      setTtotalRecords(tabledata.totalRecords);      
      setCurrentFilters(filters) //added 11-29-21
     
       // 5-4-2021 new logic in which if there are 0 matching records, automatically show them the Add new Record component.
      if(parseInt(recordcount) === 0 && mode==='addition'){
        setIsNewEntry(true)
      }       
    } else {
      setItems([])
      setItemCount(0);
    }

    // 6-19-2020 In detailsview, as user navigates records, we want to maintain their selection
    // for expanding the related records section. We do this by storing the value in parent component
    // actionpanel, and then passing it in as param. If exists, it uses it, in grid, uses local isExpand.
    if (expandRelatedRecord !== undefined) {
      setIsExpanded(expandRelatedRecord);
    }
    setTableWidth(x);
    setIsLoading(false)
  };

  //8-14-23 adding new param to be passed to the AddAction method. This is to support the chat view, where
  //users are creating new records. Because of how state works, we pass this param to the AddAction. It uses
  //this to add the item to local relationalitems state, but then passes the new relationalitems directly
  //to the applyChanges to have them recorded.  In Grid and normal detail view, this param is ignored. 
  const closeNewRecord = async (datarecord) => {

    setIsNewEntry(false);
    if(datarecord !== undefined)
    {
        AddAction(datarecord.id, datarecord.CustomID,true)
        await ApplyAction()
    }

    if(isNewEntry) {
      Close();
      setMode('review')
    }

    SwitchMode('review')
    
  };

  const configureColumns = (tempfilters,currentSortFields) =>{

    const copytableinfo = structuredClone(tableinfo)
    const visibleColumns = copytableinfo.columns.filter((el) => el.hide === undefined || !el.hide);
    // console.log(visibleColumns)

    if(visibleColumns.length>2 && (userInfo.toggleShowAllDataListColumns===undefined || !userInfo.toggleShowAllDataListColumns)) {
      const fieldsToInclude =['id'];
      fieldsToInclude.push(visibleColumns[1]?.data)
      fieldsToInclude.push(visibleColumns[2]?.data)
      const fldIdx = tempfilters.findIndex(fl=>fl.field=='id' && fl.uitype===100);
      if(fldIdx !==-1) {
          fieldsToInclude.push(tableinfo.columns.filter(col=>col.displayfield===tempfilters[fldIdx].lookupfield && col.uitype===1)[0]?.data)
      }

      //add any nestedfields
      if(nestedFields !==undefined && nestedFields.length>0) {
        nestedFields.map(itm=>{
          fieldsToInclude.push(itm.field);
        })
      }

      //Add in any sort fields 
      if(currentSortFields !==null && currentSortFields !==undefined && currentSortFields.length>0) {
        currentSortFields.map(col=>{
          fieldsToInclude.push(col.field)
        })
      }
      
      //Add all filter fields
      tempfilters.map(col=>{
        fieldsToInclude.push(col.field)
      })

      //Loop over existing fields and add any relational fields required required to properly run query.
      tableinfo.columns.filter(el=>fieldsToInclude.includes(el.data)).map(col=>{
        if(col.uitype===18){
          const relIdx = tableinfo.columns.findIndex(fld=>fld.data===col?.source?.basefield)
          if(relIdx !==-1) {
            //if relational field does not yet exist in array, add it. 
            if(fieldsToInclude.findIndex(e=>e===tableinfo.columns[relIdx].data)==-1)
            {
              fieldsToInclude.push(tableinfo.columns[relIdx].data);
            }
          }
        } //Add all relational fields that have security enabled if not yet added.
        else if(col.uitype===1 && col.useSecurity) {
          if(fieldsToInclude.findIndex(e=>e===col.data)===-1) {
            fieldsToInclude.push(col.data);
          }
        }

      })

      copytableinfo.columns = copytableinfo.columns.filter(col=>fieldsToInclude.includes(col.data));
      setFilteredTableInfo(copytableinfo);
  } else {
     setFilteredTableInfo(copytableinfo);
  }
    return copytableinfo
  }

  useEffect(() => {
    // 12-13-2021 Need to disable the "Expand" button so users who do not have full view
    // access to this table cannot see additional data.

    if(userInfo.plan !=='Free') {
      const idx = userInfo.blocks.findIndex(el=>el.blockid===blockid && (tableinfo.security===undefined || tableinfo.security.allRecords <=el.role) )
      setCanShowDetails(idx!==-1);
    }
  
    LoadData(null);
    //10-17-23 added tableinfo to fix dynamic loading of table from chatview right nav. Before was fetching all related data. this imroves performance.
  }, [id, field,tableinfo]); // 3-12-2021 added "field" as param to pass in. This was needed to support multiple joins to same table.

  const SearchData = async (value, nestFilters) => {

    setIsLoading(true)
    
    let tempNestFilters=[];

    if(nestFilters !==undefined){
      tempNestFilters = nestFilters;
    } else {
      tempNestFilters = nestedFilters;
    }

    const CustomFilter = [
      {
        // field: renderfield,
        field:'id', // 1/12/2021 changed this to "id" instead of renderfield, which was always "field_1", which can be deleted. Ultimately, the uitype=101 is what sqlfilter uses to properly filter by custom.fullannme
        sourcetableid: sourcetableid,
        header: 'field_1',
        value,
        operator: 'contains',
        uitype: 101, // 9/2/2020 we set to 101 so sqlfilter can specificy search on this field 
      },
    ];


    // let searchfilters=[];
    let sqlSearchFilters=[];

    if(value==='' && currentFilters===null && tempNestFilters.length>0) {
      sqlSearchFilters =tempNestFilters;
    } else if (value==='' && currentFilters !==null && tempNestFilters.length>0){
      //9-10-24 We now add nested filter all the time (before we checked if current filters had it.)
      //now we only concact currentFilters which are not part of nested filters. Basically, the nested
      //filter overrides.
      sqlSearchFilters = tempNestFilters.concat(currentFilters.filter(itm=>!tempNestFilters.map(i=>i.field).includes(itm.field)));
    } else if(value==='' && currentFilters !==null && tempNestFilters.length===0){
      sqlSearchFilters = currentFilters
    } else if(value !=='' && currentFilters===null && tempNestFilters.length>0) {
      sqlSearchFilters = CustomFilter.concat(tempNestFilters);
    } else if(value !=='' && currentFilters===null && tempNestFilters.length===0){
      sqlSearchFilters = CustomFilter;
    } else if(value !=='' && currentFilters !==null && tempNestFilters.length>0) {
      //only add currentFilters that are NOT in nestedfilters.
      sqlSearchFilters = CustomFilter.concat(currentFilters.filter(itm=>!tempNestFilters.map(i=>i.field).includes(itm.field))).concat(tempNestFilters);
    } else if(value !=='' && currentFilters!==null && tempNestFilters.length===0 ) {
      sqlSearchFilters = CustomFilter.concat(currentFilters);
    }

    let copytableinfo = configureColumns(currentFilters);

    const payload={
      tableinfo:copytableinfo,
      tableid:tableid,
      limit:nestedFields===undefined ? 100 : 10000,
      callingComponent:'datalist', // added 9-6-22 to be used to limit relational/lookup items to 1 to speed up query.
      blockid:blockid,
      offset:0,
      filters:sqlSearchFilters,
      role:role,
      relationalSecurity:relationalSecurity,
      sortfield:'addedon',
      sortorder:'desc',
      sortMultipleFields: sortMultiple
    }
      const tabledata = await cloudrun.post('/executeTableQuery', { payload });

      const recordcount = tabledata.count;
      const shapedData =tabledata.rows !==null ?  tabledata.rows : [];
      
      setItems(shapedData);
      setItemCount(recordcount);
      setIsLoading(false)
      setSearchValue(value)
      setTtotalRecords(tabledata.totalRecords);

      // 1-24-23 adding return recordCount so nestedControl displays proper matchign results.
      return recordcount;

  };

  // 7-25-2022 New method which allows the filtering of results from nested fields on datalist.
  // As they click a level, it passes in level data (field,uitype,value). From this data we
  // can consturct the array of filters to filter data.
  const nestedSearchData = async (items) => {
    let filters = [];
 
      items.length>0 && items.map(itm =>{
            if(itm.uitype===7 || itm.uitype===8 || itm.uitype===1 || (itm.uitype===18 && [1,7,8].includes(itm.lookupuitype))) {
              filters.push({field:itm.field,value:[itm.idValue],uitype:itm.uitype,dispayfield:`${itm.field}_options`,operator:'searchvalues'});
            } else {
              filters.push({field:itm.field,value:itm.value,uitype:itm.uitype,dispayfield:itm.field,operator:'equalto'})
            }
      })

      setNestedFilters(filters);
      //1-24-23 adding returning results so nestedcontrol displays right recordcount
      const x = await SearchData(searchValue,filters);
      return x;

  }

  const SwitchMode = async (newMode) => {
    if(newMode==='addition' && newRecordOnly) {
      setIsNewEntry(true)
      setMode(newMode)
      return false;
    }

    setIsLoading(true)
    let updatedFilter = currentFilters;

    if (currentFilters !== null) {
      updatedFilter[0].operator = newMode === 'addition' ? 'not' : 'and';
      if(newMode==='addition' && bookmarkFilters.length>0){
        updatedFilter = updatedFilter.concat(bookmarkFilters);
      }  else {
        updatedFilter=currentFilters.filter(el=>el.field==='id');
      }
      setCurrentFilters(updatedFilter);
    }

    const copytableinfo = configureColumns(updatedFilter)
    console.log(copytableinfo.columns)


    const payload={
      tableinfo:copytableinfo,
      tableid:tableid,
      blockid:blockid,
      limit:nestedFields===undefined ? 100 : 10000,
      callingComponent:'datalist',
      offset:0,
      role:role,
      relationalSecurity:relationalSecurity,
      filters:updatedFilter,
      sortfield: "addedon",
      sortorder: "desc",
      sortMultipleFields: sortMultiple,
      requiredFields: nestedFields !==undefined ? nestedFields.map(g=>g.field) : null
    }
      const tabledata = await cloudrun.post('/executeTableQuery', { payload });
      console.log(tabledata)
  
      const recordcount = tabledata.count
      const shapedData =tabledata.rows !== null ? tabledata.rows : []
      setItems(shapedData);
      setBaseItems(shapedData);
      setItemCount(recordcount);
      setMode(newMode);

      // 5-4-2021 new logic in which if there are 0 matching records, automatically show them the Add new Record component.
      if(parseInt(recordcount) === 0 && newMode==='addition'){
        setIsNewEntry(true)
      } 

      setIsLoading(false)

  };

  const CloseAction = async () => {
    if (callingComponent === 'detailview')
    {
      await SwitchMode('review');
    }
    Close();
  };

  const RemoveAction = async (id,field) => {
    
    
    const selectedRowKeys = [...selected];
    const rowIndex2 = selectedRowKeys.indexOf(id);

    if (rowIndex2 !== -1) {
      selectedRowKeys.splice(rowIndex2, 1);
    } else {
      selectedRowKeys.push(id)
    }
    setSelected(selectedRowKeys);

   
    if(mode==='review') {
    const newItems =  items.filter(itm=>itm.id !== id)
    setItems(newItems);
    }

    //7-6-23 Moved this removeItem to bottom(was as top)
    //Issue was when a RTF field was showing in datalist, the "remove" action was not being visually updates
    //in quickview..it was still showing old entry. Moving here to bottom seems to have solved issue. 
    await RemoveItem(id,mode,field);
 
  };

  //8-14-23 Added isNewRecord param for supporting ChatView Relational NEW itesm, so they properly get
  //assocated with table. The RightNav view uses this param to internally call ApplyChanges passing in the
  //new record, so that it can be properly added to relational table. This param is ignored by grid/detail view.
  const AddAction = async (id, field,isNewRecord=false) => {
    if (selectMode === 'multiple') {
      await AddItem(id, field,isNewRecord);
    } else {
      await AddItem(id, field,isNewRecord);
      await SwitchMode('review');
    }
  };

  const ApplyAction = async () => {
    await Apply();
    if (callingComponent === 'detailview') {
      setSearchValue('')
      await SwitchMode('review');
    }
    //
  };

  // const updateExpandRecord = (isExpand) => {
    
  //   // 12-13-2021. New security setting. It checks if usr has full access to all the
  //   // relational records (security.allRecords). if not, don't let them expand/show details
  //   if(!canShowDetails) {
  //     toast.info(<div style={{margin:'10px'}}>🔒 Security applied. Your security access does not have access to all possible records.</div>, {
  //       position: toast.POSITION.BOTTOM_CENTER,
  //       autoClose:7000,
  //     })
  //     return false;
  //   }

  //   setIsExpanded(isExpand);
  //   if (UpdateExpandRelatedRecord !== undefined) {
  //     UpdateExpandRelatedRecord(isExpand);
  //   }
  // };

   // 4/5/23 updating the sort to support multi-sort fields and we will save selected fields
   // on a per table-field basis, as fields will be different per table sorted.
  const toggleSort = (tableid,field,sortFields) => {

    const tablefield=`${tableid}-${field}`
    const idx = userInfo.systemattributes.relationSorting?.findIndex(el=>el.tablefield===tablefield);

    if(sortFields.length===0) {
      setSortMultiple(null);

      if(idx !==undefined && idx !==-1) {
        if(userInfo.systemattributes.relationSorting.length===1) {
          delete userInfo.systemattributes.relationSorting;
        } else {
          userInfo.systemattributes.relationSorting = userInfo.systemattributes.relationSorting.splice(idx);
        }
      }

      if(searchValue !== '')
      {
        SearchData(searchValue)
      } else {
        LoadData(null)
      }
    } else {
      setSortMultiple(sortFields);
      if(userInfo.systemattributes.relationSorting===undefined){
        userInfo.systemattributes.relationSorting = [{tablefield:`${tablefield}`, fields:sortFields }]
      } else {
        const idx2 = userInfo.systemattributes.relationSorting.findIndex(el=>el.tablefield===tablefield);
        if(idx2===-1) {
          userInfo.systemattributes.relationSorting.push({tablefield:`${tablefield}`, fields:sortFields })
        } else {
          // console.log(userInfo.systemattributes);
          userInfo.systemattributes.relationSorting[idx2].fields = sortFields;
        }
      }
      // console.log(userInfo.systemattributes.relationSorting);

      if(searchValue !== '')
      {
        SearchData(searchValue)
      } else {
        LoadData(sortFields);
      }
    }

  }

  const updateSelections =(id,RID)=>{

    // return false;
    
    if(mode==='review' && callingComponent==='detailview' &&  loadRelatedRecord !==undefined && loadRelatedRecord !==null) {
      loadRelatedRecord(id, tableid,blockid,'add')
      return false;
    } else if(mode==='review' && (loadRelatedRecord===undefined ||  loadRelatedRecord===null)) {
      return false;
    }


    const selectedRowKeys = [...selected];
    const rowIndex = selectedRowKeys.indexOf(id);

    if (rowIndex !== -1) {
      selectedRowKeys.splice(rowIndex, 1);
      RemoveAction(id,RID)
    } else {
      selectedRowKeys.push(id);
      AddAction(id,RID)
    }
    setSelected(selectedRowKeys);
  }

  const updateRowHeight=(rw)=>{
    setRowHeight(rw)
    userInfo.rowHeight=rw;
  }

  const getTableHeight =() =>{
    if(isNewEntry){
      return null
    }
    else if(['Grid','SingleDetail'].includes(callingComponent) && nestedFields==undefined){
      return callingComponent==='Grid' ? 'calc(100vh - 330px)' : 'calc(100vh - 300px)'
    } 
    else if(['Grid','SingleDetail'].includes(callingComponent) && nestedFields?.length>0){
      return (items.length >resultSize ? resultSize : items.length)*(userInfo.rowHeight ?? rowHeight)+200
    } else if(!['Grid','SingleDetail'].includes(callingComponent) && nestedFields==undefined) {
      // return 'calc(100vh - 470px)'
      return null
    } else if(!['Grid','SingleDetail'].includes(callingComponent) && nestedFields?.length>0) {
      // return (items.length >resultSize ? resultSize : items.length)*(userInfo.rowHeight ?? rowHeight)+10
      return 'calc(100vh - 370px)'
    } else {
      return null
    }
  }

  const getQuickViewHeight=() =>{
    if(['Grid','SingleDetail'].includes(callingComponent) && nestedFields==undefined){
      return 'calc(100vh - 370px)'
    } else if(callingComponent==='Grid' && nestedFields?.length>0){
      return (items.length >resultSize ? resultSize : items.length) *(userInfo.rowHeight ?? rowHeight)+70
    } else if (callingComponent !=='Grid' && nestedFields==undefined) {
      // return 'calc(100vh - 380px)'
      return  (items.length >resultSize ? resultSize : items.length)*(userInfo.rowHeight ?? rowHeight)+25
    } else if(callingComponent !=='Grid' && nestedFields?.length>0) {
      return  (items.length >resultSize ? resultSize : items.length)*(userInfo.rowHeight ?? rowHeight)+25
    }
  }

  const toggleShowAllColumns = async () =>{
    userInfo.toggleShowAllDataListColumns=!showAllColumns
    setShowAllColumns(!showAllColumns);
    let x = 250;
    let displayCols = tableinfo.columns.filter((el) => el.hide === undefined || !el.hide);
    if(showAllColumns) {
      displayCols=displayCols.slice(0,3);
    } 

    displayCols.map((col) => {
        x = x + parseInt(col.width);
      });

      //this means we are showing delete icon, so need to bump overall table by 60 
      //so we remove interal horizontal scroll in detail view.
      if(mode==="review") {
        x=x+60;
      }
      setTableWidth(x);
      await LoadData()
  }

  const getCompletionButtons =() =>{
    return (
      <div
      style={{
        display: 'flex',
        flexDirection:'row',
        // border:'1px solid blue',
        marginTop: '10px',
        alignItems: 'center',
        // marginLeft: '40px',
        // minHeight:'30px',
        justifyContent: 'center',
      }}
    >
      {(callingComponent === 'detailview' && mode == 'addition' && !isNewEntry ) || (callingComponent !== 'detailview' && !isNewEntry ) ? (
        <>
          <TextButton text="Cancel" Action={CloseAction} hoverColor="#75757580" textColor="black" />
          <div style={{width:'20px'}} />
          <GBButton text="Done" Action={ApplyAction} color={color} width="75px" />
        </>
      ) :  null}
      </div>
    )
  }


  return (
    <div style={{ 
      // border:'3px solid red',
      width: '100%',
      display:'flex',
      flexDirection:'column',
      height: callingComponent==='SingleDetail' ?  'calc(100vh - 145px)' : '100%',
      // height: '600px',
      overflow:'auto' ,
      }}>
      
      {(isNewEntry || newRecordOnly) && mode==='addition' && tableinfo.security.addRecord <= role ? (
      <>
        {formToken ===null ? (
        <NewRecord
          close={closeNewRecord}
          blockid={blockid}
          role={role}
          tableinfo={tableinfo}
          sourcetableid={sourcetableid}
          color={color}
          tableid={tableid}
          LoadData={LoadData}
          callingComponent="datalist" //other option right now is "grid" - controls if header bar is colored.
        />
        ): <FormWrapper
          token={formToken}
          close={close}
          executeAndClose={closeNewRecord}
          color={color}
          addedby={userInfo.userid}
          role={role}
          hiddenFields={hiddenFields}
          callingComponent={callingComponent}
          searchParams={{internal:true}}
        />
        }
      </>) : (
        <TopBar
          SearchData={SearchData}
          hasItems={items && items.length>0}
          rowHeight={userInfo.rowHeight ?? rowHeight}
          updateRowHeight={updateRowHeight}
          selectMode={selectMode}
          mode={mode}
          setIsNewEntry={setIsNewEntry}
          SwitchMode={SwitchMode}
          color={color}
          sortField={sortField}
          sortOrder={sortOrder}
          sortMultiple={sortMultiple}
          tableinfo={tableinfo}
          tableid={tableid}
          field={field}
          toggleSort={toggleSort}
          hasBlockAccess={blockid ===undefined || userInfo.blocks.findIndex(itm=>itm.blockid===blockid) !== -1}
          showCreateRecord={tableinfo?.security===undefined || tableinfo?.security.addRecord <= role}
          canEdit={canEdit}
          recordName={tableinfo.recordname !==undefined ? tableinfo.recordname : 'Record'}
          role={role}
          callingComponent={callingComponent}
        />
      )}
       {isNewEntry && tableinfo?.security.addRecord > role ? (
        <div style={{margin:'20px'}}>No records available</div>
       ) : null}
    <div
     style={{
      // display:'flex',
      // flexDirection:'column',
      //   overflow: 'auto',
      //   // height: height !==undefined ? height : window.innerHeight,
      //   height:  window.innerHeight-600,
      //   alignItems:'space-between'
     }}
    >
      {!isNewEntry && mode==='addition' && baseItems && baseItems.length>0 && nestedFields !==undefined ? (     
          <div>
          <NestedFieldsDisplay 
              data={items} 
              nestedFields={nestedFields} 
              nestedSearchData={nestedSearchData} 
              searchValue={searchValue} 
              />
          </div>
        ):null}
  
      <div
        style={{
          // border:'1px solid red',
          // overflow: 'auto',
          // height: height !==undefined ? height : "300px",
          height: getTableHeight(),
          minHeight:'100px',
          // height: !isNewEntry ? callingComponent==='Grid' ? 'calc(100vh - 340px)' : 'calc(100vh - 324px)' : null,
          marginLeft: '15px',
          marginBottom:'20px',
          marginRight: '10px',
          alignItems: 'center',
        }}
      >
        {isLoading && !isNewEntry ? (
          <Spinner color={color} />
        ): null}

        {!isLoading && mode !=='addition' && items !==undefined && items !==null && items.length>0 && callingComponent !== 'detailview' ? (
          <div>Selected ({items.length})</div>
          ):null}

         {!isLoading && mode !=='addition' && canEdit && (items ===null || items ===undefined || items?.length===0) ? (
          <div style={{marginLeft:'25px'}}>
            <div style={{color:'#B7B7BB',marginBottom:'20px'}}>No records have been selected.</div>
            <center><GBButton icon={mdiPlus} iconColor="white" iconHoverColor="white" iconPosition="left" color={color} text={`Add ${tableinfo.recordname}`} Action={SwitchMode} ActionValue="addition"  /></center>
          </div>
          ):null}

        {items && !isLoading && items.length>0 && !isNewEntry && filteredTableInfo !==null ? (<>
         {/* {nestedFields !==undefined ? getCompletionButtons() : null} */}
         {tableinfo.columns.filter(col=>col.hide===undefined || !col.hide).length > 3 ? (
           <div style={{marginBottom:'5px',display:'flex',flexDirection:'row',alignItems:'center'}}>
              <Checkbox checked={showAllColumns} color={color} onChange={toggleShowAllColumns} /> 
              <div style={{marginLeft:'5px'}}>Show more columns </div>
              </div>
        //  <div style={{marginBottom:'5px'}}><GBSwitch text="Show more columns" Action={toggleShowAllColumns} isChecked={showAllColumns} color={color} /></div>
         ) : null}
        
         <QuickViewVirtual
                color={color}
                rowHeight={userInfo.rowHeight ?? rowHeight}
                tableid={tableid}
                tableinfo={tableinfo}
                data={items}
                showDetailView={updateSelections}
                setSortField={()=>{console.log('sort')}}
                sortField="id"
                sortOrder="asc"
                UpdateTableColumnWidth={null}
                showBookmarkBar={false} //added so we can properly calculate height of grid.
                isExpanded={isExpanded}
                loadData={null}
                groupTableWidth={callingComponent==='Grid' || callingComponent==='SingleDetail' ? 800 : tableWidth}
                // groupTableHeight={callingComponent==='Grid' ? 'calc(100vh - 310px)' : 'calc(100vh - 360px)'}
                groupTableHeight={getQuickViewHeight()}
                recordCount={items.count}
                mode={mode}
                selectedKeys={selected}
                selectMode={selectMode}
                RemoveItem={RemoveAction}
                canEdit={canEdit}
                showAllColumns={showAllColumns}
          />
      </>) :null}
      {searchValue==='' && totalRecords>resultSize && items.length>=resultSize && mode==='addition' && !isNewEntry ? (
         <div style={{textAlign:'right', marginRight:'20px'}}>Viewing {resultSize} of {totalRecords} records</div>
      ):null}

       {getCompletionButtons()}
      </div>
      </div>

        {mode !=='addition' && relationalSecurity && role <3 ? (
          <div style={{color:'#B7B6B6',width:'90%',alignSelf:'center' }}>🔒 Some related records may be excluded from this view due to security access level controls. <u><a href="https://www.graceblocks.com/support-article/faq-lock-icon" target="_newwindow">Learn more here</a></u>.</div>
        ):null}

        {mode ==='review' && callingComponent==='detailview' && userInfo.plan==='Free' ? (
          <div style={{color:'#B7B6B6',width:'90%',alignSelf:'center'}}>🔔 Upgrade to a paid plan to set the sort, columns and column width that will display here. <u><a href="https://www.graceblocks.com/support-article/graceblocks-plans" target="_newwindow">Learn more here</a></u>.</div>
        ):null}
        
    </div>
  );
};

export default DataList;
